import React, { useEffect, useState } from 'react';
import { Breadcrumb, CircleName, Footers, Navbars } from '../../commons';
import { SingleShopContainer } from './SingleShopContainer'
import { useGetSingleshopDashboard } from '../../../apis/fetch';
import { verifyResponse } from '../../../apis/response';
import { useAppState } from '../../../store/AppState';
import { Loading } from '../../commons/tables/loading/Loading';

export const Singleshop = () => {
  const [{ tree, date }] = useAppState();
  const getSingleshopDashboardResponse = useGetSingleshopDashboard(date.typeDate, date.start, date.end);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(false)
    let timer1 = setTimeout(() => setShow(true), 300 * 1000);
    return () => {
      clearTimeout(timer1);
    };
  },
  );

  useEffect(() => {
    if (getSingleshopDashboardResponse.content.isLoading === 'FETCHED') {
      if (verifyResponse(getSingleshopDashboardResponse, 200)) {
        setData(getSingleshopDashboardResponse.content.response.data);
        setIsLoading(false);
      } else console.log(getSingleshopDashboardResponse.content.response.data);
      getSingleshopDashboardResponse.sendRequest({ loading: 'NOT FETCHING' });
    }
  }, [getSingleshopDashboardResponse]);

  useEffect(() => {
    if (!tree.loading) {
      getSingleshopDashboardResponse.sendRequest({
        loading: 'FETCHING',
        body: JSON.stringify(tree.selected),
      });
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree.selected, date.start, date.end]);

  return !tree.loading && !show &&
    <div id="dashboard">
      <Navbars.NavbarDatePicker disabled={isLoading} />
      <div className="container-fluid">
        <div className="row ">
          <div className="col-12">
            <div className="px-0 py-md-2 ">
              <Breadcrumb />
            </div>
          </div>
          <div className='col-12 px-2 mb-1'>
            <div className='d-flex mb-2 ms-2 mt-2 mt-md-0 ms-md-4 align-items-center'>
              <CircleName name={tree.selected.name?.substring(0, 2) ?? ""} />
              <h3 className='c-black font-size-22 font-weight-bold mb-0 ms-3'>
                {tree.selected?.name ?? ""}
              </h3>
            </div>
            <p className="c-dark-grey font-size-16 font-weight-regular ms-3 mb-2">
            </p>
          </div>
          <div className="col-12 mt-2 ">
            {!isLoading && <SingleShopContainer data={data} />}
          </div>
        </div>
        {isLoading && <Loading />}
        <div className="row ">
          <div className='px-3 px-md-4 col-12'>
            <Footers.FooterPoweredBy />
          </div>
        </div>
      </div>
    </div>
}