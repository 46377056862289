import React from 'react';
// COMPONENTS
import { MyTargetOverview } from "./MyTargetOverview"
import { CardPieChartsOverview } from "./cards/CardPieChartsOverview"
import { ComparisonLineChart } from "../../../commons/charts/ComparisonLineChart"
import { useTranslation } from 'react-i18next';
import { CardYourStore } from './cards/CardYourStore';
import { CardYourOnline } from './cards/CardYourOnline';
import { useAppState } from '../../../../store/AppState';



export function OverviewTabContainer({ data, parametersFetch }) {
    const { t } = useTranslation();
    const [{date}] = useAppState();

    return (
        <div id="OverviewTabContainer">
            <div>
                <h3 className="c-dark-grey font-size-15">{t("overviewtabcontainer.nb")}</h3>
                <h2 className="c-black font-size-21 font-weight-semibold">{t("overviewtabcontainer.mytarget")}</h2>
                <MyTargetOverview data={data} typeData={date.typeDate} parametersFetch={parametersFetch} />
            </div>
            <div className="row  pt-3">
                <div className="col-12">
                    <h2 className="c-black font-size-21 font-weight-semibold">{t("overviewtabcontainer.mycomparisontitle")}</h2>
                    <h3 className="c-dark-grey font-size-15">{t("overviewtabcontainer.mycomparisondescription")}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 py-2">
                    <CardPieChartsOverview
                        type="sales"
                        physical={data.store.filter(element => element.kind === 'PHYSICAL' && element.subkind === 'SALES')}
                        online={data.store.filter(element => element.kind === 'ONLINE' && element.subkind === 'SALES')}
                        Id="totalTurnover"
                    />
                </div>
                <div className="col-lg-6 py-2">
                    <CardPieChartsOverview
                        type="transactions"
                        physical={data.store.filter(element => element.kind === 'PHYSICAL' && element.subkind === 'RECEIPTS')}
                        online={data.store.filter(element => element.kind === 'ONLINE' && element.subkind === 'TRANSACTIONS')}
                        salesPhysical={data.store.filter(element => element.kind === 'PHYSICAL' && element.subkind === 'SALES')}
                        salesOnline={data.store.filter(element => element.kind === 'ONLINE' && element.subkind === 'SALES')}
                        Id="totalTransactions"
                    />
                </div>
            </div>

            {data.store.find(elem => elem.kind === 'PHYSICAL' && elem.subkind === 'AFFLUENCE') &&
                data.store.find(elem => elem.kind === 'ONLINE' && elem.subkind === 'UNIQUE_PEOPLE') && <>
                    <div className="row pt-3">
                        <div className="col-12">
                            <h2 className="c-black font-size-21 font-weight-semibold ">{t("overviewtabcontainer.mycustomergaintitle")}</h2>
                            <h3 className="c-dark-grey font-size-15">{t("overviewtabcontainer.mycustomergaindescription")}</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 py-2">
                            <CardYourStore
                                profitPhysical={data.store.filter(element => element.kind === 'PHYSICAL' && element.subkind === 'PROFIT')}
                                affluencePhysical={data.store.filter(element => element.kind === 'PHYSICAL' && element.subkind === 'AFFLUENCE')}
                                transactionsPhysical={data.store.filter(element => element.kind === 'PHYSICAL' && element.subkind === 'RECEIPTS')}
                            />
                        </div>
                        <div className="col-lg-6 py-2">
                            <CardYourOnline
                                profitOnline={data.store.filter(element => element.kind === 'ONLINE' && element.subkind === 'PROFIT')}
                                affluenceOnline={data.store.filter(element => element.kind === 'ONLINE' && element.subkind === 'UNIQUE_PEOPLE')}
                                transactionsOnline={data.store.filter(element => element.kind === 'ONLINE' && element.subkind === 'TRANSACTIONS')}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                             <div className="bg-grad-op25 shadowDropMediumBlur border-radius mt-4  p-3">
                                <h2 className="c-black font-size-20 font-weight-semibold mb-0">{t("overviewtabcontainer.comparisontraffictitle")}</h2>
                                <h3 className="c-black font-size-16 mb-3">{t("overviewtabcontainer.comparisontrafficdescription")}</h3>

                                <ComparisonLineChart
                                    Id="ComparisonLineChart"
                                    data={data}
                                    typeDate={date.typeDate} />
                            </div>
                        </div>

                    </div>
                </>}
        </div>


    );
}
