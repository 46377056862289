import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { useNewPassword } from '../../apis/fetch';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { Alerts, Inputs, Checks } from "../commons";

import IconGradKey from "../../img/IconGradKey.png";
import { verifyResponse } from '../../apis/response';

export function ResetPasswordContent() {
    const { t } = useTranslation();
    const gotoPage = useHistory();
    const newPasswordResponse = useNewPassword();

    const [loading, setLoading] = useState(false);
    const [enableButton, setEnableButton] = useState(true);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [infoAlert, setInfoAlert] = useState({type: "", title:"alert.titlewrongcredentials", message: "alert.descriptionpasswordsdifferents"});
    const [showAlert, setShowAlert] = useState("collapse");

    useEffect(() => {
        if(!loading) {
            setLoading(true);
            const params = (new URL(document.location)).searchParams;
            sessionStorage.setItem("temporaryToken", params.get('hashcode'));
        }
    }, [loading])

    useEffect(() => {
        if(newPasswordResponse.content.isLoading === "FETCHED") {
            if(verifyResponse(newPasswordResponse, 200)) {
                gotoPage.push("/passwordchanged");
            } else {
            }
            newPasswordResponse.sendRequest({loading: "NOT FETCHING"});
        }
    }, [newPasswordResponse])

    useEffect(() => {
        if(isPasswordValid && password.length>0 && confirmPassword.length>0) {
            setEnableButton(false);
        } else setEnableButton(true);
    }, [isPasswordValid, password, confirmPassword])

    const click = () => {
        if(password === confirmPassword) {
            newPasswordResponse.sendRequest({loading: "FETCHING", body: JSON.stringify({ password }) });
        } else {
            setInfoAlert({type: "error", title: "alert.titlewrongcredentials", message: "alert.descriptionpasswordsdifferents"});
            setShowAlert("visible");
        }
    }
    
    return (
        <div id="ResetPasswordContent"  className="border-radius bg-white p-5 pb-4 min-height-520 shadowDropMediumBlur">
               <div className="px-md-5 px-2 h-100 d-flex h-100 flex-column text-center justify-content-between align-items-center ">
                    <div className="my-0 align-self-center w-100">
                    <img alt="" className="img-icon80 m-0 " src={IconGradKey} />
                    <h4 className="c-dark-grey font-size-24 font-weight-bold text-center mx-3">{t("resetpassword.title")}</h4>
                    <p className="c-medium-grey font-size-14 font-weight-semibold text-start  ms-2 mb-2">{t("resetpassword.description")}</p>
                    <div  className="c-dark-grey ms-2">
                    <Checks.PasswordCheck password={password} setIsPasswordValid={setIsPasswordValid}/>
                    </div>
                </div>
                <div className="mb-2 align-self-center w-100 px-3">
                    <Inputs.InputPassword value={password} setPassword={setPassword} />
                    <Inputs.InputPassword value={confirmPassword} setPassword={setConfirmPassword} />
                    <Alerts.Alert info={infoAlert} show={showAlert} />
                </div>
                <ButtonComponent cssClass="gradientButton mb-1 font-weight-bold"
                    disabled={enableButton} 
                    onClick={() => click()}
                >
                 {t("resetpassword.button")}
                </ButtonComponent>
            
            </div>
        </div>  
    );
}