import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import IconTiffanyRealtime from '../../../../img/IconTiffanyRealtime.png'
import IconPurpleRealtime from '../../../../img/IconPurpleRealtime.png'
import "./cardrealtime.css"
import Dropdown from 'react-bootstrap/Dropdown'
import { useAppState } from '../../../../store/AppState';
import { Formatters } from '../../../commons';


export function CardRealTime({ type, kpiRealtime, dataRealTime }) {
    const { t } = useTranslation();
    const [{ date }] = useAppState();
    const [filterSelected, setFilterSelected] = useState({});


    const [miniCardSorted, setMiniCardSorted] = useState(dataRealTime);

    const filterDropDown =
        [{
            name: "filteralfaz",
            value: "alphabeticAZ",
        }, {
            name: "filteralfza",
            value: "alphabeticZA",
        }, {
            name: "filtermaxaff",
            value: "numercic90",
        }, {
            name: "filterminaff",
            value: "numercic09",
        }]



    useEffect(() => {
        let dataSorted = miniCardSorted
        dataSorted = miniCardSorted.sort(function (a, b) {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        })
        setMiniCardSorted(dataSorted)
        setFilterSelected({ name: "filteralfaz", value: "alphabeticAZ" })
    }, []);


    const handleFilter = (filter) => {
        let dataSorted = miniCardSorted
        if (filter.value === "alphabeticAZ") {
            dataSorted = miniCardSorted.sort(function (a, b) {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            })

        } else if (filter.value === "alphabeticZA") {
            dataSorted = miniCardSorted.sort(function (a, b) {
                if (a.name > b.name) { return -1; }
                if (a.name < b.name) { return 1; }
                return 0;

            })

        } else if (filter.value === "numercic90") {

            dataSorted = miniCardSorted.sort(function (a, b) {
                return b.value - a.value;


            })

        } else if (filter.value === "numercic09") {
            setFilterSelected(filter)
            dataSorted = miniCardSorted.sort(function (a, b) {
                return a.value - b.value;
            })

        }
        setMiniCardSorted(dataSorted)
        setFilterSelected(filter)

    }



    return (
        <div id="CardRealTime" className="h-100" >
            <div className={`bg-white border-radius d-flex flex-column flex-wrap py-4 px-3 px-md-4 py-md-4 pb-0 `} >

                <div className={`headerRealTime  d-flex flex-row flex-wrap justify-content-between ${type === "physical" ? "bgPhysical" : "bgOnline"}`}>
                    <div className="d-flex flex-column">
                        <div className=" d-flex flex-row align-items-center">
                            <img className="img-icon20 me-1" src={type === "physical" ? IconTiffanyRealtime : IconPurpleRealtime} alt="" />
                            <h3 className="c-black font-weight-semibold font-size-16 mb-0">{t(`cardrealtimestore.nowinstore`)}</h3>
                        </div>

                        <h2 className="c-black font-weight-bold font-size-33">{kpiRealtime[0].value}</h2>
                        <h4 className="c-green font-weight-semibold font-size-15">
                        <Formatters.NumberFormatter value={parseInt(kpiRealtime[0].percentage)} /> %</h4>
                        <p className="c-medium-grey font-weight-regular font-size-12">{t(`cardrealtimestore.from${date.typeDate}`)}</p>
                    </div>
                </div>
                {dataRealTime.length > 1 && <div className={`${type === "physical" ? "bg-grad-tiffany-op25" : "bg-grad-purple-op25"} border-radius py-3 px-2 mb-4`}>
                    <div className="d-flex flex-row flex-wrap align-items-end justify-content-between ">
                        <p className="c-black font-weight-semibold font-size-15 mb-2 ms-2">{t(`cardrealtimestore.${type}mysensor`)}</p>


                        <Dropdown
                            className="dropDownRealtimeFilter"
                            drop="down">
                            <Dropdown.Toggle variant="" id="dropdown-filter">


                                <h3 className="font-size-16 d-flex py-1 px-3 justify-content-between align-items-center w-100 m-1">
                                    {t(`cardrealtimestore.sortby`)}{" "}<span className="text-decoration-underline font-weight-semibold mx-1">{t(`cardrealtimestore.${filterSelected.name}`)}</span>
                                </h3>


                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {filterDropDown.map((filter, index) =>

                                    <Dropdown.Item href={"#/action-" + index} key={index} onClick={() => handleFilter(filter)}>

                                        {t(`cardrealtimestore.${filter.name}`)}
                                        {/* <div className="checkbox-grad-container" >
                                            <span className="checkmark"></span>
                                            <p className="c-black m-0 font-size-16 ms-2 w-100 h-100 ">
                                                {t(`cardrealtimestore.${filter.name}`)}
                                            </p>
                                        </div> */}
                                    </Dropdown.Item>

                                )}

                            </Dropdown.Menu>
                        </Dropdown >












                    </div>
                    <div className="d-flex flex-column flex-sm-row flex-wrap w-100">
                        {miniCardSorted.map((element, index) =>
                            <div key={index} className="miniCardRealTime flex-grow-1 bg-white  border-radius d-flex flex-column  flex-wrap p-2 ">
                                <div className=" d-flex flex-row mb-2">
                                    <img className="img-icon20 me-2" src={type === "physical" ? IconTiffanyRealtime : IconPurpleRealtime} alt="" />
                                    <h3 className="c-black font-weight-semibold font-size-16">{element.name}</h3>
                                </div>
                                <h2 className="c-black font-weight-semibold font-size-20">{element.value}</h2>
                            </div>
                        )}
                    </div>
                </div>}
            </div>

        </div >
    );
}