let isFind = false;

export const pathToParent = (data, selected, arrayBreadcrumb = []) => {
  isFind = false;
  arrayBreadcrumb.push({ name: data.name, id: data.identifier, tree: data });

  if (data.identifier !== selected.identifier) {
    if (data.haschild) {
      pathToChilds(data.haschild, selected, arrayBreadcrumb);
    }
  }

  return arrayBreadcrumb;
};

const pathToChilds = (data, selected, arrayBreadcrumb) => {
  data.forEach((child) => {
    if (isFind) return;

    arrayBreadcrumb.push({ name: child.name, id: child.identifier, tree: child });

    if (child.id === selected.id) {
      isFind = true;
      return;
    } else {
      if (child.haschild) {
        pathToChilds(child.haschild, selected, arrayBreadcrumb);
        if (isFind) return;
      }

      arrayBreadcrumb.pop();
    }
  });
};
