import React from 'react';
import {
    ColumnDirective,
    ColumnsDirective,
    Filter,
    GridComponent,
    Inject,
    Group,
    Page,
    Sort,
} from '@syncfusion/ej2-react-grids';
import { Browser } from "@syncfusion/ej2-base";
import IconBlackDownload from "../../img/IconBlackDownload.png"
import "./table.css"

const data = [{
    emissione: "7/4/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3831950344389&locale=it"
},
{
    emissione: "7/5/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3836940386501&locale=it"
},
{
    emissione: "7/6/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3837011525829&locale=it"
},
{
    emissione: "7/7/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3837346513093&locale=it"
},
{
    emissione: "7/8/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839677169861&locale=it"
},
{
    emissione: "7/9/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839687786693&locale=it"
},
{
    emissione: "7/10/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839710134469&locale=it"
},
{
    emissione: "7/11/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839718523077&locale=it"
},
{
    emissione: "7/12/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3848465940677&locale=it"
},
{
    emissione: "7/1/2022",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3848663597253&locale=it"
},
{
    emissione: "7/4/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3831950344389&locale=it"
},
{
    emissione: "7/5/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3836940386501&locale=it"
},
{
    emissione: "7/6/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3837011525829&locale=it"
},
{
    emissione: "7/7/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3837346513093&locale=it"
},
{
    emissione: "7/8/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839677169861&locale=it"
},
{
    emissione: "7/9/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839687786693&locale=it"
},
{
    emissione: "7/10/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839710134469&locale=it"
},
{
    emissione: "7/11/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3839718523077&locale=it"
},
{
    emissione: "7/12/2021",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3848465940677&locale=it"
},
{
    emissione: "7/1/2022",
    nroFattura: "10248",
    description: "Lorem ipsum sed ut perspiciatis",
    total: "32,38",
    pdf: "https://pdf.vify.io/api/pdf-preview/invoice?shop_domain=imok-saga.myshopify.com&order_id=3848663597253&locale=it"
},
]
class Table extends React.Component {
    constructor(props) {
        super(props);
        this.pageSettings = { pageSize: 12 };
        this.sortSettings = {
            columns: [
                { field: 'nroFattura', direction: 'Ascending' }
            ]
        };
        this.settingsSelection = {
            checkboxOnly: true,
            SelectionSettingsModel: {
                checkboxMode: 'ResetOnRowClick',

            }
        };
        this.filterSettings = {
            columns: [
                { field: 'nroFattura', operator: 'greaterthan', value: 2 }
            ]
        };

        this.state = {
            data: [],
            reveneuSelected: []
        };
    }
    // componentDidUpdate(propsPrecedenti) {
    //     if (this.props.dataBilling != propsPrecedenti.dataBilling) {
    //         this.createData()
    //     }
    // }

    // createData() {
    //     this.props.dataBilling.map(data => console.log(data))
    // }

    // Select Row and get results
    rowSelected = (selectedrecords) => {
        if (selectedrecords.data.length > 1) {
            let recordsSelected = [];

            for (var i = 0; i < selectedrecords.data.length; i++) {
                const reveneuLink = selectedrecords.data[i].pdf.slice(0, 8) + "api-" + selectedrecords.data[i].pdf.slice(8)
                const reveneuId = selectedrecords.data[i].nroFattura

                recordsSelected.push({ id: reveneuId, link: reveneuLink })
                this.setState({ reveneuSelected: recordsSelected });
            }
        } else {
            const reveneuLink = selectedrecords.data.pdf.slice(0, 8) + "api-" + selectedrecords.data.pdf.slice(8)
            const reveneuId = selectedrecords.data.nroFattura
            let recordsSelected = [];

            recordsSelected = this.state.reveneuSelected

            recordsSelected.push({ id: reveneuId, link: reveneuLink })
            this.setState({ reveneuSelected: recordsSelected });
        }
    }

    // Deselect Row and get  results
    rowDeselected = (deselectedrecords) => {
        if (deselectedrecords.data.length > 1) {
            this.setState({ reveneuSelected: [] });
        } else {
            let recordsSelected = this.state.reveneuSelected
            const reveneuId = deselectedrecords.data.nroFattura


            for (var i = 0; i < recordsSelected.length; i++) {
                if (recordsSelected[i].id === reveneuId) {
                    recordsSelected.splice(i, 1);
                    this.setState({ reveneuSelected: recordsSelected });
                }
            }
        }
       
    }






    // template Download Reveneu
    template = (props) => {
        const link = props.pdf.slice(0, 8) + "api-" + props.pdf.slice(8);
        return (
            <div>
               
                <a href={link} download="fattura" >
                    <img alt="" className='img-icon25 pointer' src={IconBlackDownload} />

                </a>
            </div>);
    }

    load = () => {
        this.adaptiveDlgTarget = document.getElementsByClassName('e-mobile-content')[0];
    }


    render() {

        return (

            <div id="billing" className="borderRadius shadowDropMediumBlur bg-white border-radius my-3 scrollbarCustom">
                <div className="p-5 pt-3 ">
                    <div className="p-1 m-0 d-flex justify-content-between flex-wrap pt-md-4 ">
                        <h2 className="c-black font-size-21 font-weight-bold ">Informazioni di fatturazione</h2>
                        <a onClick={this.downloadSelected.bind(this)} className="c-purple font-size-16 font-weight-regular pointer"  >Scarica Selezionati</a>
                    </div>

                    <div className="e-bigger">
                        {!Browser.isDevice ? (
                            <div className="e-mobile-layout">
                                <div className="e-mobile-content">
                                    <GridComponent
                                        // dataSource={this.props.dataBilling  ? this.props.dataBilling : null}
                                        dataSource={data}
                                        allowPaging={true}
                                        pageSettings={this.pageSettings}
                                        filterSettings={this.filterSettings}
                                        enableHover={false}
                                        allowSorting={true}
                                        sortSettings={this.sortSettings}
                                        allowFiltering={false}
                                        ref={grid => this.grid = grid}
                                        selectionSettings={this.settingsSelection}
                                        rowSelected={this.rowSelected.bind(this)}
                                        rowDeselected={this.rowDeselected.bind(this)}
                                        enableAdaptiveUI={true}
                                        rowRenderingMode={"Horizontal"}
                                        load={this.load}
                                    >
                                        <ColumnsDirective>
                                            <ColumnDirective type='checkbox' width='50' />
                                            <ColumnDirective headerText='Emissione' field='emissione' width='auto' textAlign="Left" />
                                            <ColumnDirective headerText='N.ro Fattura' field='nroFattura' width='auto' textAlign="Left" />
                                            <ColumnDirective headerText='Descrizione' field='description' width='auto' textAlign="Left" />
                                            <ColumnDirective headerText='Totale' field='total' width='auto' textAlign="Left" />
                                            <ColumnDirective headerText='Download' field='pdf' width='auto' template={this.template} textAlign='Left' />
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Filter, Group]} />
                                    </GridComponent>
                                </div>
                            </div>

                        ) :
                            <div className="e-mobile-layout">
                                <div className="e-mobile-content">


                                    <GridComponent
                                        // dataSource={this.props.dataBilling  ? this.props.dataBilling : null}
                                        dataSource={data}
                                        allowPaging={true}
                                        pageSettings={this.pageSettings}
                                        filterSettings={this.filterSettings}
                                        enableHover={false}
                                        allowSorting={true}
                                        sortSettings={this.sortSettings}
                                        allowFiltering={false}
                                        ref={grid => this.grid = grid}
                                        selectionSettings={this.settingsSelection}
                                        rowSelected={this.rowSelected.bind(this)}
                                        rowDeselected={this.rowDeselected.bind(this)}
                                        enableAdaptiveUI={true}
                                        rowRenderingMode={"Vertical"}
                                        load={this.load}
                                    >
                                        <ColumnsDirective>
                                        <ColumnDirective type='checkbox' width='50' />
                                            <ColumnDirective headerText='Emissione' field='emissione' width='100' textAlign="Left" />
                                            <ColumnDirective headerText='N.ro Fattura' field='nroFattura' width='100' textAlign="Left" />
                                            <ColumnDirective headerText='Descrizione' field='description' width='100' textAlign="Left" />
                                            <ColumnDirective headerText='Totale' field='total' width='100' textAlign="Left" />
                                            <ColumnDirective headerText='Download' field='pdf' width='120' template={this.template} textAlign='Left' />
                                        </ColumnsDirective>
                                        <Inject services={[Page, Sort, Filter, Group]} />
                                    </GridComponent>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>


        );
    }
}

export default Table;
