import React from 'react';

import IconError from "../../../img/IconRedAlert.png";
import IconSuccess from "../../../img/IconGreenSuccess.png";
import IconWarning from "../../../img/IconYellowMail.png";
import IconTargetDateDisabled from "../../../img/IconYellowWarning.png";
import "./alert.css";
import { useTranslation } from 'react-i18next';


export function Alert({ info, show }) {
    const { t } = useTranslation();
    const icons = {
        error: IconError,
        success: IconSuccess,
        warning: IconWarning,
        targetDateDisabled: IconTargetDateDisabled
    }

    return (
        <div className={`alert ${info.type} p-2 flex-column text-start`}
            style={{ visibility: show }}>
            <div className="d-flex align-items-flexends">
                <img alt="" className="img-icon20 m-0 me-1" src={icons[info.type]} />
                <h3 className="mb-0 font-weight-bold font-size-14 fst-italic ">{t(info.title)}</h3>
            </div>
            {info.message &&
                <p className="mb-0 font-size-12">{t(info.message)}</p>}
            {info.messageTwo &&
                <p className="mb-0 font-size-12">{t(info.messageTwo)}</p>}
            {info.messageCustom &&
                <p className="mb-0 font-size-12">
                {t("alert.warningnewemailchanged")}
                <br />
                {t("alert.warningnewemailnotrecived")} <span className="text-decoration-underline pointer" onClick={info.messageCustom}>{t("alert.warningnewemailresubmit")}</span>
            </p>}
        </div>
    );
}



