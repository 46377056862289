import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { loadCldr } from '@syncfusion/ej2-base';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './datepicker.css';
import { Formatters } from '..';
import { useAppState } from '../../../store/AppState';
import { setDateAction } from '../../../actions';
import { L10n } from '@syncfusion/ej2-base';
import { Browser } from "@syncfusion/ej2-base";

loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/supplemental/weekData.json'),
  require('cldr-data/main/fr-CH/ca-gregorian.json'),
  require('cldr-data/main/fr-CH/numbers.json'),
  require('cldr-data/main/fr-CH/timeZoneNames.json'),
  require('cldr-data/main/en-CH/ca-gregorian.json'),
  require('cldr-data/main/en-CH/numbers.json'),
  require('cldr-data/main/en-CH/timeZoneNames.json'),
  require('cldr-data/main/it-CH/ca-gregorian.json'),
  require('cldr-data/main/it-CH/numbers.json'),
  require('cldr-data/main/it-CH/timeZoneNames.json')
);

export const DatePicker = ({ id, max }) => {
  const dateSettings = {
    day: {
      startDate: 'Month',
      depthDate: 'Month',
      formatDate: 'dd MMMM y',
      multiSelectionDate: false,
    },
    week: {
      startDate: 'Month',
      depthDate: 'Month',
      formatDate: 'dd MMMM y',
      multiSelectionDate: true,
    },
    month: {
      startDate: 'Year',
      depthDate: 'Year',
      formatDate: 'MMMM',
      multiSelectionDate: false,
    },
    year: {
      startDate: 'Decade',
      depthDate: 'Decade',
      formatDate: 'y',
      multiSelectionDate: false,
    },
  };

  const [{ date }, dispatch] = useAppState();
  const { t, i18n } = useTranslation();
  const [valueDate, setValueDate] = useState(moment(date.start).toDate());
  const [valuesDate, setValuesDate] = useState([]);
  const [startDate, setStartDate] = useState(dateSettings[date.typeDate].startDate);
  const [depthDate, setDepthDate] = useState(dateSettings[date.typeDate].depthDate);
  const [formatDate, setFormatDate] = useState(dateSettings[date.typeDate].formatDate);
  const [fixedTopCalendar, setFixedTopCalendar] = useState(0);

  L10n.load({
    "it-CH": {
      "datepicker": {
        "today": t(`datepicker.this${date.typeDate}`)
      }
    },
    "fr-CH": {
      "datepicker": {
        "today": t(`datepicker.this${date.typeDate}`)
      }
    },
    "en-CH": {
      "datepicker": {
        "today": t(`datepicker.this${date.typeDate}`)
      }
    }
  })

  const [multiSelectionDate, setMultiSelectionDate] = useState(false);
  let datepicker = useRef(null);

  useEffect(() => {
    setValueDate(moment().toDate());
  }, [date.typeDate]);

  useEffect(() => {
    handleChange(valueDate);
  }, [valueDate]);

  const handleChange = (e) => {
    const value = e.value ? e.value : valueDate;
    setValueDate(value);
    const start = moment(value).startOf(date.typeDate);
    const end = moment(value).endOf(date.typeDate);
    const days = [];
    if (date.typeDate === 'week') {
      let day = start;
      while (day <= end) {
        days.push(day.toDate());
        day = day.clone().add(1, 'd');
      }
      datepicker.current.element.value = `${start.date() < 10 ? `0${start.date()}` : start.date()
        } - ${end.date() < 10 ? `0${end.date()}` : end.date()} / ${start.month() + 1 < 10 ? `0${start.month() + 1}` : start.month() + 1
        } / ${start.year()}`;
    }

    setValuesDate(days);
    dispatch(setDateAction(dispatch, {
      typeDate: date.typeDate,
      start: Formatters.FormatDateTZ(moment(start)),
      end: Formatters.FormatDateTZ(moment(end)),
    }));
  };

  useEffect(() => {
    setStartDate(dateSettings[date.typeDate].startDate);
    setDepthDate(dateSettings[date.typeDate].depthDate);
    setFormatDate(dateSettings[date.typeDate].formatDate);
    setMultiSelectionDate(dateSettings[date.typeDate].multiSelectionDate);
  }, [date.typeDate]);

  useEffect(() => {
    var elInputCalendar = document.getElementById('Navbarcalendar');
    if (elInputCalendar) {
      elInputCalendar.disabled = true;
    }
  }, []);

  const fixTopCalendar = () => {
    if (!Browser.isDevice) {
      const heightNavbarMain = document.getElementById("navbarMain").offsetHeight;
      const topDatepickerInput = document.getElementById("Navbarcalendar").offsetTop;
      const heightDatepickerInput = document.getElementById("Navbarcalendar").offsetHeight;
      const offset = 4;
      const topCalendarDatepicker = heightNavbarMain + topDatepickerInput + heightDatepickerInput + offset;
      setFixedTopCalendar(topCalendarDatepicker);
      datepicker.current.show();
    }
  }

  return (
    <div id='datePicker' className='datepicker-control-section col'>
      <DatePickerComponent
        cssClass={`GradDatePicker navbarCalendar top-${fixedTopCalendar}`}
        change={handleChange}
        id={id + 'calendar'}
        open={fixTopCalendar}
        value={valueDate}
        values={valuesDate}
        isMultiSelection={multiSelectionDate}
        start={startDate}
        depth={depthDate}
        format={formatDate}
        locale={i18n.language + '-CH'}
        ref={datepicker}
        max={max}
        openOnFocus
        firstDayOfWeek={1}
      />
    </div>
  );
};
