import { useFetch, headers } from './models';
var moment = require('moment-timezone');

export const useSignIn = () => {
  const method = 'POST';
  headers['Login-Type'] = 'web';

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/signin?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useForgotPassword = () => {
  const method = 'POST';

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/forgot-password?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useNewPassword = () => {
  const method = 'POST';
  headers['Authorization'] =
    'Bearer ' + sessionStorage.getItem('temporaryToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/new-password?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useChangePassword = () => {
  const method = 'POST';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/change-password?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useChangeEmail = () => {
  const method = 'POST';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/change-email?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useConfirmEmail = () => {
  const method = 'POST';
  headers['Authorization'] =
    'Bearer ' + sessionStorage.getItem('temporaryToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/confirm-email?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useGetAccount = () => {
  const method = 'GET';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useUpdateAccount = () => {
  const method = 'PUT';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useAccountCheck = (request) => {
  const method = 'GET';

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    `/accounts/check?username=${request.username}&email=${request.email}&` +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useGetAccountInvoices = () => {
  const method = 'GET';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url =
    window.REACT_APP_SERVICE_USERS_INTERNAL +
    '/accounts/invoices?' +
    window.REACT_APP_SERVICE_V1;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useGetMultishopDashboard = (group, start, end) => {
  const method = 'POST';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const timezone =  moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("z");

  const url = `${window.REACT_APP_SERVICE_DEVICES_INTERNAL}/dashboard/multistore?timezone=${timezone}&start=${start}&end=${end}&group=${group}&${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useGetSingleshopDashboard = (group, start, end) => {
  const method = 'POST';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const timezone =  moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("z");

  const url = `${window.REACT_APP_SERVICE_DEVICES_INTERNAL}/dashboard/singlestore?timezone=${timezone}&start=${start}&end=${end}&group=${group}&${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useNewTargetsUser = () => {
  const method = 'POST';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url = `${window.REACT_APP_SERVICE_USERS_INTERNAL}/targets-users?${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  return useFetch({ url, params });
};

export const useDisableTargetUser = (targetUserId) => {
  const method = 'PATCH';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url = `${window.REACT_APP_SERVICE_USERS_INTERNAL}/targets-users/${targetUserId}?${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  return useFetch({ url, params });
};
