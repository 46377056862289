import React, { useEffect } from 'react';
import { OverviewTabContainer } from './overview/OverviewTabContainer'
import { PhysicalShop } from './physical/PhysicalShop';
import { OnlineShop } from './online/OnlineShop';

import { TabHeaderButton } from './TabHeaderButton'
import "./singleshopContainer.css"
import { useAppState } from '../../../store/AppState';
import { setHeadersSingleshop, targetsRequest } from '../../../actions';
import { Scroller } from '../../commons';

export function SingleShopContainer({ data }) {
    const [{ tree, date, headersSingleshop }, dispatch] = useAppState();

    let headers = [
        {
            active: false,
            button: tree.selected.kpi.filter(kpi => kpi.kind === "ONLINE").length > 0 &&
                tree.selected.kpi.filter(kpi => kpi.kind === "PHYSICAL").length > 0 &&
                <TabHeaderButton
                    type="OVERVIEW"
                    title={"singleshopcontainer.titletaboverview"}
                    description={"singleshopcontainer.descriptiontaboverview"}
                    click={() => buttonClick(0)}
                />,
            content: <OverviewTabContainer
                data={data}
                parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }}
            />
        },
        {
            active: false,
            button: tree.selected.kpi.filter(kpi => kpi.kind === "PHYSICAL").length > 0 &&
                <TabHeaderButton
                    type="PHYSICAL"
                    title={"singleshopcontainer.titletabphysical"}
                    description={"singleshopcontainer.descriptiontabphysical"}
                    click={() => buttonClick(1)}
                />,
            content: <PhysicalShop
                data={data}
                parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }}
            />
        },
        {
            active: false,
            button: tree.selected.kpi.filter(kpi => kpi.kind === "ONLINE").length > 0 &&
                <TabHeaderButton
                    type="ONLINE"
                    title={"singleshopcontainer.titletabonline"}
                    description={"singleshopcontainer.descriptiontabonline"}
                    click={() => buttonClick(2)}
                />,
            content: <OnlineShop
                data={data}
                parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }}
            />

        }
    ];

    const buttonClick = (index) => {
        headers.forEach((header, i) => {
            if (index === i) header.active = true;
            else header.active = false;
        })

        dispatch(setHeadersSingleshop(headers))
    }

    const getActiveTab = () => {
        let tabSelected = -1;
        headersSingleshop.forEach((header, index) => {
            if(header.active && tabSelected === -1) {
                tabSelected = index;
            }
        })

        return tabSelected;
    }

    useEffect(() => {
        dispatch(targetsRequest(dispatch, { getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }))

        let tabSelected = -1;
        headers.forEach((header, index) => {
            if(header.button && tabSelected === -1) {
                header.active = true;
                tabSelected = index;
            }
        })

        headersSingleshop.length === 0 && dispatch(setHeadersSingleshop(headers));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const contents = [
        <OverviewTabContainer data={data} parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }} />,
        <PhysicalShop data={data} parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }} />,
        <OnlineShop data={data} parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }} />
    ]

    return (
        <div className="px-md-4" id='mainTabSingleShop'>
            <Scroller
                id="mainTabScroller">
                <div className="headers">
                    {headersSingleshop.map((header, index) => header.button && React.createElement(header.button.type, { ...header.button.props, active: header.active, key: index }))}
                </div>
            </Scroller>
            <div className="content borderGrey">
                {headersSingleshop.length > 0 && contents[getActiveTab()]}
            </div>
        </div>
    );
}
