import React, { useState, useEffect } from 'react';
import { Inputs } from '../commons'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useAppState } from '../../store/AppState';
import { useTranslation } from 'react-i18next';
import "./filter.css"

export function Filter({ filterArr, getFilterChosen, filterChosen }) {
  const { t } = useTranslation();
  const [state] = useAppState();
  const [filterChecked, setFilterChecked] = useState(filterChosen);

  useEffect(() => {
    setFilterChecked(filterChosen)
  }, [filterChosen]);

  useEffect(() => {
    getFilterChosen(filterChecked)
  }, [filterChecked]);

  const deleteThisFilter = (filter) => {
    const filterCheckedTemp = [...filterChecked]
    for (var i = filterCheckedTemp.length - 1; i >= 0; --i) {
      if (filterCheckedTemp[i].value === filter.value) {
        filterCheckedTemp.splice(i, 1);
      }
    }
    setFilterChecked(filterCheckedTemp)
  }

  const handleCheckbox = (e) => {
    const filterCheckedTemp = [...filterChecked]
    const value = JSON.parse(e.target.value)
    if (e.target.checked) {

      filterCheckedTemp.push(value)

    } else if (e.target.checked === false) {
      for (var i = filterCheckedTemp.length - 1; i >= 0; --i) {
        if (filterCheckedTemp[i].value === value.value) {
          filterCheckedTemp.splice(i, 1);
        }
      }
    }
    setFilterChecked(filterCheckedTemp)
  }

  return (
    <div id="filterTarget" className=" col-12 h-100 d-none d-sm-block scrollbarCustom " >
      <h3 className="c-dark-grey font-size-16 font-weight-bold text-uppercase mt-4 ">{t("targetfilter.title")}</h3>
      <div className="listFilter d-flex flex-wrap mb-1" >


        {Object.entries(filterArr).map((filter, i) => {
          return i === 0 ?
            <div className="mt-2" >
              <Inputs.CheckBoxDropDownTree
                filterChecked={filterChecked}
                key={i}
                title={t(`targetfilter.${filter[1].title}`)}
                tree={[state.tree.full]}
                onChange={handleCheckbox}
              />
            </div>
            :
            <div className="mt-2" >
              <Inputs.CheckBoxDropDown
                filterChecked={filterChecked}
                key={i}
                title={t(`targetfilter.${filter[1].title}`)}
                listCheckbox={filter[1].filters}
                onChange={handleCheckbox}
              />
            </div>
        })}
      </div>
      <div className="d-flex  justify-content-between px-1">
        <div className="d-flex flex-wrap justify-content-start px-1">
          {filterChecked.map((filter, index) =>
            <div className="labelTagFilter align-items-center d-flex mx-2 my-1 py-1 px-3">
              <span className="c-white font-size-16 mb-0 me-3">{t(`${filter.name}`)}</span>
              <ButtonComponent
                iconCss='e-icons e-close me-1'
                onClick={() => deleteThisFilter(filter)} />
            </div>)}
        </div>
        <ButtonComponent
          disabled={filterChecked.length === 0}
          className="redlineButton d-flex mb-3 font-size-16 mt-3"
          onClick={() => setFilterChecked([])}>
          {t(`targetfilter.deletebutton`)}
        </ButtonComponent>
      </div>

    </div>
  );
}