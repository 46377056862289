import React, { useEffect, useState } from 'react';
import { TabLineChart } from './TabLineChart';
import { TargetCard } from './tabContainer/pages/TargetCard';
import { useAppState } from '../../../store/AppState';
import { targetsRequest } from '../../../actions';
import { KpiButton } from './KpiButton';
import { Scroller } from '../../commons/scroller/Scroller';
import './mainshop.css';

export function MainShop({ data }) {
  const [{tree, targets, date}, dispatch] = useAppState();
  const [kpiSelected, setKpiSelected] = useState(data.kpi[0]);
  const [kpiIndexSelected, setKpiIndexSelected] = useState(0);

  useEffect(() => {
    dispatch(targetsRequest(dispatch, { getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.start, date.end, tree.selected.id, tree.selected.kind]);

  const selectKpi = (value, index) => {
    setKpiSelected(value);
    setKpiIndexSelected(index);
  }


  // SELECT TARGET 
  const [cardsTargetFiltered, setCardsTargetFiltered] = useState([]);

  useEffect(() => {
    var arrayTargetSelected = targets.list.filter((target) =>
      target.detail.kind === date.typeDate.toUpperCase() &&
      date.start <= target.detail.ends &&
      date.end >= target.detail.starts &&
      target.detail.config.destination.id === tree.selected.id &&
      target.detail.status !== 'INTERRUPTED'
    )

    setCardsTargetFiltered(arrayTargetSelected)

  }, [kpiIndexSelected, targets.list]);

  return (
    <>
      <div className="btn-group-kpi-multishop e-btn-group mx-md-3">
        <Scroller>
          {data.kpi.map((kpi, index) =>
            <KpiButton
              key={index}
              number={index}
              selected={kpiIndexSelected}
              dateType={date.typeDate}
              kpi={kpi}
              numberofbuttons={data.kpi.length}
              value={kpi.value}
              trend={kpi.percentage}
              selectKpi={(value, index) => selectKpi(value, index)}
              cardsTargetFiltered={cardsTargetFiltered}
            />
           
          )}
        </Scroller>

      </div>
      <div className='px-md-3 px-2'>
        <div className='row pb-3 mx-2 mx-md-0 m-3'>
          <div className='col-12 border-radius bg-grad-op25 p-3'>
            <TabLineChart
              type={data.kpi[kpiIndexSelected].kind}
              name={data.kpi[kpiIndexSelected].kind + data.kpi[kpiIndexSelected].subkind}
              id={`chart-${kpiSelected.kind.toLowerCase()}-${kpiSelected.subkind.toLowerCase()}`}
              data={kpiSelected.history}
              totalData={data}
              typeDate={date.typeDate}
            />
   
            <TargetCard
              type={data.kpi[kpiIndexSelected]}
              cardsTargetFiltered={cardsTargetFiltered}
              parametersFetch={{ getNew: "false", destinationId: tree.selected.id, destinationKind: tree.selected.kind, start: date.start, end: date.end }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
