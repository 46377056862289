import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconTableUpArrow from '../../../img/IconTableUpArrow.png'
import IconTableDownArrow from '../../../img/IconTableDownArrow.png'
import IconGradRightArrow from '../../../img/IconGradRightArrow.png'
import IconWhiteBigStore from '../../../img/IconWhiteBigStore.png'
import IconWhiteSession from '../../../img/IconWhiteSession.png'
import './responsivetable.css';
import { Formatters } from "..";

export const ResponsiveTable = ({ id, filters, data, valueToCompare, click }) => {
    const { i18n } = useTranslation();
    const [sortedData, setSortedData] = useState(data);
    const [filterActive, setFilterActive] = useState('');

    function saveSorting(newSortedData, value) {
        setFilterActive(value);
        setSortedData(newSortedData);
    }

    return (
        <div id={id} className={`responsive-table mx-md-2 scrollbarCustom ${i18n.language}`}>
            <TableHeaderSort
                filters={filters}
                data={data}
                filterActive={filterActive}
                saveSorting={saveSorting}
                valueToCompare={valueToCompare}
                mobile={true}
            />
            <div className="py-0 border-radius overflow-auto scrollbarCustom">
                <Table
                    filters={filters}
                    data={sortedData}
                    filterActive={filterActive}
                    saveSorting={saveSorting}
                    valueToCompare={valueToCompare}
                    click={click}
                />
            </div>
        </div>
    );
}

export const Table = ({ filters, data, filterActive, saveSorting, valueToCompare, click }) => {
    return (
        <table className="table bg-grey">
            <TableHeaderSort
                filters={filters}
                data={data}
                filterActive={filterActive}
                saveSorting={saveSorting}
                valueToCompare={valueToCompare}
                mobile={false}
            />
      
            <TableBody
                data={data}
                click={click}
            />
        </table>
    );
}

export const TableHeaderSort = ({ filters, data, filterActive, saveSorting, valueToCompare, mobile }) => {
    const { t } = useTranslation();

    function sortColumn(value) {
        const [field, order] = value.split('-');

        let sortedData = data.sort(function (a, b) {
            const aField = valueToCompare(a, field);
            const bField = valueToCompare(b, field);
            if (order === 'asc') {
                if (aField < bField) return -1;
                if (aField > bField) return 1;
            }
            else if (order === 'desc') {
                if (aField > bField) return -1;
                if (aField < bField) return 1;
            }
            return 0;
        });

        saveSorting(sortedData, value);
    }

    return mobile ?
        <div className="d-flex flex-md-row flex-column justify-content-between">

            {data.filter(level => level.kind === 'COMPANY_GROUP').length > 0 && data.filter(level => level.kind === "STORE_PHYSICAL").length === 0 ?
                <h3 className="c-black font-size-16 font-weight-bold my-2">{t("multishopsubgrouptable.titlegroup")}</h3> :
                data.filter(level => level.kind === 'COMPANY_GROUP').length === 0 && data.filter(level => level.kind === "STORE_PHYSICAL").length > 0 ?
                    <h3 className="c-black font-size-16 font-weight-bold my-2">{t("multishopsubgrouptable.titlestore")}</h3> :
                    <h3 className="c-black font-size-16 font-weight-bold my-2">{t("multishopsubgrouptable.titlestoreegroup")}</h3>
            }

            <div className="responsive-sort">
                <h3 className="c-dark-grey font-size-14 text-md-end font-weight-semibold m-2 text-uppercase">{t("multishopsubgrouptable.sort")}</h3>
                <div className="gradient-input-round mb-3 mt-2">
                    <select
                        className="e-select py-1 px-2 w-100"
                        style={{ minHeight: "35px" }}
                        onChange={(e) => sortColumn(e.target.value)}
                        value={filterActive}
                    >
                        {filters.fields.map((field) => {
                            return filters.orders.map((order, index) => {
                                const value = `${field}-${order}`;
                                return <option key={index} value={value}>{t(`multishopsubgrouptable.${value}`)}</option>
                            })
                        })}
                    </select>
                </div>
            </div>
        </div>
        :
        <thead>
            <tr className=" shadowDropLessBlur">
                {filters.fields.map((field, index) => {
                    return <th key={index} scope="col" className="text-start ps-2">
                        <div className="d-flex flex-row align-items-center">

                            {field !== "name" &&
                                <img key={index}
                                    src={field.split("_")[0] === "physical" ? IconWhiteBigStore : IconWhiteSession}
                                    alt="" className="img-icon25 pointer mx-1" />}

                            <h5 className="c-white font-size-16 font-weight-semibold mb-0">
                                {t(`multishopsubgrouptable.${field}`)}</h5>
                            <div className="d-flex flex-column">
                                {filters.orders.map((order, index) => {
                                    const value = `${field}-${order}`;
                                    return <img key={index} alt="" className="img-icon10 pointer mx-1"
                                        style={{ visibility: filterActive === value ? "hidden" : "visible" }}
                                        src={order === 'asc' ? IconTableDownArrow : IconTableUpArrow}
                                        onClick={() => sortColumn(value)}
                                    />
                                })}
                            </div>
                        </div>
                    </th>
                })}
                <th scope="col" className="text-start ps-1"></th>
            </tr>
        </thead>
}

const TableBody = ({ data, click }) => {
    return (
        <tbody>
            {data.map((tr, index) =>
                <tr className="shadowDropLessBlur" key={index}>
                    <td className="text-start text-break bg-white ps-3 py-3">
                        <div className="c-black font-weight-semibold pointer text-decoration-underline ms-2"
                            onClick={() => click(tr)}>
                            {tr.name}
                        </div>
                    </td>
                    {tr.kpi.map((td, index) => {
                        return <td key={index} className="bg-white ps-3 py-3"><p className="text-center text-break mb-0">
                            <Formatters.NumberFormatter value={td.value}
                                currency={td.kind + td.subkind === 'PHYSICALSALES' ||
                                    td.kind + td.subkind === 'PHYSICALPROFIT' ||
                                    td.kind + td.subkind === 'ONLINESALES' ||
                                    td.kind + td.subkind === 'ONLINEPROFIT'} />

                            {td.kind + td.subkind === 'PHYSICALSALES' || td.kind + td.subkind === 'PHYSICALPROFIT' || td.kind + td.subkind === 'ONLINESALES' || td.kind + td.subkind === 'ONLINEPROFIT' ? ' €' :
                                td.kind + td.subkind === 'PHYSICALCONVERSION' || td.kind + td.subkind === 'ONLINECONVERSION' ? ' %' : td.kind + td.subkind === 'PHYSICALPROFIT' ? ' €p.' : null}</p></td>
                    })}
                    <td className="bg-white justify-content-end ps-2 py-3">
                        <img alt="" className="img-icon30 float-endpointer pointer text-decoration-underline me-3"
                            src={IconGradRightArrow}
                            onClick={() => click(tr)}
                        />
                    </td>
                </tr>
            )}
        </tbody>
    )
}