import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useHistory } from "react-router";
import "./targetcard.css"
// i18next Translation
import { useTranslation } from 'react-i18next';

// FETCH
import { useAppState } from '../../../../../store/AppState';
import { DottedMenu, Formatters, InfoTooltip, LabelTarget } from '../../../../commons';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';

export function TargetCard({ cardsTargetFiltered, type, parametersFetch }) {
  const gotoPage = useHistory();
  const { t } = useTranslation();
  const [{account, date}] = useAppState();
  const [cardTarget, setCardTarget] = useState([]);


  useEffect(() => {
    let cardTargetSelected = cardsTargetFiltered.filter((target) =>
      target.type.kind === type.kind &&
      target.type.subkind === type.subkind
    )
    setCardTarget(cardTargetSelected)
  }, [cardsTargetFiltered]);

  return (
    <div id="TargetCard"
      className={type.kind === "PHYSICAL" ? "storeStyle" :
        type.kind === "ONLINE" ? "onlineStyle" : null}
    >
      {cardTarget.map((target) =>
        <div className="p-3 my-3 bg-white border-radius">

          <div className=" d-flex flex-column">
            <div className=" d-flex justify-content-between">
              <h1 className="c-black font-weight-bold font-size-20 text-wrap">{t(`multishoptargetcard.${(target.detail.kind + target.type.kind + target.type.subkind).toLowerCase()}`)}</h1>
              <div className="d-flex align-items-center justify-content-between mb-2" >

              {target.detail.status !== 'INTERRUPTED' && target.creator.id === account.data.user.id &&
            moment(target.detail.ends).toDate() >= moment().toDate() ?
            <DottedMenu
              idTarget={target.detail.id}
              parametersFetch={parametersFetch}
              nametarget={target.detail.kind + (target.type.kind + target.type.subkind).toLowerCase()}
            />
            : target.creator.id !== account.data.user.id && target.detail.status !== 'INTERRUPTED' &&
              moment(target.detail.ends).toDate() >= moment().toDate() ?
              <InfoTooltip />
              : <LabelTarget target={target} />
          } 
              </div>

            </div>
            <div className="d-flex align-items-end  flex-row flex-wrap">
              <h2 className="c-black font-size-22 font-weight-bold mb-0">{target.detail.current_value}</h2>
              <p className="c-dark-grey font-size-16 mb-0 ms-1">/{target.detail.target_value}</p>
            </div>

            <div className="d-flex flex-wrap flex-row align-items-start flex-md-row-reverse mt-2">

              <div className="d-flex flex-row flex-wrap">
                <div className="d-flex flex-column  mx-2 my-1">
                  <p className="c-medium-grey font-size-13 font-weight-bold text-uppercase m-0">{t("multishoptargetcard.starttarget")}</p>
                  <p className="font-size-14 m-0 font-weight-regular">{Formatters.FormatDateLocale(moment(target.detail.starts))}</p>
                </div>

                <div className="d-flex flex-column mx-2  my-1">
                  <p className="c-medium-grey font-size-13 font-weight-bold text-uppercase m-0">{t("multishoptargetcard.endtarget")}</p>
                  <p className="font-size-14 m-0 font-weight-regular">{Formatters.FormatDateLocale(moment(target.detail.ends))}</p>
                </div>
 
               {(moment(target.detail.ends))._d > (moment())._d &&
                <div className="d-flex flex-column mx-2  my-1">
                  <p className="c-medium-grey font-size-13 font-weight-bold text-uppercase m-0">{t("multishoptargetcard.remaining")}</p>
                  <p className="font-size-14 m-0 font-weight-regular">
                    {Math.ceil((moment(target.detail.ends).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24))} 
                    
                    {Math.ceil((moment(target.detail.ends).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24))===1?
                    <span className="ms-1">{t("multishoptargetcard.day")}</span>
                    :<span className="ms-1">{t("multishoptargetcard.days")}</span>
                  }</p>
                </div>}

                <div className="d-flex flex-row align-items-end ms-2">
                  <div className="d-flex flex-column">
                    <p className="c-medium-grey font-size-13 font-weight-bold text-uppercase m-0">{t("multishoptargetcard.createdby")}</p>

                    {target.creator.id === account.data.user.id ?
                      <div className="d-flex">
                        <p className="c-black font-size-16 font-weight-regular m-0">{t("multishoptargetcard.you")}</p>
                        <span className="circleName-grad-s d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
                      m-0  font-size-11 ms-2">{target.creator.name.substring(0, 1) + target.creator.surname.substring(0, 1)}</span>
                      </div> :
                      <div className="d-flex">
                        <p className="c-black font-size-16 font-weight-semibold m-0">{target.creator.name + target.creator.surname}</p>
                        <span className="circleName-s d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
                      m-0  font-size-11 ms-2">
                          {target.creator.name.substring(0, 1) + target.creator.surname.substring(0, 1)}
                        </span>
                      </div>
                    }
                  </div>

                </div>
              </div>


              <div className="progress-description d-flex  flex-row mt-2 flex-1 ">
                <p className="c-medium-grey font-size-16 font-weight-bold text-uppercase">{t("multishoptargetcard.completion")}</p>
                {Formatters.FormatDate(moment(target.detail.starts)) <= Formatters.FormatDate(moment()) &&
                  <p className={`c-black font-size-16 font-weight-bold mb-0 ms-1 ${target.type.kind === "PHYSICAL" ? "c-tiffany" : "c-purple"}`}>{Math.trunc((target.detail.current_value / target.detail.target_value) * 100)}%</p>
                }
              </div>

            </div>
            {Formatters.FormatDate(moment(target.detail.starts)) < Formatters.FormatDate(moment()) &&
              target.type.subkind !== "CONVERSION" &&
              <ProgressBar now={(target.detail.current_value / target.detail.target_value) * 100} />

            } </div>

        </div>
      )
      }
      {cardsTargetFiltered.filter(targetFiltered => targetFiltered.type.kind === type.kind && targetFiltered.type.subkind === type.subkind).length === 0 &&
        Formatters.FormatDate(moment(date.end)) > Formatters.FormatDate(moment()) &&
        <div className="p-2 my-2 d-flex justify-content-end">
          <ButtonComponent
            className="addTarget-button mt-2 font-weight-bold"
            onClick={() => gotoPage.push("/createnewtarget")}
          >{t("multishoptargetcard.addtarget")}</ButtonComponent>
        </div>
      }
    </div>


  );
}