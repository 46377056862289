import React from 'react';
import IconWhiteTarget from '../../../../img/IconWhiteTarget.png';
import './kpibutton.css';
// i18next Translation
import { useTranslation } from 'react-i18next';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Formatters } from '../../../commons';
import moment from 'moment';

export function KpiButton({ kpi, value, trend, dateType, selectKpi, number, selected, cardsTargetFiltered, numberofbuttons }) {
  const { t } = useTranslation();

  const getStatus = (kpiButton) => {
    let status = "";

    let buttonTargetKpi = cardsTargetFiltered.filter((target, index) =>
      target.type.kind === kpiButton.kind &&
      target.type.subkind === kpiButton.subkind
    )

    if (buttonTargetKpi && buttonTargetKpi[0]) {
      if (moment(buttonTargetKpi[0].detail.ends).toDate() < moment().toDate() &&
        buttonTargetKpi[0].detail.current_value < buttonTargetKpi[0].detail.target_value) {
        status = "fail";
      } else if (moment(buttonTargetKpi[0].detail.ends).toDate() < moment().toDate() &&
        buttonTargetKpi[0].detail.current_value >= buttonTargetKpi[0].detail.target_value) {
        status = "complete";
      } else {
        status = "progress";
      }

    } else {
      status = "none";
    }
    return status
  }

  return (
    <ButtonComponent cssClass={`btn-kpi-multishop w-auto p-0 border-radius border-none ${selected === number && 'active'}`}
      onClick={() => selectKpi(kpi, number)}>
      <div className={`header shadowDropMediumBlur border-radius target-${getStatus(kpi)}`}
        style={{ width: `calc( ((100vw - 5.5rem - 1px - (${numberofbuttons-1} * 8px)) / ${numberofbuttons})` }}
      >
        <div className='state d-flex justify-content-center align-items-center'>
          <img alt='' className='img-icon25 me-1' src={IconWhiteTarget} />
          <p className='m-0 c-white font-size-14 font-weight-bold text-uppercase'>
            {t(`kpibutton.${getStatus(kpi)}`)}
          </p>
        </div>

        <div
           className={`minHeight m-0 px-3 py-2 d-flex flex-column bg-white border-radius justify-content-between  ${kpi.kind.toLowerCase()}`}
        >
          <div className='d-flex flex-row justify-content-between align-items-start mb-1'>
            <h1 className='c-black font-size-16 font-weight-regular mb-0 mt-1 text-start'>
              {t(`kpibutton.${(kpi.kind + kpi.subkind).toLowerCase()}`)}
            </h1>
            <span className='ms-2 mt-1 iconCash'></span>
          </div>

          <div className='d-flex flex-column align-items-end '>

            <h3 className='c-black font-size-18 font-weight-bold align-self-end mb-1'>

              <Formatters.NumberFormatter value={value}
                currency={kpi.kind + kpi.subkind === 'PHYSICALSALES' || kpi.kind + kpi.subkind === 'PHYSICALPROFIT' ||
                  kpi.kind + kpi.subkind === 'PHYSICALAVERAGE_RECEIPT' || kpi.kind + kpi.subkind === 'PHYSICALPROFIT' ||
                  kpi.kind + kpi.subkind === 'ONLINEPROFIT' || kpi.kind + kpi.subkind === 'ONLINESALES' ||
                  kpi.kind + kpi.subkind === 'ONLINEAVERAGE_TRANSACTION'
                }
              />
              <Formatters.FormatCurrency typeKpi={kpi.kind + kpi.subkind} />
            </h3>

            <h4 className={`${trend > 0 ? "c-green" : trend < 0 ? "c-red" : "c-medium-grey"}
           font-size-15 font-weight-semibold align-self-end mb-1 d-flex flex-row`}>

              {trend > 0 ? "+" : null}<Formatters.NumberFormatter value={parseInt(trend)} /> %

              <span className={`${trend > 0 ? "icon-trendUp" : trend < 0 ? "icon-trendDown" : "icon-trendDraw"} ms-1`} />
            </h4>
            <p className='c-dark-grey font-size-12 font-weight-regular align-self-end mb-1'>
              {t(`kpibutton.from${dateType}`)}
            </p>
          </div>
        </div>
      </div>
    </ButtonComponent>
  );
}
