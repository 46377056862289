import { treeReducer } from './tree';
import { accountReducer } from './account';
import { targetsReducer } from './targets';
import { dateReducer } from './date';
import { headersSingleshopReducer } from './headersSingleshop';
import { Formatters } from '../components/commons';
import moment from 'moment';

const initialState = {
  tree: { loading: true, full: {}, selected: {}, breadcrumb: [] },
  account: { loading: true, data: {} },
  targets: { loading: true, new: [], list: [] },
  date: { typeDate: 'day', start: Formatters.FormatDateTZ(moment().startOf('day')), end: Formatters.FormatDateTZ(moment().endOf('day')) },
  headersSingleshop: []
};

const combineReducers = (reducers) => {
  return (state, action) => {
    return Object.keys(reducers).reduce((acc, prop) => {
      return {
        ...acc,
        ...reducers[prop]({ [prop]: acc[prop] }, action),
      };
    }, state);
  };
};

export { initialState, combineReducers, treeReducer, accountReducer, targetsReducer, dateReducer, headersSingleshopReducer };
