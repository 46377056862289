import React, { useEffect, useState } from 'react';
import { useAppState } from '../../../../store/AppState';
import { Scroller } from '../../../commons';
import { KpiButton } from '../commons/KpiButton';
import { TargetCard } from '../commons/TargetCard';
import { TabLineChartSingleshop } from '../commons/TabLineChartSingleshop';

export function OnlineTabContainer({ data, parametersFetch }) {

  const [{ tree, targets, date }] = useAppState();
  const [kpiSelected, setKpiSelected] = useState(data.store.filter(element => element.kind === "ONLINE")[0]);
  const [kpiIndexSelected, setKpiIndexSelected] = useState(0);
  const [subData, setSubData] = useState([]);
  const [loadingSubData, setLoadingSubData] = useState(true);

  useEffect(() => {
    if (loadingSubData) {
      let subDataArray = [];
      if (kpiSelected.kind === "ONLINE") {
        data.online.forEach((online) => {
          const kpiFiltered = online.kpi.filter(elem => elem.kind === kpiSelected.kind && elem.subkind === kpiSelected.subkind);
          const history = kpiFiltered.length > 0 ? kpiFiltered[0].history : [];
          subDataArray.push({ name: online.name, history: history })
        })
      }

      setSubData(subDataArray);
      setLoadingSubData(false);
    }
  }, [kpiSelected.kind, kpiSelected.subkind, loadingSubData])


  const selectKpi = (value, index) => {
    setKpiSelected(value);
    setKpiIndexSelected(index);
    setLoadingSubData(true);
  }

  // SELECT TARGET 
  const [cardsTargetFiltered, setCardsTargetFiltered] = useState([]);

  useEffect(() => {
    var arrayTargetSelected = targets.list.filter((target, index) =>

      target.detail.kind === date.typeDate.toUpperCase() &&
      date.start <= target.detail.ends &&
      date.end >= target.detail.starts &&
      target.detail.config.destination.id === tree.selected.id &&
      target.detail.status !== 'INTERRUPTED'
    )

    setCardsTargetFiltered(arrayTargetSelected)

  }, [kpiIndexSelected, targets.list]);


  return (
    <>
      <div className="btn-group-kpi-multishop e-btn-group ">
        <Scroller
          id="online">
          {data.store.filter(kpi => kpi.subkind !== 'CARTS' && kpi.kind === 'ONLINE').map((kpi, index) =>
            <KpiButton
              key={index}
              number={index}
              selected={kpiIndexSelected}
              dateType={date.typeDate}
              kpi={kpi}
              numberofbuttons={data.store.filter(kpi => kpi.subkind !== 'CARTS' && kpi.kind === 'ONLINE').length}
              value={kpi.value}
              trend={kpi.percentage}
              selectKpi={(value, index) => selectKpi(value, index)}
              cardsTargetFiltered={cardsTargetFiltered}
            />
          )}
        </Scroller>
      </div>
      <div className=''>
        <div className='row pt-0 mb-0 mx-0 m-3'>
          <div className='col-12 border-radius bg-grad-purple-op25 p-3'>
            {!loadingSubData && <TabLineChartSingleshop
             name={kpiSelected.kind+kpiSelected.subkind}
              type={kpiSelected.kind}
              id={`chart-${kpiSelected.kind.toLowerCase()}-${kpiSelected.subkind.toLowerCase()}`}
              mainData={kpiSelected}
              subData={subData}
              typeDate={date.typeDate}
            />}
            <TargetCard
              type={kpiSelected}
              cardsTargetFiltered={cardsTargetFiltered}
              parametersFetch={parametersFetch}
            />
          </div>
        </div>
      </div>
    </>
  );
}