import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from 'react-bootstrap/Dropdown'
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import "./inputs.css"
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';

export function InputPassword({ value, setPassword, handleKeyPress }) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    const enterSubmit = (e) => {
        if (handleKeyPress) handleKeyPress(e);
    }

    return (
        <div className="gradient-input input-show-hide w-100 mb-3 mt-2">
            <input
                className="e-input w-100 py-1 px-2"
                type={showPassword ? "text" : "password"}
                placeholder={t("login.placeholderPassword")}
                onChange={(e) => setPassword(e.target.value)}
                onKeyPress={(e) => enterSubmit(e)}
                value={value}
            />
            <span
                className="c-violet bg-white d-flex align-items-center justify-content-center px-1 not-selectable font-weight-regular font-size-13 pointer"
                style={{ minWidth: "65px" }}
                onClick={() => setShowPassword(!showPassword)} >
                {showPassword ? `${t("login.hide")}` : `${t("login.show")}`}
            </span>
        </div>
    );
}

export function InputText({ placeholder, value, onChange, handleKeyPress }) {
    const enterSubmit = (e) => {
        if (handleKeyPress) handleKeyPress(e);
    }

    return (
        <div className="gradient-input mb-3 mt-2 w-auto">
            <input
                className="e-input w-100 py-1 px-2"
                type="text"
                placeholder={placeholder}
                onChange={(e) => onChange(e.target.value)}
                onKeyPress={(e) => enterSubmit(e)}
                value={value}
            />
        </div>
    );
}

export function InputTextIcon({ Icon, disabled,decimal, value, onChange, placeholder }) {


    //     const valueNotZero = (numberValue) => {

    //         // const reg = new RegExp('[0-9]');


    //         console.log( onChange(numberValue))



    //         numberValue = numberValue + ""


    //     if (numberValue === "0" ||numberValue === "" ) {
    //             onChange("")}
    // else{
    //         switch (numberValue.slice(-1)) {
    //             case "0":
    //             case "1":
    //             case "2":
    //             case "3":
    //             case "4":
    //             case "5":
    //             case "6":
    //             case "7":
    //             case "8":
    //             case "9":

    //                 onChange(numberValue)
    //                 break;
    //             default:
    //                 onChange(numberValue.slice(0,-1))
    //             // code block
    //         }

    //     }


    // if (numberValue === "0") {
    //     onChange("")
    // } else if (numberValue === "") {
    //     onChange("")

    // } else if (
    //     numberValue.slice(-1) !== '0' ||
    //     numberValue.slice(-1) !== '1' ||
    //     numberValue.slice(-1) !== '2' ||
    //     numberValue.slice(-1) !== '3' ||
    //     numberValue.slice(-1) !== '4' ||
    //     numberValue.slice(-1) !== '5' ||
    //     numberValue.slice(-1) !== '6' ||
    //     numberValue.slice(-1) !== '7' ||
    //     numberValue.slice(-1) !== '8' ||
    //     numberValue.slice(-1) !== '9') {
    //     onChange(numberValue)
    //     console.log(numberValue.slice(-1) !== '2')

    // } else {


    //     onChange(numberValue)
    // }
// }


return (
    <div
        style={disabled ? { opacity: 0.5 } : null}
        className="gradient-input input-show-hide w-100 "
        >
             <div
      
        className="bg-white w-100 input-radius-border justify-content-between"
        >
        <NumericTextBoxComponent
        enabled={!disabled}
        placeholder={placeholder}
        className="e-input w-100 py-1 px-2 bg-white"
             format="###"
            value={value}
            // decimals={decimal}
            min={1}
            step={1}
            onChange={(e) => onChange(e.target.value)} 
            />

</div>

        {/* <input
            disabled={disabled}
            className="e-input w-100 py-1 px-2 bg-white"
            placeholder={placeholder}
            // type="number"
            pattern="\d*"
            min="1"
            value={value}
            onChange={(e) => valueNotZero(e.target.value)}
        // onKeyDown={(event) => { 
        //     if (event.key === 'e' ||
        //         event.key === 'E' ||
        //         event.key === '+' ||
        //         event.key === '-' ||
        //         event.key === '.' ||
        //         event.key === ',')
        //         event.preventDefault();
        // }}
        />*/}

        <img
            alt=""
            src={Icon}
            className="bg-white iconInput " />
    </div>
);
}

export function Select({ listOptions, onChange, disabled, value }) {
    return (
        <div className="gradient-input-round"
            style={disabled ? { opacity: 0.5 } : null}
        >
            <select
                value={value}
                disabled={disabled}
                className="e-select w-100"
                onChange={(e) => onChange(e.target.value)}
            >
                {listOptions.map((option, index) => {
                    return <option key={index} value={option.value}>{option.name}</option>
                })}
            </select>
        </div>
    );
}

export function CheckBoxDropDown({ title, listCheckbox, onChange, filterChecked }) {
    return (
        <Dropdown
            className="checkBoxDropDown "
            drop="down"
            autoClose="outside">
            <Dropdown.Toggle variant="" id="dropdown-kpi">
                <div className="gradient-input-round  ">
                    <div className="input-grad-innercontainer py-1 px-3 w-100">
                        <h3 className="font-size-16 d-flex justify-content-between w-100 m-0">{title}</h3>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {listCheckbox && listCheckbox.map((option, index) =>
                    <Dropdown.Item href="#/action-1" key={index} >
                        <div className="checkbox-grad-container position-relative"
                            onChange={onChange}>
                            <label htmlFor={title + index} className="control control-checkbox c-black m-0 font-size-16 ms-2 w-100 h-100 ">
                                <input
                                    id={title + index}
                                    type="checkbox"
                                    key={index}
                                    value={JSON.stringify(option)}
                                    name={title + index}
                                    checked={filterChecked.filter(element => option.value === element.value).length > 0}
                                />
                                <div class="control_indicator"></div>
                                {option.name}</label>
                        </div>
                    </Dropdown.Item>
                )}

            </Dropdown.Menu>
        </Dropdown>
    );
}

export function CheckBoxDropDownTree({ title, tree, onChange, filterChecked }) {
    const treeView = useRef(null);

    const nodeTemplate = (data) => {
        return (
            <div id="treeviewSidebarTemplateFilter" >
                <div className="treeviewdiv">
                    <div className={`d-flex align-items-center`} onChange={(e) => onChange(e)}>
                        <label htmlFor={data.name} className="txtName control control-checkbox align-items-center c-black font-size-18 d-flex font-weight-regular m-0 ms-1 me-1">
                            {data.kind === "STORE_PHYSICAL" ?
                                <span className="sotreIconTree e-badge e-badge-primary d-flex justify-content-center align-items-center me-2" /> :
                                <span className="circleName d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold m-0  font-size-13 me-2">
                                    {data.name.substring(0, 2)}
                                </span>
                            }
                            <input
                                id={data.name}
                                type="checkbox"
                                value={JSON.stringify({ name: data.name, value: data.identifier, type: "LEVEL" })}
                                name={data.name}
                                checked={filterChecked.filter(element => element.value === data.identifier).length > 0}
                            />
                            <div class="control_indicator"></div>

                            {data.name}
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    const created = () => {
        treeView.current?.expandAll();
    }

    useEffect(() => {
        treeView.current?.expandAll();
    })

    return (
        <Dropdown
            className="checkBoxDropDown"
            drop="down"
            autoClose="outside"
        >
            <Dropdown.Toggle variant="" id="dropdown-kpi" >
                <div className="gradient-input-round  ">
                    <div className="input-grad-innercontainer py-1 px-3 w-100">
                        <h3 className="font-size-16 d-flex justify-content-between w-100 m-0">{title}</h3>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <TreeViewComponent
                    id="treeview"
                    nodeTemplate={nodeTemplate}
                    ref={treeView}
                    created={created}
                    fields={{ dataSource: tree, id: "identifier", parentID: 'parentID', text: 'name', child: 'haschild' }}
                    fullRowSelect={false}
                    allowMultiSelection
                />
            </Dropdown.Menu>
        </Dropdown>
    );
}


export function CheckBoxGroup({ title, listCheckbox, onChange, filterChecked }) {
    return (<div onChange={onChange} className="">
        {listCheckbox && listCheckbox.map((option, index) =>
            <div className="mb-1 d-flex align-items-center position-relative" >
                <label htmlFor={title + index} className="control control-checkbox c-black m-0 font-size-18 ms-2 w-100 h-100 ">
                    <input
                        id={title + index}
                        type="checkbox"
                        key={index}
                        value={JSON.stringify(option)}
                        name={title + index}
                        checked={filterChecked.filter(element => option.value === element.value).length > 0}
                    />
                    <div class="control_indicator"></div>
                    {option.name}</label>
            </div>

        )}
    </div>
    );
}

export function CheckBoxTree({ tree, onChange, filterChecked }) {
    const treeView = useRef(null);

    const nodeTemplate = (data) => {
        return (
            <div id="treeviewSidebarTemplateFilter">
                <div className="treeviewdiv">
                    <div className={`d-flex align-items-center`} onChange={(e) => onChange(e)}>
                        <label htmlFor={data.name} className="txtName control control-checkbox align-items-center c-black font-size-18 d-flex font-weight-regular m-0 ms-1 me-1">
                            {data.kind === "STORE_PHYSICAL" ?
                                <span className="sotreIconTree e-badge e-badge-primary d-flex justify-content-center align-items-center me-2" /> :
                                <span className="circleName d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold m-0  font-size-13 me-2">
                                    {data.name.substring(0, 2)}
                                </span>
                            }
                            <input
                                id={data.name}
                                type="checkbox"
                                value={JSON.stringify({ name: data.name, value: data.identifier, type: "LEVEL" })}
                                name={data.name}
                                checked={filterChecked.filter(element => element.value === data.identifier).length > 0}
                            />
                            <div class="control_indicator"></div>

                            {data.name}
                        </label>
                    </div>
                </div>
            </div>
        );
    }

    const created = () => {
        treeView.current?.expandAll();
    }

    useEffect(() => {
        treeView.current?.expandAll();
    })

    return (
        <TreeViewComponent
            id="treeview"
            nodeTemplate={nodeTemplate}
            ref={treeView}
            created={created}
            fields={{ dataSource: tree, id: "identifier", parentID: 'parentID', text: 'name', child: 'haschild' }}
            fullRowSelect={false}
            allowMultiSelection
        />
    );
}





export function FormDropDownTree({ title, tree, onChange }) {
    const { t } = useTranslation();
    const treeView = useRef(null);
    const [levelName, setLevelName] = useState(title);
    const [showDropdown, setShowDropdown] = useState(false);

    const nodeTemplate = (data) => {
        return (
            <div id="treeviewSidebarTemplateFilter" >
                <div className="treeviewdiv">
                    <div className={`d-flex align-items-center`} onClick={() => { onChange(data); setLevelName(data.name); setShowDropdown(false) }}>
                        <div className="txtName  align-items-center c-black font-size-18 d-flex font-weight-regular m-0  me-2">
                            {data.kind === "STORE_PHYSICAL" ?
                                <span className="sotreIconTree e-badge e-badge-primary d-flex justify-content-center align-items-center me-2" /> :
                                <span className="circleName d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold m-0 font-size-13">
                                    {data.name.substring(0, 2)}
                                </span>
                            }
                            <p className="ps-2 mb-0">{data.name}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        showDropdown && treeView.current?.expandAll();
    }, [showDropdown])

    return (
        <Dropdown
            className="formDropDownTree"
            drop="down"
            show={showDropdown}
            autoClose="outside">
            <Dropdown.Toggle variant="" id="dropdown-kpi">
                <div className="gradient-input-round" onClick={() => setShowDropdown(!showDropdown)}>
                    <div className="input-grad-innercontainer py-1 px-2 w-100">
                        <h3 className="font-size-16 font-weight-regular d-flex justify-content-between w-100 m-0">{t(levelName)}</h3>
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>

                <TreeViewComponent
                    id="treeview"
                    nodeTemplate={nodeTemplate}
                    ref={treeView}
                    fields={{ dataSource: tree, id: "identifier", parentID: 'parentID', text: 'name', child: 'haschild' }}
                    fullRowSelect={false}
                    allowMultiSelection
                />
            </Dropdown.Menu>
        </Dropdown>

    );
}
