import React, { useState, useEffect } from "react";

import IconGradDottedMenu from "../../../../img/IconGradDottedMenu.png"
import IconRedHourglass from "../../../../img/IconRedHourglass.png"
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import './dottedmenu.css'

import { useDisableTargetUser } from '../../../../apis/fetch';
import { verifyResponse } from '../../../../apis/response';
// i18next Translation
import { useTranslation } from 'react-i18next';
import { targetsRequest } from "../../../../actions";
import { useAppState } from "../../../../store/AppState";


export function DottedMenu({ idTarget, nametarget, parametersFetch }) {
    const { t } = useTranslation();
    const disableTargetUser = useDisableTargetUser(idTarget);
    const [show, setShow] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [state, dispatch] = useAppState();

    // FETCH INTERROMPI OBIETTIVO
    useEffect(() => {
        if (disableTargetUser.content.isLoading === 'FETCHED') {

            if (verifyResponse(disableTargetUser, 200)) {
                setIsDeleted(true);
            } else console.log(disableTargetUser.content.response.data);
        }
    }, [disableTargetUser]);

    const interruptTarget = () => {
        disableTargetUser.sendRequest({ loading: "FETCHING" });
    }

    const closeModal = () => {
        dispatch(targetsRequest(dispatch, parametersFetch));
        setShow(false);
    }


    return (
        <>
            <div id="DottedMenuTargetCard" className="" >
                <Dropdown align="end">
                    <Dropdown.Toggle variant="" id="dropdown-basic" className="p-0">
                        <img src={IconGradDottedMenu} alt="menu" className="pointer" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                        <Dropdown.Item onClick={() => setShow(true)}><div className="d-flex align-items-center my-2 pb-1"><p className="c-red font-size-18 mb-0">{t(`dottedmenu.dropstoptarget`)}</p><img src={IconRedHourglass} alt="" className="ms-2" /></div></Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <Modal
                show={show}
                onHide={() => closeModal()}
                backdrop="static"
                className="modalTargetCard"
                centered>
                <Modal.Header closeButton>
                </Modal.Header>
                {!isDeleted ?
                    <>
                        <Modal.Body><h2 className="c-black font-size-20 text-center">{t(`dottedmenu.titlestoptarget`)}</h2>
                            <p className="c-black font-size-16 text-center">{t(`dottedmenu.descriptionstoptarget`)}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='d-flex w-100 ' >
                                <div className='justify-content-around w-50 text-center pt-3 pb-4 border-right'>
                                    <div className="c-black font-size-20 pointer text-decoration-none" onClick={() => setShow(false)}>
                                        {t(`dottedmenu.cancel`)}
                                    </div>
                                </div>
                                <div className='justify-content-around w-50 text-center pb-4 pt-3'>
                                    <div className="c-red font-size-20 pointer text-decoration-none "
                                        onClick={() => interruptTarget()}>
                                        {t(`dottedmenu.confirmstop`)}
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </>
                    :
                    <Modal.Body>
                        <div className="mb-5 mt-4">
                            <p className="c-black font-size-16 text-center"> {t(`dottedmenu.stoptargetsuccess`)}</p>
                        </div>
                    </Modal.Body>
                }
            </Modal>
        </>
    );
}