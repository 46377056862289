import { getTree } from '../middleware/apis';

export const treeRequest = (dispatch) => {
  return { type: 'TREE_REQUEST', payload: { loading: true, call: () => getTree(dispatch) } };
};

export const treeSuccess = ({ data }) => {
  return { type: 'TREE_SUCCESS', payload: { loading: false, data: data } };
};

export const treeError = () => {
  return { type: 'TREE_ERROR', payload: { loading: false, data: [] } };
};

export const treeSelected = ({ dispatch, data, full }) => {
  return { type: 'TREE_SELECTED', payload: { loading: false, data, call: () => dispatch(treeBreadcrumb({full, selected:data})) } };
};

export const treeBreadcrumb = ({ full, selected }) => {
  return { type: 'TREE_BREADCRUMB', payload: { loading: false, full, selected } };
};
