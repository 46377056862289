import LogoGradImok from "../../../img/LogoGradImok.png"
import IconVioletFacebook from "../../../img/IconVioletFacebook.png"
import IconVioletTwitter from "../../../img/IconVioletTwitter.png"
import IconVioletInstagram from "../../../img/IconVioletInstagram.png"
import IconVioletLinkedin from "../../../img/IconVioletLinkedin.png"
import { useTranslation } from 'react-i18next';
import { LanguageSelectorFooter } from "../languageSelectors/LanguageSelectorFooter";

export function FooterPoweredBy() {
    return (
        <div id="footerPoweredBy" className="px-md-3">
            <div className="d-flex justify-content-end p-0">
                <a href="https://www.imok.fr/" target="_blank" rel="noreferrer"><img alt="" className="img-icon80 " src={LogoGradImok} /></a>
            </div>
        </div>
    );
}

export function FooterSocialAndFaq() {
    const { t } = useTranslation();
    const openLinkImage = (e) => e.target.nextSibling.click();

    return (
        <div id="FooterSocialAndFaq" className="bg-white d-flex ">
            <div className="container-fluid">
                <div className="row py-3 align-items-end">
                    <div className="col-md-auto col-xs-12 d-flex flex-column justify-content-center px-5 py-2">
                        <div className="mb-0 mt-1 mx-2  align-self-center align-self-md-start">
                            <LanguageSelectorFooter />
                        </div>

                        <p className="c-violet font-weight-regular mb-1 mx-2 text-size12 align-self-center  align-self-md-start ">{t("login.followon")}</p>
                        <div className=" d-flex justify-content-start align-self-center p-0">
                            <img alt="" className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletFacebook} onClick={openLinkImage}/>
                            <a id="facebook" className="d-none" href="https://www.facebook.com/imoksolutions" target="_blank" rel="noreferrer">Facebook</a>
                            <img alt="" className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletTwitter} onClick={openLinkImage}/>
                            <a id="twitter" className="d-none" href="https://twitter.com/imoksolution" target="_blank" rel="noreferrer">Twitter</a>
                            <img alt="" className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletInstagram} onClick={openLinkImage}/>
                            <a id="instagram" className="d-none" href="https://www.instagram.com/imok_solution/" target="_blank" rel="noreferrer">Instagram</a>
                            <img alt="" className="img-icon20 my-0 mx-2 iconsocial pointer" src={IconVioletLinkedin} onClick={openLinkImage}/>
                            <a id="linkedin" className="d-none" href="https://www.linkedin.com/company/i-m-ok/" target="_blank" rel="noreferrer">Linkedin</a>
                        </div>
                    </div>

                    <div className="col-md col-xs-12 d-flex flex-wrap  justify-content-center p-2">
                        <a className="c-heavenly font-weight-regular my-1 mx-2 pointer" href="https://www.imok.fr/" target="_blank" rel="noreferrer">{t("login.imokgroup")}</a>
                        <a className="c-heavenly font-weight-regular my-1 mx-2 pointer" href="https://www.imok.fr/contact/" target="_blank" rel="noreferrer">{t("login.contact")}</a>
                    </div>
                    <div className="col-md-auto col-xs-12 justify-content-center  d-flex px-5 py-2">
                        <img alt="" className="img-logo80 m-2" src={LogoGradImok} />
                    </div>
                </div>
            </div>
        </div>
    );
}