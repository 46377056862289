import { Category, ChartComponent, Inject, BarSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import React, { useEffect, useRef } from 'react';
import "./cardaffluencechart.css"

export function CardAffluenceChart({ data }) {
    const chartObj = useRef(null);

    /* useEffect(() => {
        refreshWithChart();
        window.addEventListener("resize", refreshWithChart);
        return () => {
            window.removeEventListener("resize", refreshWithChart);
        }
    }, []) */

    function onPointRender(args) {
        if (args.point.index === 0) {
            args.fill = '#007996';
        }
        else if (args.point.index === 1) {
            args.fill = '#4BB6C9';
        } else if (args.point.index === 2) {
            args.fill = '#01B5BC';
        }
        else if (args.point.index === 3) {
            args.fill = '#97EAEA';
        }
    }
    /* 
        const refreshWithChart = () => {
            document.getElementById('affluence_chart').style.width = `calc(${document.getElementById('affluence_chart_svg').clientWidth}px + 2rem)`;
        } */

    return (
        <div id="CardAffluenceChart" >
            <div className="bg-white border-radius p-3 overflow-hide">
                <ChartComponent
                    id="affluence_chart"
                    width="100%"
                    height={45 * data.devices.zones.length + 60 + ""}
                    primaryXAxis={{
                        valueType: 'Category',
                        majorGridLines: { width: 0 }
                    }}
                    chartArea={{ border: { width: 0 } }}
                    pointRender={onPointRender}
                    ref={chartObj}
                >
                    <Inject services={[BarSeries, Category]} />
                    <SeriesCollectionDirective  >
                        <SeriesDirective
                            dataSource={data.devices.zones}
                            cornerRadius={{ topRight: 20, bottomRight: 20 }}
                            yName='value'
                            xName='name'
                            type='Bar'
                            fill="#43CEDA"
                        >
                        </SeriesDirective>
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </div>
    );
}