import React from "react";
import { useHistory } from "react-router";
import Dropdown from 'react-bootstrap/Dropdown';


import IconBlackRightArrow from "../../../img/IconBlackRightArrow.png";
import "./myaccountdropdown.css";
import { useTranslation } from "react-i18next";

export const MyAccountDropdown = () => {
    const gotoPage = useHistory();
    const { t } = useTranslation();
    return (
        <Dropdown id="myAccountDropdown">
            <Dropdown.Toggle >
                <span className="iconUser transition"></span>
            </Dropdown.Toggle>
            <Dropdown.Menu className="ms-2 px-1 mt-2">
                <div className="mx-0">
                    <Dropdown.Item className="pb-0 ">
                        <button
                            onClick={() => gotoPage.push("/myaccount")}
                            className="d-flex pt-4 align-items-center w-100 border-bottom-grey">
                            <div className="mb-2 d-flex align-items-center w-100">
                                <span className="iconAccount" />
                                <p className="ms-2 c-black font-size-18 mb-0 d-flex flex-1">{t("myaccountdropdown.account")}</p>
                                <img alt="" className="img-icon15 ms-3" src={IconBlackRightArrow} />
                            </div>
                        </button>
                    </Dropdown.Item>
                    <Dropdown.Item className="pb-0 ">
                        <button
                            onClick={() => gotoPage.push("/conditionsofsale")}
                            className="d-flex pt-4 align-items-center w-100 border-bottom-grey">
                            <div className="mb-2 d-flex align-items-center w-100">
                                <span className="iconConditionsofsales" />
                                <p className="ms-2 c-black font-size-18 mb-0 d-flex flex-1">{t("myaccountdropdown.conditionsofsale")}</p>
                                <img alt="" className="img-icon15 ms-3" src={IconBlackRightArrow} />
                            </div>
                        </button>
                    </Dropdown.Item>
                    <Dropdown.Item className="pb-0  mb-4">
                        <button
                            onClick={() => gotoPage.push("/privacy")}
                            className="d-flex pt-4 align-items-center w-100 border-bottom-grey">
                            <div className="mb-2 d-flex align-items-center w-100">
                                <span className="iconPrivacy" />
                                <p className="ms-2 c-black font-size-18 mb-0 d-flex flex-1">{t("myaccountdropdown.privacy")}</p>
                                <img alt="" className="img-icon15 ms-3" src={IconBlackRightArrow} />
                            </div>
                        </button>
                    </Dropdown.Item>
                </div>
                <Dropdown.Item className="py-2 px-0 border-top-grey">
                    <button
                        onClick={() => gotoPage.push("/")}
                        className="d-flex m-auto mt-2 align-items-center">
                        <span className="iconLogout" />
                        <p className="ms-2 c-black font-size-18 mb-0 font-weight-semibold">{t("myaccountdropdown.logout")}</p>
                    </button>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}