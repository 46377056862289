import React from 'react';
import { LabelTarget, DottedMenu, InfoTooltip } from "../../../../commons";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';
import "./targetcardoverview.css"
import { useAppState } from '../../../../../store/AppState';
import moment from 'moment';

export function TargetCardOverview({ dimension, target, parametersFetch }) {
  const { t, i18n } = useTranslation();
  const [state,] = useAppState();

  return (
    <div id="TargetCardOverview" className={`p-2 col-12 ${dimension}`} >
      <div className="targetCard bg-white shadowDropLessBlur border-radius p-3 d-flex flex-column h-100 " >
        <div className="d-flex align-items-center justify-content-between mb-2" >
          <p className="c-black font-size-18 font-weight-bold m-0 text-capitalize">{t(`targetcardoverview.${(target.type.kind + target.type.subkind).toLowerCase()}`)}</p>


          {target.detail.status !== 'INTERRUPTED' && target.creator.id === state.account.data.user.id &&
            moment(target.detail.ends).toDate() >= moment().toDate() ?
            <DottedMenu
              idTarget={target.detail.id}
              parametersFetch={parametersFetch}
              nametarget={target.detail.kind + (target.type.kind + target.type.subkind).toLowerCase()}
            />
            : target.creator.id !== state.account.data.user.id && target.detail.status !== 'INTERRUPTED' &&
              moment(target.detail.ends).toDate() >= moment().toDate() ?
              <InfoTooltip />
              : <LabelTarget target={target} />
          }
        </div>
        <div className="d-flex align-items-end mb-1 ">
          <p className="c-black font-size-20 font-weight-bold mb-0">{target.detail.current_value}</p>
          <p className="c-medium-grey font-size-14 font-weight-regular mb-0 ms-1">/{target.detail.target_value}</p>
        </div>

        <div className="d-flex align-items-between mt-1 mb-2 justify-content-between">

          {target.type.subkind === "CONVERSION" ?
            <p className={`${target.type.kind === "PHYSICAL" ? "c-tiffany" : "c-purple"} align-self-end font-size-16 font-weight-bold mb-0 ms-0 me-3 text-uppercase`}>{t(`targetcardoverview.conversionshop${(target.type.kind).toLowerCase()}`)}</p>
            :
            <div className="d-flex align-items-end ">
              <p className="c-medium-grey font-size-13 text-uppercase font-weight-bold  mb-0">
                {t(`targetcardoverview.completion`)}
                <span className={`${target.type.kind === "PHYSICAL" ? "c-tiffany" : "c-purple"} font-size-16 font-weight-bold mb-0 ms-0 me-3`}>{Math.trunc((target.detail.current_value / target.detail.target_value) * 100)}%</span>
              </p>
            </div>
          }
          <div className="d-flex flex-row align-items-center ">
            <div className="d-flex flex-column align-items-start ">
              <p className="c-medium-grey font-size-13 font-weight-bold text-uppercase m-0 mb-0 text-nowrap">{t(`targetcardoverview.createdby`)}</p>

              {target.creator.id === state.account.data.user.id ?
                <div className="d-flex">
                  <p className={`c-black  font-weight-semibold m-0 ${dimension === "col-lg-12" ? "font-size-14" : "font-size-12"}`}>{t(`targetcardoverview.you`)}</p>
                </div> :
                <div className="d-flex">
                  <p className={`c-black  font-weight-semibold m-0 ${dimension === "col-lg-12" ? "font-size-14" : "font-size-12"}`}>{target.creator.name} {target.creator.surname}</p>
                </div>
              }

            </div>


            <div className="d-flex">
              {target.creator.id === state.account.data.user.id ?
                <span className="circleName-grad-s d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
                      m-0  font-size-11 ms-2">{target.creator.name.substring(0, 1)}{target.creator.surname.substring(0, 1)}</span>
                :
                <span className="circleName-s d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
                      m-0  font-size-11 ms-2">{target.creator.name.substring(0, 1)}{target.creator.surname.substring(0, 1)}</span>
              }


            </div>

          </div>


        </div>
        {target.type.subkind !== "CONVERSION" ?
          <ProgressBar now={(target.detail.current_value / target.detail.target_value) * 100} className={target.type.kind === "PHYSICAL" ? "progressTiffany" : "progressPurple"} />
          : null}
      </div>

    </div>
  );
}