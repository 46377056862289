import React, { Suspense } from 'react';
import './App.css';
import { AppStateProvider } from './store/AppState';
import * as Reducers from './reducers';
import { AppContainer } from './routing/AppContainer';
import moment from 'moment';

const appReducers = Reducers.combineReducers({
  tree: Reducers.treeReducer,
  account: Reducers.accountReducer,
  targets: Reducers.targetsReducer,
  date: Reducers.dateReducer,
  headersSingleshop: Reducers.headersSingleshopReducer
});


moment.updateLocale('it', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});

moment.updateLocale('fr', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});

export default function App() {
  return (
    <Suspense fallback='loading'>
      <AppStateProvider reducer={appReducers} initialState={Reducers.initialState}>
        <AppContainer />
      </AppStateProvider>
    </Suspense>
  );
}