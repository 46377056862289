import {
  treeSuccess,
  treeError,
  accountSuccess,
  accountError,
  targetsSuccess,
  targetsError
} from '../actions';
import { verifyMessage } from '../apis/models';

const urlServer = () => {
  return window['REACT_APP_URL_SERVER_' + process.env.REACT_APP_ENVIRONMENT];
};

const headers = {
  'Content-Type': 'application/json; charset=UTF-8',
};

const fetchData = async (request) => {
  return await fetch(urlServer() + request.url, request.params).then(
    (response) => response.json()
  );
};

export const getTree = async (dispatch) => {
  const method = 'GET';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url = `${window.REACT_APP_SERVICE_USERS_INTERNAL}/accounts/tree?${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  fetchData({ url, params })
    .then((data) => {
      verifyMessage(data.message);
      dispatch(treeSuccess(data))
    })
    .catch((err) => dispatch(treeError()));
};

export const getAccount = (dispatch) => {
  const method = 'GET';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url = `${window.REACT_APP_SERVICE_USERS_INTERNAL}/accounts?${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  fetchData({ url, params })
    .then((data) => {
      verifyMessage(data.message);
      dispatch(accountSuccess(data))
    })
    .catch((err) => dispatch(accountError()));
};

export const getTargets = async (dispatch, { getNew = "false", duration = "", start = "", end = "", destinationId = 0, destinationKind = "" }) => {
  const method = 'GET';
  headers['Authorization'] = 'Bearer ' + sessionStorage.getItem('sessionToken');

  const url = `${window.REACT_APP_SERVICE_USERS_INTERNAL}/targets-users?destinationId=${destinationId === 0 ? "" : destinationId.toString()}&destinationKind=${destinationKind}&duration=${duration}&start=${start}&end=${end}&getNew=${getNew}&${window.REACT_APP_SERVICE_V1}`;
  const params = { method, headers };

  fetchData({ url, params })
    .then((data) => {
      verifyMessage(data.message);
      dispatch(targetsSuccess(data))
    })
    .catch((err) => dispatch(targetsError()));
};
