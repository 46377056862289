import React from 'react';
import { PieChart } from "../../../../commons/charts/PieChart"
import IconTiffanyBigStore from '../../../../../img/IconTiffanyBigStore.png'
import IconEcommercePurple from '../../../../../img/IconEcommercePurple.png'
import { Formatters } from '../../../../commons'
import './cardpiechartoverview.css'
import { useTranslation } from 'react-i18next';

export function CardPieChartsOverview({ Id, type, physical, online, salesPhysical, salesOnline }) {
    const { t, i18n } = useTranslation();
    return (
        <div className={`cardPieOverview bg-white shadowDropMediumBlur  border-radius h-100 ${type}`}  >

            <div className="p-4 h-100  d-flex flex-wrap">
                <div className="pieChartContent col-12 col-sm-6">
                    {physical.length > 0 && online.length > 0 &&
                        <PieChart
                        type={type}
                            physical={physical}
                            online={online}
                            Id={Id}
                        />}
                </div>
                <div className="col-sm-6 col-12 d-flex flex-column justify-content-between">
                    <div>
                        <h3 className="c-black font-weight-semibold mb-0 font-size-19">{t(`cardpiechartsoverview.${type}`)} </h3>
                        <h2 className="c-black font-weight-semibold font-size-24 my-1 ">
                            {type === "transactions" ?
                                <>  <Formatters.NumberFormatter value={(physical[0]?.value ?? 0) + (online[0]?.value ?? 0)} />
                                </> :
                                <>  <Formatters.NumberFormatter value={(physical[0]?.value ?? 0) + (online[0]?.value ?? 0)} currency /> €
                                </>
                            }

                        </h2>
                        <div className="d-flex align-items-center mb-4 mt-3" >
                            <img className="img-icon40 me-2" alt="" src={IconTiffanyBigStore} />
                            <div className="d-flex flex-column" >
                                <h4 className="c-tiffany font-weight-semibold font-size-18 mb-0">{t("cardpiechartsoverview.store")}</h4>

                                <p className="c-black regular font-size-18 mb-0">
                                    {type === "transactions" ?
                                        <> <Formatters.NumberFormatter value={physical[0]?.value ?? 0}  />
                                        </> :
                                        <>  <Formatters.NumberFormatter value={physical[0]?.value ?? 0 } currency /> €
                                        </>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-4 mt-3" >
                            <img className="img-icon40 me-2" alt="" src={IconEcommercePurple} />
                            <div className="d-flex flex-column" >
                                <h4 className="c-purple font-weight-semibold font-size-18 mb-0">{t("cardpiechartsoverview.ecommerce")}</h4>
                                <p className="c-black regular font-size-18 mb-0">
                                {type === "transactions" ?
                                        <> <Formatters.NumberFormatter value={online[0]?.value ?? 0}  />
                                        </> :
                                        <> <Formatters.NumberFormatter value={online[0]?.value ?? 0 } currency /> €
                                        </>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>

                    {type === "transactions" &&
                        <h4 className="c-black  font-weight-regular font-size-18 mb-0">
                            <span className="font-weight-semibold me-2">{t("cardpiechartsoverview.tickets")}</span>
                            <Formatters.NumberFormatter
                                value={salesPhysical[0] && salesOnline[0] && salesPhysical[0].value + salesOnline[0].value > 0 ? (salesPhysical[0].value + salesOnline[0].value) / (physical[0].value + online[0].value) : 0}
                                currency/> €
                        </h4>
                    }

                </div>
            </div>


        </div >
    );
}