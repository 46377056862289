export function headersSingleshopReducer(state, action) {
    const { type, payload } = action;

    state = { ...state };
    switch (type) {
        case 'SET_HEADERS_SINGLESHOP':
            state.headersSingleshop = payload.headers;
            break;
        case 'CLEAN_HEADERS_SINGLESHOP':
            state.headersSingleshop = [];
            break;
        default:
            return state;
    }

    return state;
}