import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { loadCldr } from '@syncfusion/ej2-base';
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './datepicker.css';
import { Formatters } from '../commons';
import { L10n } from '@syncfusion/ej2-base';

loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/supplemental/weekData.json'),
    require('cldr-data/main/fr-CH/ca-gregorian.json'),
    require('cldr-data/main/fr-CH/numbers.json'),
    require('cldr-data/main/fr-CH/timeZoneNames.json'),
    require('cldr-data/main/en-CH/ca-gregorian.json'),
    require('cldr-data/main/en-CH/numbers.json'),
    require('cldr-data/main/en-CH/timeZoneNames.json'),
    require('cldr-data/main/it-CH/ca-gregorian.json'),
    require('cldr-data/main/it-CH/numbers.json'),
    require('cldr-data/main/it-CH/timeZoneNames.json')
);

export const DatePicker = ({ typeDate, setDateInfo, disabled, id, min, max, deleteDate }) => {
    const dateSettings = {
        day: {
            startDate: 'Month',
            depthDate: 'Month',
            formatDate: 'dd MMMM y',
            multiSelectionDate: false,
        },
        week: {
            startDate: 'Month',
            depthDate: 'Month',
            formatDate: 'dd MMMM y',
            multiSelectionDate: true,
        },
        month: {
            startDate: 'Year',
            depthDate: 'Year',
            formatDate: 'MMMM',
            multiSelectionDate: false,
        },
        year: {
            startDate: 'Decade',
            depthDate: 'Decade',
            formatDate: 'y',
            multiSelectionDate: false,
        },
    };

    const { t, i18n } = useTranslation();
    const [valueDate, setValueDate] = useState("");
    const [valuesDate, setValuesDate] = useState([]);
    const [startDate, setStartDate] = useState('Day');
    const [depthDate, setDepthDate] = useState('Day');
    const [formatDate, setFormatDate] = useState('dd MMMM y');


    L10n.load({
        "it-CH": {
            "datepicker": {
                "today": t(`datepicker.this${typeDate}`),
                "placeholder": 'Scegli le date',
            }
        },
        "fr-CH": {
            "datepicker": {
                "today": t(`datepicker.this${typeDate}`),
                "placeholder": 'Sélectionner la plage de temps',
            },
        },
        "en-CH": {
            "datepicker": {
                "today": t(`datepicker.this${typeDate}`),
                "placeholder": 'Select the date',
            }
        }
    })


    const [multiSelectionDate, setMultiSelectionDate] = useState(false);
    let datepicker = useRef(null);

    useEffect(() => {
        setValueDate("");
    }, [typeDate]);


    useEffect(() => {
        handleChange(valueDate);
    }, [valueDate]);

    const handleChange = (value, e) => {
        const start = moment(value).startOf(typeDate);
        const end = moment(value).endOf(typeDate);
        const days = [];
        if (typeDate === 'week') {
            let day = start;
            while (day <= end) {
                days.push(day.toDate());
                day = day.clone().add(1, 'd');
            }
            if (datepicker.current.value) {
                datepicker.current.value = `${start.date() < 10 ? `0${start.date()}` : start.date()
                    } - ${end.date() < 10 ? `0${end.date()}` : end.date()} / ${start.month() + 1 < 10 ? `0${start.month() + 1}` : start.month() + 1
                    } / ${start.year()}`;
            }
        }
        setValuesDate(days);
        setDateInfo({
            typeDate: typeDate,
            start: start.isValid() ? Formatters.FormatDateTZ(moment(start)) : "",
            end: end.isValid() ? Formatters.FormatDateTZ(moment(end)) : "",
        });
    };

    // CANCELLA LE DATE QUANDO CAMBIA IL FORM DELLA CREAZIONE OBIETTIVI
    useEffect(() => {
        if (deleteDate) {
            setValueDate("")
            setValuesDate([])
        }
    }, [deleteDate]);

    useEffect(() => {
        setStartDate(dateSettings[typeDate].startDate);
        setDepthDate(dateSettings[typeDate].depthDate);
        setFormatDate(dateSettings[typeDate].formatDate);
        setMultiSelectionDate(dateSettings[typeDate].multiSelectionDate);
    }, [typeDate]);


    useEffect(() => {

        var elInputCalendar = document.getElementById('formNewTargetcalendar');
        if (elInputCalendar) {
            elInputCalendar.disabled = true;
        }

    }, [disabled]);

    return (
        <div id='datePicker' className='datepicker-control-section col'>
            <DatePickerComponent
                cssClass={'GradDatePicker'}
                enabled={!disabled}
                change={(e) => { setValueDate(e.value ? e.value : valueDate); }}
                id={id + 'calendar'}
                value={valueDate}
                values={valuesDate}
                isMultiSelection={multiSelectionDate}
                start={startDate}
                depth={depthDate}
                format={formatDate}
                locale={i18n.language + '-CH'}
                ref={datepicker}
                min={min}
                max={max}
                firstDayOfWeek={1}
            />
        </div>
    );
};
