import React from 'react';
import { useTranslation } from 'react-i18next';
import { Category, ChartComponent, ColumnSeries, Inject, Tooltip, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import ReactDOMServer from 'react-dom/server';
import { Formatters } from '../../commons';
import moment from "moment";
import './tablinechart.css'

export function TabLineChart({ data, type, name, id, typeDate, totalData }) {
  const { t } = useTranslation();

  const formatY = () => {
    switch (name) {
      case 'PHYSICALSALES':
      case 'PHYSICALPROFIT':
      case 'ONLINEPROFIT':
      case 'ONLINESALES':
      case 'ONLINEAVERAGE_TRANSACTION':
      case 'PHYSICALAVERAGE_RECEIPT':
        return '### €'
      case 'PHYSICALCONVERSION':
      case 'ONLINECONVERSION':
        return 'p'

      default:
        return 'n'
    }
  }

  let dataLong = "";
  const kpiTrend = totalData.kpi.filter(element => element.kind + element.subkind === name)[0].percentage

  const tootltipeDate = (argsX) => {
    if (typeDate === "day") {
      data.forEach(element => {
        let time = argsX + ":00"
        dataLong = time
      })

    } else if (typeDate === "week") {

      data.forEach(element => {

        let dataWeek = moment(element.date).format("dddd-DD-MMM-YYYY")
        if (
          
          t(`formatterX.${ moment(element.date).format("ddd")}`) === argsX) {
          let dataWeekTranslate = t(`multishoptablinechart.${dataWeek.split("-")[0].toLowerCase()}`) +
            " " + dataWeek.split("-")[1] + " " + t(`multishoptablinechart.${dataWeek.split("-")[2].toLowerCase()}`)
            + " " + dataWeek.split("-")[3]

          dataLong = dataWeekTranslate
        }
      })

    } else if (typeDate === "month") {
      data.forEach(element => {
        let dataMonth = moment(element.date).format("dddd-DD-MMM-YYYY")
        if (moment(element.date).format("DD") === ('0' + argsX).slice(-2)) {
          let dataMonthTranslate = 
          t(`multishoptablinechart.${dataMonth.split("-")[0].toLowerCase()}`) +
          " " + dataMonth.split("-")[1] + " " + t(`multishoptablinechart.${dataMonth.split("-")[2].toLowerCase()}`)
          + " " + dataMonth.split("-")[3]
          dataLong = dataMonthTranslate
        }
      })
    } else if (typeDate === "year") {
      data.forEach(element => {
        let dataYear = moment(element.date).format("MMMM")
        if (
          t(`formatterX.${moment(element.date).format("MMM")}`) === argsX) {
          let dataMonthTranslate = t(`multishoptablinechart.long${dataYear.toLowerCase()}`)
          dataLong = dataMonthTranslate
        }
      })
    }
  }



  const linechartTooltipTemplate = (args) => {

    tootltipeDate(args.x)

    
    const tooltipTemplate = ReactDOMServer.renderToStaticMarkup(
    <div id={"linechartTooltip" + id} className={`linechartTooltipTemplate d-flex flex-column p-2 border-radius ${type === "PHYSICAL" ? "bg-tiffany" : "bg-purple"}`}>


        <p className=" font-weight-semibold font-size-12 ms-2 mb-0 text-end"><span className="c-white">
          {dataLong}
        </span></p>

        <div>
          <p className="c-white font-weight-bold  font-size-13 ms-2 mb-0 text-end" >
         {args.y}   
         
         {/* <Formatters.NumberFormatter value={Number(args.y)} currency={name === 'PHYSICALSALES' || name === 'PHYSICALPROFIT' || name === 'ONLINEPROFIT' || name === 'ONLINESALES' || name === 'PHYSICALPROFIT'} />
            <Formatters.FormatCurrency typeKpi={name} /> */}
          </p>
        </div>
        <div className="d-flex align-self-end ">
          <h4 className="c-white-op80 font-size-13 font-weight-semibold mb-0 text-end">
            {kpiTrend > 0 ? "+" : null}<Formatters.NumberFormatter value={parseInt(kpiTrend)} /> %
          </h4>
          <span className={`ms-1 ${kpiTrend > 0 ? "icon-trendUp" : kpiTrend < 0 ? "icon-trendDown" : "icon-trendDraw"}`} />
        </div>
      </div>
    );


     document.querySelector("#TabLineChartMultiShop_tooltip div#TabLineChartMultiShop_tooltipparent_template div").innerHTML = tooltipTemplate
    return (
      <></>

      // <div id={"linechartTooltip" + id} className={`linechartTooltipTemplate d-flex flex-column p-2 border-radius ${type === "PHYSICAL" ? "bg-tiffany" : "bg-purple"}`}>


      //   <p className=" font-weight-semibold font-size-12 ms-2 mb-0 text-end"><span className="c-white">
      //     {dataLong}
      //   </span></p>

      //   <div>
      //     <p className="c-white font-weight-bold  font-size-13 ms-2 mb-0 text-end" >
      //       <Formatters.NumberFormatter value={Number(args.y)} currency={name === 'PHYSICALSALES' || name === 'PHYSICALPROFIT' || name === 'ONLINEPROFIT' || name === 'ONLINESALES' || name === 'PHYSICALPROFIT'} />
      //       <Formatters.FormatCurrency typeKpi={name} />
      //     </p>
      //   </div>
      //   <div className="d-flex align-self-end ">
      //     <h4 className="c-white-op80 font-size-13 font-weight-semibold mb-0 text-end">
      //       {kpiTrend > 0 ? "+" : null}<Formatters.NumberFormatter value={parseInt(kpiTrend)} /> %
      //     </h4>
      //     <span className={`ms-1 ${kpiTrend > 0 ? "icon-trendUp" : kpiTrend < 0 ? "icon-trendDown" : "icon-trendDraw"}`} />
      //   </div>
      // </div>
    );
  }

  return (
    <div>
      <h3 className="c-black font-size-18 font-weight-semibold mb-0">{t(`multishoptablinechart.${name.toLowerCase()}`)}</h3>
      <p className="c-black font-size-14 mb-2">{t(`multishoptablinechart.description${name.toLowerCase()}`)}</p>
      <div id={'lineChartCard' + id}
        className='p-2 border-radius bg-white '>
        <div className='ms-3 me-4 mt-3'>
          <ChartComponent
            height="350px"
            id='TabLineChartMultiShop'
            className="overflowChart"
            primaryXAxis={{
              valueType: 'Category',
              interval: 1,
              majorGridLines: { width: 0 },
              labelIntersectAction: 'MultipleRows',
              labelPlacement: 'OnTicks'
            }}
            primaryYAxis={
              { labelFormat: formatY() }
            }
            tooltip={{
              enable: true,
              template: linechartTooltipTemplate
            }}
            chartArea={{ border: { width: 0 } }}
          >
            <Inject services={[ColumnSeries, LineSeries, Tooltip, Category]} />
            <SeriesCollectionDirective>
              <SeriesDirective
                fill={type === "PHYSICAL" ? "var(--tiffany)" : "var(--purple)"}
                dataSource={Formatters.FormatDateChart(data, typeDate, 'date', 'value')}
                xName='date'
                yName="value"
                name='Sales'
                width={4}
                type='Line'
                marker={{ visible: false }}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        </div>
      </div>
    </div>
  );
}