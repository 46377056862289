import React, { useEffect } from 'react';
import { Navbars } from '../../components/commons';
import { accountRequest, treeSelected, treeRequest, treeBreadcrumb } from './../../actions';
import { useAppState } from '../../store/AppState';
import { zEshow } from '../../middleware/zE';

export const Private = ({ children }) => {
  const [state, dispatch] = useAppState();
  const treeFull = sessionStorage.getItem('treFull');
  const itemSelected = sessionStorage.getItem('multishopItemSelected');

  useEffect(() => {
    zEshow();
  }, [])

  useEffect(() => {
    dispatch(treeRequest(dispatch));
    dispatch(accountRequest(dispatch));
  }, []);

  useEffect(() => {
    !state.tree.loading &&
      dispatch(treeBreadcrumb({ full: state.tree.full, selected: state.tree.selected }));
  }, [state.tree.loading]);

  useEffect(() => {
    if (!state.tree.loading) {
      if (treeFull === JSON.stringify(state.tree.full) && itemSelected) {
        dispatch(treeSelected({ dispatch, data: JSON.parse(itemSelected), full: state.tree.full }));
      } else {
        dispatch(treeSelected({ dispatch, data: state.tree.full, full: state.tree.full }));
        sessionStorage.setItem('multishopItemSelected', JSON.stringify(state.tree.full));
        sessionStorage.setItem('treFull', JSON.stringify(state.tree.full));
      }
    }
  }, [state.tree.full]);

  return !state.account.loading ? (
    state.account.data && (
      <div>
        <Navbars.NavbarMain />
        {children}
      </div>
    ) /* : <Redirect to='/login' /> */
  ) : <div></div>;
};