import React from 'react';
import { Formatters } from '../../../../commons'
import { useTranslation } from 'react-i18next';
import './cardyourstore.css'

export function CardYourStore({ profitPhysical, affluencePhysical, transactionsPhysical }) {
    const { t, i18n } = useTranslation();
    return (
        <div id="CardYourStore" className=" h-100" >

            <div className="bgCardYourStore bg-white shadowDropMediumBlur border-radius h-100 d-flex flex-column flex-wrap p-4">

                <h3 className="c-black font-weight-semibold mb-0 font-size-22 mt-1">
                {t("cardyourstore.inyour")}<span className="c-tiffany font-weight-semibold font-size-22 ms-2">{t("cardyourstore.physicalstore")}</span>
                </h3>

                <div className="d-flex flex-column align-items-start mb-2 mt-3" >
                    <h4 className="c-tiffany font-weight-bold font-size-14 mb-0 text-uppercase">{t("cardyourstore.profitforclient")}</h4>
                    <p className="c-black font-weight-semibold font-size-25 mb-0">
                        <Formatters.NumberFormatter value={profitPhysical[0].value} currency /> €
                    </p>
                </div>

                <div className="d-flex align-items-start mt-3" >
                    <div className="d-flex flex-column m-0" >
                        <h4 className="c-tiffany font-weight-bold font-size-14 mb-0 text-uppercase">{t("cardyourstore.tickets")}</h4>
                        <p className="c-black font-weight-semibold font-size-25 mb-0">
                            <Formatters.NumberFormatter value={transactionsPhysical[0].value} />
                        </p>
                    </div>
                    <span className="borderGrey ms-4 me-4"></span>
                    <div className="d-flex flex-column m-0" >
                        <h4 className="c-tiffany font-weight-bold font-size-14 mb-0 text-uppercase">{t("cardyourstore.presence")}</h4>
                        <p className="c-black font-weight-semibold font-size-25 mb-0">
                            <Formatters.NumberFormatter value={affluencePhysical[0].value} />
                        </p>
                    </div>
                </div>
                <div className="d-flex flex-column   mt-3" >

                    <p className="c-tiffany font-weight-bold font-size-16 mb-0 ">
                          
                    {transactionsPhysical[0].value!==0 && affluencePhysical[0].value!==0  ?
                           <>  <Formatters.NumberFormatter value={transactionsPhysical[0].value / affluencePhysical[0].value*100} /> % </>
                        :"-"
                        }
                        <span className="c-black font-weight-regular ms-2 mb-0">{t("cardyourstore.peopledescription")}</span>
                    </p>
                </div>

            </div>

        </div>
    );
}