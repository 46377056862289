import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import IconGradForgot from "../../img/IconGradForgot.png"
import { Alerts, Inputs } from "../commons";
import { useTranslation } from 'react-i18next';
import { useForgotPassword } from '../../apis/fetch';
import { verifyResponse } from '../../apis/response';

export function ForgotPasswordContent() {
    const { t } = useTranslation();
    const gotoPage = useHistory();
    const forgotPasswordResponse = useForgotPassword();

    const [enableButton, setEnableButton] = useState(true);
    const [email, setEmail] = useState("");
    const [infoAlert, setInfoAlert] = useState({ type: "", title: "alert.titlewrongcredentials", message: "alert.descriptionwrongcredentials" });
    const [showAlert, setShowAlert] = useState("hidden");

    useEffect(() => {
        if (forgotPasswordResponse.content.isLoading === "FETCHED") {
            if (verifyResponse(forgotPasswordResponse, 200)) {
                setShowAlert("hidden");
                gotoPage.push("/newpassword", { email: email });
            } else {
                setInfoAlert({ type: "error", title: "alert.titlewrongcredentials", message: "alert.descriptionwrongcredentials" });
                setShowAlert("visible");
            }
            forgotPasswordResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [forgotPasswordResponse])

    useEffect(() => {
        if (email.length >= 1) {
            setEnableButton(false);
        } else setEnableButton(true);
    }, [email])

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            forgotPasswordResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email }) })
        }
    }
    return (
        <div id="ForgotPasswordContent" className="border-radius bg-white px-5 py-4 min-height-520 shadowDropMediumBlur h-100">
            <div className="px-md-5 px-2 h-100 d-flex h-100 flex-column text-center justify-content-between align-items-center ">
                <div className="my-0 align-self-center w-100">
                    <img alt="" className="img-icon90 m-0 " src={IconGradForgot} />
                    <h4 className="c-dark-grey font-size-24 font-weight-bold  my-2 align-content-center" >{t("forgotpassword.title")}</h4>
                    <div className="px-1 mx-3">
                    <Inputs.InputText
                        onChange={setEmail}
                        placeholder={t("forgotpassword.placeholdermail")}
                        handleKeyPress={handleKeyPress}
                    />
                   
                    <Alerts.Alert info={infoAlert} show={showAlert} />
                     </div>
                    <ButtonComponent
                        cssClass="gradientButton mb-1 mt-2 font-weight-semibold"
                        disabled={enableButton}
                        onClick={() => forgotPasswordResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email }) })}
                    >
                        {t("forgotpassword.button")}
                    </ButtonComponent>
                </div>
                <h4 className="c-dark-grey text-center font-size-14 font-weight-regular my-0" >{t("forgotpassword.else")} <div onClick={() => gotoPage.push("/login")} className="c-purple font-size-14 font-weight-semibold pointer ms-2 text-decoration-none">{t("forgotpassword.backtologin")}</div></h4>
            </div>
        </div>
    );
}
