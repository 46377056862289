import React, { useEffect } from 'react';
import { useHistory } from "react-router";
import IconGradMail from "../../img/IconGradMail.png"
import { useTranslation } from 'react-i18next';
import { useForgotPassword } from '../../apis/fetch';


export function MailNewPasswordContent() {
    const gotoPage = useHistory();
    const { t } = useTranslation();
    const forgotPasswordResponse = useForgotPassword();

    useEffect(() => {
        if (forgotPasswordResponse.content.isLoading === "FETCHED") {
            forgotPasswordResponse.sendRequest({loading: "NOT FETCHING"});
        }
    }, [forgotPasswordResponse])
    
    return (
        <div id="MailNewPasswordContent" className="border-radius bg-white px-5 py-4 min-height-520 shadowDrop h-100">
            <div className="d-flex  flex-column flex-wrap align-content-center justify-content-center my-2  h-100 d-flex h-100 flex-column text-center justify-content-between align-items-center ">
                <div className="mt-3 mb-4 text-center">
                    <img alt="" className="img-icon80 m-0 " src={IconGradMail} />
                    <h4 className="c-dark-grey text-center font-size-21 font-weight-bold  mt-1 mb-2">{t("mailnewpassword.title")}</h4>
                    <p className="c-medium-grey text-center font-size-18 font-weight-regular mx-2  mt-1 mb-2">{t("mailnewpassword.descriptionone")}<br/>{t("mailnewpassword.descriptiontwo")}</p>
                </div>
                <h4 className="c-dark-grey text-center font-size-14 font-weight-regular mb-3 mt-5" >{t("mailnewpassword.emailnotrecived")}<a  onClick={() => forgotPasswordResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email: gotoPage.location.state.email }) })} className="c-purple font-size-14 font-weight-semibold pointer ms-2">{t("mailnewpassword.resubmit")}</a></h4>
            </div>
        </div>
    );
}
