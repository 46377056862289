import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useTranslation } from 'react-i18next';
import { useConfirmEmail } from '../../apis/fetch';


export function MailChangedContent() {
    const gotoPage = useHistory();
    const { t } = useTranslation();
    const confirmEmailResponse = useConfirmEmail();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!loading) {
            setLoading(true);
            const params = (new URL(document.location)).searchParams;
            sessionStorage.setItem("temporaryToken", params.get('hashcode'));
            confirmEmailResponse.sendRequest({loading: "FETCHING"});
        }
    }, [loading, confirmEmailResponse])

    useEffect(() => {
        if(confirmEmailResponse.content.isLoading === "FETCHED") {
            confirmEmailResponse.sendRequest({loading: "NOT FETCHING"});
        }
    }, [confirmEmailResponse])

    return (
        <div id="mailChangedContent">
            <div className="row justify-content-center">
            <div   className="bgMailChanged">
                </div>
            
                <div className="text-center border-radius pt-5 col-md-6 col-xl-4">
                    <h4 className="c-black  font-size-46 font-weight-bold w-100 mt-5 mb-2">{t("mailchanged.title")}</h4>
                    <ButtonComponent
                        cssClass="gradientButton mb-1 mt-5" 
                        onClick={() => gotoPage.push("/")}
                    >
                        {t("mailchanged.button")}
                    </ButtonComponent>
                </div>
            </div> 
        </div>
    );
}
