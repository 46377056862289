import { useTranslation } from "react-i18next";
import flagFr from "../../../img/fr.png";
import flagIta from "../../../img/ita.png";
import flagEng from "../../../img/eng.png";
import "./languageselector.css";
import Dropdown from 'react-bootstrap/Dropdown'
import { useUpdateAccount } from "../../../apis/fetch";
import React, { useEffect, useState } from "react";
import i18next from "i18next";

export const LanguageSelector = () => {
    const [langActive, setLangActive] = useState("");
    const updateAccountResponse = useUpdateAccount();

    const { t, i18n } = useTranslation();

    const languages = [
        { name: "fr", flag: flagFr },
        { name: "it", flag: flagIta },
         {name: "en", flag: flagEng}
    ];

    useEffect(() => {
        if (i18next.language) {
            changeLang(i18n.language.split("-")[0])
        }
    }, [])

    const changeLang = (lang) => {
        console.log(lang)
        i18n.changeLanguage(lang);
        if(lang==="fr"||lang==="it"||lang==="en"){
            setLangActive(lang)
            }
            else{setLangActive("fr")}
    }

    const handleClick = (lang) => {
        changeLang(lang);

        updateAccountResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ language: lang }) });
    }

    useEffect(() => {
        if (updateAccountResponse.content.isLoading === "FETCHED") {
            updateAccountResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [updateAccountResponse])

    const selectActiveFlag = (langActive) => {
        switch (langActive) {
            case "it":
                return flagIta;
            case "fr":
                return flagFr;
             case "en":
              return flagEng;
            default:
                return flagFr;
        }
    }

    return (
        <Dropdown id="languageSelector">
            {languages.map((language, key) => {
                return langActive === language.name &&
                    <Dropdown.Toggle variant=""
                        className="buttonactive"
                        id={language.name}
                        key={key}
                    >
                        <img alt="" className="img-icon40" src={language.flag} />
                    </Dropdown.Toggle>

            })}

            <Dropdown.Menu>
                {languages.map((language, key) => {
                    return (
                        <Dropdown.Item key={key}
                            id={language.name}
                            onClick={() => handleClick(language.name)}
                            className="d-flex flagDropped flex-column"
                        >
                            <img alt="" className="img-icon40 " src={language.flag} />
                            <hr />
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown >
    );
}