import moment from 'moment';
import React from 'react';
import IconWhiteTarget from "../../../../img/IconWhiteTarget.png"
import { useTranslation } from 'react-i18next';
import "./labeltarget.css"

export const LabelTarget = ({ target }) => {
    const { t } = useTranslation();
    return (
        <div id="labelTarget" >
            {(
                /* moment(target.detail.starts).toDate() <= moment().toDate() &&
                moment().toDate() <= moment(target.detail.ends).toDate() &&
                target.detail.current_value < target.detail.target_value && */target.detail.status === "INTERRUPTED"
            ) ?
                <div className="labelTarget d-flex bg-medium-grey" >
                    <img src={IconWhiteTarget} alt="" className=" img-icon25 align-self-center" />
                    <p className="c-white mb-0 font-size-14 text-uppercase font-weight-bold align-self-center">{t('labeltarget.interrupted')}</p>
                </div>
                : (
                    moment(target.detail.starts).toDate() <= moment().toDate() &&
                    moment().toDate() <= moment(target.detail.ends).toDate() &&
                    target.detail.current_value < target.detail.target_value
                ) ?
                    <div className="labelTarget d-flex bg-orange" >
                        <img src={IconWhiteTarget} alt="" className=" img-icon25 align-self-center" />
                        <p className="c-white mb-0 font-size-14 text-uppercase font-weight-bold align-self-center">{t('labeltarget.inprogress')}</p>
                    </div> :
                    target.detail.current_value < target.detail.target_value &&
                        moment().toDate() > moment(target.detail.ends).toDate() ?
                        <div className="labelTarget d-flex bg-red " >
                            <img src={IconWhiteTarget} alt="" className=" img-icon25 align-self-center" />
                            <p className="c-white mb-0 font-size-14 text-uppercase font-weight-bold align-self-center">{t('labeltarget.notcomplete')}</p>
                        </div> :
                        target.detail.current_value >= target.detail.target_value ?
                            <div className="labelTarget d-flex bg-green" >
                                <img src={IconWhiteTarget} alt="" className=" img-icon25 align-self-center" />
                                <p className="c-white mb-0 font-size-14 text-uppercase font-weight-bold align-self-center">{t('labeltarget.complete')}</p>
                            </div> :
                            null}
        </div>
    );
}
