import { Category, ChartComponent, ColumnSeries, AxesDirective, AxisDirective, Tooltip, DataLabel, Inject, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import React, { useState, useEffect } from 'react';
import "./comparisonlinechart.css"
import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Formatters } from '..';


export function ComparisonLineChart({ id, data, typeDate }) {

  const { t, i18n } = useTranslation();

  const [dataLineChart, setDataLineChart] = useState([]);




  // TOOLTIP
  const tooltipRender = (args) => {
    args.headerText = t(`comparisonlinechart.${args.data.seriesName}`);
    args.text = args.point.y + "";
    args.textStyle.color = '#fff';
  }


  const tooltip = {
    enable: true,
    fill: 'var(--medium-grey)'
  }




  useEffect(() => {
    let onlineUniquePeople = data.online[0].kpi.filter(kpi => kpi.kind + kpi.subkind === "ONLINEUNIQUE_PEOPLE")[0].history
    let physicalAffluence = data.store.filter(kpi => kpi.kind + kpi.subkind === "PHYSICALAFFLUENCE")[0].history

    let dataComparison = []

    onlineUniquePeople.forEach(online => {
      physicalAffluence.forEach(store => {
        if (online.date === store.date) {
          dataComparison.push({ date: moment(online.date), valuestore: store.value, valueonline: online.value })
        }
      });

    });

    setDataLineChart(dataComparison)

  }, []);



  return (
    <div className='border-radius bg-white shadowDropMediumBlur px-2' >

      <div className='mx-md-4  py-5'>
        <ChartComponent
          tooltip={tooltip}
          tooltipRender={tooltipRender}
          // tooltip={{
          //   enable: true, header: '${point.x}',
          //   format: '${point.y}'
          // } }
          id={"ComparisonLineChart" }
          className="overflowChart ComparisonLineChart"
          labelPlacement={"Auto"}
          primaryXAxis={{
            valueType: 'Category',
            interval: 1,
            majorGridLines: { width: 0 },
            labelPlacement: 'OnTicks'
          }}
          chartArea={{ border: { width: 0 } }}
        >
          <Inject services={[LineSeries, Tooltip, DataLabel, Category]} />
          <AxesDirective>
            <AxisDirective
              majorGridLines={{ width: 0 }}
              // rowIndex={0}
              minimum={0}
              // maximum={maxY(data)}
              // interval={maxY(data) / 6}
              opposedPosition={false}
              lineStyle={{ width: 0 }}
              majorTickLines={{ width: 0 }}
              name='yAxis0' >
            </AxisDirective>
            <AxisDirective
              // majorGridLines={{ width: 0 }}
              // rowIndex={0}
              minimum={0}
              // maximum={maxY2(data)}
              // interval={maxY2(data) / 6}
              opposedPosition={true}
              lineStyle={{ width: 0 }}
              majorTickLines={{ width: 0 }}
              name='yAxis1' >
            </AxisDirective>
          </AxesDirective>
          <SeriesCollectionDirective  >
            <SeriesDirective
              name="store"
              tooltipMappingName="OnlineTooltip"
              yAxisName='yAxis0'
              dataSource={Formatters.FormatDateChart(dataLineChart, typeDate, 'date', 'valuestore')}
              xName='date'
              yName='valuestore'
              width={4}
              type='Line'
              fill="#43CEDA"
              marker={{ visible: false }}
            >
            </SeriesDirective>

            {/* <SeriesDirective dataSource={data2} xName='x' yName='y' name='Japan' type='Line' marker={{
            visible: true, width: 10, height: 10, border: { width: 2, color: '#F8AB1D' }
        }} yAxisName='yAxis1' width={2}> */}

            <SeriesDirective
              name="online"
               dataSource={Formatters.FormatDateChart(dataLineChart, typeDate, 'date', 'valueonline')}
            //  dataSource={dataLineChart}
              xName='date'
              yName='valueonline'
              width={4}
              type='Line'
              fill={"#A05CFD"}
              marker={{ visible: false }}
              yAxisName='yAxis1'>
            </SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
        <div className="d-flex justify-content-start mt-3 ms-5 flex-wrap">
          <h3 className="c-dark-grey font-size-16 font-weight-regular d-flex align-items-center mx-4" >
            <span className="legendComparisonStore mx-2"></span>{t("comparisonlinechart.store")}</h3>
          <h3 className="c-dark-grey font-size-16 font-weight-regular d-flex align-items-center mx-4" >
            <span className="legendComparisonOnline mx-2"></span>{t("comparisonlinechart.online")}</h3>
        </div>

      </div>
    </div>

  );
}





