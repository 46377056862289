import React, { useState, useEffect, useCallback } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Alerts } from "../commons";
//Style css
import "./info.css"
import { useTranslation } from 'react-i18next';
import { useAccountCheck, useChangeEmail, useGetAccount, useUpdateAccount } from '../../apis/fetch';
import { Inputs } from '../commons';
import { verifyResponse } from '../../apis/response';
// fareTraduzioni

export function Info({ setIsBlockingInfo }) {
    const { t, i18n } = useTranslation();

    const [enableButton, setEnableButton] = useState(true);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    // const [language, setLanguage] = useState("");
    const [currency, setCurrency] = useState("");

    const [user, setUser] = useState({});
    const [isChangeEmail, setIsChangeEmail] = useState(false);

    const [infoAlert, setInfoAlert] = useState({ type: "", title: "", message: "" });
    const [showAlert, setShowAlert] = useState("hidden");

    // const currencies = [
    //     {name: "Eur €", value: "euro"},
    //     {name: "Dollar $", value: "dollar"}
    // ]

    const getAccountResponse = useGetAccount();
    const updateAccountResponse = useUpdateAccount();
    const accountCheckResponse = useAccountCheck({ username: "", email: email });
    const changeEmailResponse = useChangeEmail();

    useEffect(() => {
        getAccountResponse.sendRequest({ loading: "FETCHING" });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (getAccountResponse.content.isLoading === "FETCHED") {
            if (verifyResponse(getAccountResponse, 200)) {
                setUser(getAccountResponse.content.response.data.user);
                setName(getAccountResponse.content.response.data.user.name);
                setSurname(getAccountResponse.content.response.data.user.surname);
                // setLanguage(getAccountResponse.content.response.data.user.language);
                setCurrency(getAccountResponse.content.response.data.user.currency);
                if (!isChangeEmail) {
                    setEmail(getAccountResponse.content.response.data.user.email);
                }
            } else {
                setInfoAlert({ type: "error", title: "alert.titlewrongcurrentpassword", message: "alert.descriptionpasswordsdifferents", messageCustom: null });
                setShowAlert("visibile");
            }
            getAccountResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [getAccountResponse, isChangeEmail, t])

    useEffect(() => {
        if (accountCheckResponse.content.isLoading === "FETCHED") {
            if (verifyResponse(accountCheckResponse, 200)) {
                setShowAlert("hidden");
                setIsChangeEmail(true);
                updateAccountResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ name: name, surname: surname, currency: currency }) });
                changeEmailResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email: user.email, new_email: email }) });
            } else {
                setInfoAlert({ type: "error", title: "alert.titlewrongcredentials", message: "alert.existinguser", messageCustom: null });
                setShowAlert("visible");
            }
            accountCheckResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [accountCheckResponse, updateAccountResponse, changeEmailResponse, name, surname, email, currency, user.email, t])

    useEffect(() => {
        if (updateAccountResponse.content.isLoading === "FETCHED") {
            if (verifyResponse(updateAccountResponse, 200) && !isChangeEmail) {
                setInfoAlert({ type: "success", title: "alert.savesuccesstitle", message: "alert.savesuccessdescription", messageCustom: null});
                setShowAlert("visible");
                getAccountResponse.sendRequest({ loading: "FETCHING" });
                setIsBlockingInfo(false);
            }
            updateAccountResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [updateAccountResponse, isChangeEmail, getAccountResponse, setIsBlockingInfo, t])

    useEffect(() => {
        if (changeEmailResponse.content.isLoading === "FETCHED") {
            if (verifyResponse(changeEmailResponse, 200)) {
                setInfoAlert({ type: "warning", title: "alert.warningnewemailtitle", message: null, messageCustom: () => changeEmailResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email: user.email, new_email: email }) }) });
                setShowAlert("visible");
                getAccountResponse.sendRequest({ loading: "FETCHING" });
                setIsBlockingInfo(false);
            }
            changeEmailResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [changeEmailResponse, getAccountResponse, setIsBlockingInfo, t])

    useEffect(() => {
        if ((user.name !== name && name !== "") ||
            (user.surname !== surname && surname !== "") ||
            (user.email !== email && email !== "") ||
            // (user.language !== language && language !== "") ||
            (user.currency !== currency && currency !== "")) {
            setEnableButton(false);
            setIsBlockingInfo(true);
        } else {
            setEnableButton(true);
            setIsBlockingInfo(false);
        }
    }, [user, name, surname, email,
        //  language, 
        currency, setIsBlockingInfo])

    const saveUser = () => {
        if (user.email !== email) {
            accountCheckResponse.sendRequest({ loading: "FETCHING" });
        } else updateAccountResponse.sendRequest({
            loading: "FETCHING", body: JSON.stringify({
                name: name, surname: surname,
                //  language: language,
                currency: currency
            })
        });
    }

    const onChangeEmail = (value) => {
        setEmail(value);
        if (infoAlert.type === "warning") {
            setShowAlert("hidden");
        }
    }

    return (
        <div id="personalInfo" className="h-100">
            <div className="containerPersonalInfo h-100 d-flex flex-column justify-content-between m-auto px-4 px-md-5">
                <h2 className="c-black font-size-21 font-weight-bold  mb-4 ">{t("userarea.personalinfo")}</h2>
                <div className="d-flex flex-column h-100 justify-content-between borderbottom-max-md pb-xl-0 pb-4">
                    <div className="mt-1 mb-3 align-self-center w-100 ">
                        <h4 className="c-dark-grey font-size-14 font-weight-bold mb-1 text-uppercase">{t("userarea.name")}</h4>
                        <Inputs.InputText placeholder={t("userarea.name")} value={name} onChange={setName} />
                        <h4 className="c-dark-grey font-size-14 font-weight-bold mb-1 text-uppercase">{t("userarea.surname")}</h4>
                        <Inputs.InputText placeholder={t("userarea.surname")} value={surname} onChange={setSurname} />
                        <h4 className="c-dark-grey font-size-14 font-weight-bold mb-1 text-uppercase">{t("userarea.mail")}</h4>
                        <Inputs.InputText placeholder={t("userarea.mailplaceholder")} value={email} onChange={onChangeEmail} />
                        {/* <h4 className="c-dark-grey font-size-14 font-weight-bold mb-1 text-uppercase">{t("userarea.currency")}</h4>
                                <Inputs.Select listOptions={currencies} value={currency} onChange={setCurrency} /> */}
                        {/* <div className="d-flex">
                            <div className="mb-3 me-3 align-self-start w-50">
                                <h4 className="c-dark-grey font-size-14 font-weight-bold mb-1 text-uppercase">{t("userarea.language")}</h4>
                                <Select options={languages} value={language} onChange={setLanguage} />
                            </div>
                            <div className="mb-3 align-self-start w-50">
                                <h4 className="c-dark-grey font-size-14 font-weight-bold mb-1 text-uppercase">{t("userarea.currency")}</h4>
                                <Select options={currencies} value={currency} onChange={setCurrency} />
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-auto">
                        <Alerts.Alert info={infoAlert} show={showAlert} />
                    </div>
                    <ButtonComponent cssClass="gradientButton mb-1 mt-5 w-100"
                        disabled={enableButton}
                        onClick={() => saveUser()}
                    >
                        {t("userarea.button")}
                    </ButtonComponent>
                </div>
            </div>
        </div>
    );
}