import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardRealTime } from '../commons/CardRealTime';
import { CardMySite } from './CardMySite';
import { OnlineTabContainer } from './OnlineTabContainer';

export function OnlineShop({ data, parametersFetch }) {
    const { t } = useTranslation();
    const [realTimeOnline, setRealTimeOnline] = useState([]);
    const cardMyCashDeskShow = data.online.length > 1;
    const cardRealTimeShow = data.store.filter(elem => elem.kind === "ONLINE" && elem.subkind === "CARTS").length > 0

    useEffect(() => {
        const realTime = []
        if (cardRealTimeShow) {
            data.online.forEach(element => {
                const value = element.kpi.filter(elem => elem.kind === "ONLINE" && elem.subkind === "CARTS")[0].value
                realTime.push({ name: element.name, value: value })
            })
            setRealTimeOnline(realTime)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h3 className="c-dark-grey font-size-15 ms-1 mt-3">{t("onlinetabcontainer.nb")}</h3>
            <OnlineTabContainer data={data} parametersFetch={parametersFetch} />
            <div className="row g-0 mt-3">
                {cardMyCashDeskShow && (
                    <div className={`${realTimeOnline.length > 0 ? "col-xl-7 pe-xl-3" : "col-12"}`} >
                        <div className="mb-4">
                            <h3 className="c-black font-weight-semibold font-size-20 ms-1 mt-2 mb-1">
                                {t("onlinetabcontainer.myplatforms")}
                            </h3>
                            <CardMySite type="online" data={data} />
                        </div>
                    </div>)
                }
                <div className="col-xl">
                    {realTimeOnline.length > 0 &&
                        <div className='mb-4'>
                            <h3 className="c-black font-weight-semibold font-size-20 ms-1 mt-2 mb-1">
                                {t("onlinetabcontainer.myactivecarts")}
                            </h3>
                            <CardRealTime type="online"
                                data={data}
                                dataRealTime={realTimeOnline}
                                kpiRealtime={data.store.filter(elem => elem.kind === "ONLINE" && elem.subkind === "CARTS")}
                            />
                        </div>
                    }
                </div>
            </div>

        </div>
    );
}