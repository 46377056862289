import React, { useState, useEffect } from 'react';
import Table from './Table'
import { Navbars, Sidebars, Footers } from '../commons'
import { useGetAccountInvoices } from '../../apis/fetch';
import { verifyResponse } from '../../apis/response';
// fareTraduzioni
export function Billing() {

    const [loaded, setLoaded] = useState(false);

    const getAccountInvoicesResponse = useGetAccountInvoices();
    const [dataBilling, setDataBilling] = useState({});
    useEffect(() => {
        if (!loaded) {
            setLoaded(true);
            getAccountInvoicesResponse.sendRequest({ loading: "FETCHING" });
        }
    }, [loaded, getAccountInvoicesResponse])


    useEffect(() => {
        if(getAccountInvoicesResponse.content.isLoading === "FETCHED") {
            if(verifyResponse(getAccountInvoicesResponse, 200)) {
                setDataBilling(getAccountInvoicesResponse.content.response.data)
            }
            getAccountInvoicesResponse.sendRequest({loading: "NOT FETCHING"});
        }
    }, [getAccountInvoicesResponse])


    return (
        <>
            <div id="ReportArea">
                <Navbars.NavbarBackPage/>
                <div className="container-fluid container-xl ">
                    <div className="row">
                        <div className="col-md-4 col-xl-3 pt-3 py-md-3 stickySideBar">
                            <Sidebars.SidebarUser active="billing" title="Fatturazione" />
                        </div>
                        <div className="col-md-8 col-xl-9">
                            <Table dataBilling={dataBilling} />
                        </div>
                    </div>
                    <Footers.FooterPoweredBy />
                </div>
            </div>
        </>
    );
}