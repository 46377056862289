import React from 'react';

import { useTranslation } from 'react-i18next';
import BgLoading from '../../../../img/BgLoading.png'
import './loading.css'

export function Loading() {
    const { t, i18n } = useTranslation();

    return (
        <div id="Loading" className="flex-grow-1">
            <div className="d-flex flex-column  justify-content-center align-items-center pt-5" >
                <img src={BgLoading} alt="Loading"  className="imgLoading align-self-center" />
                <h3 className="c-black font-size-20 font-weight-bold align-self-center " >{t("loading.text")}</h3>
            </div>
        </div>
    );
}