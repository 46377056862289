import moment from "moment";
import { useTranslation } from 'react-i18next';
import { Formatters } from "..";
import { useAppState } from '../.././../store/AppState'

export function NumberFormatter({ value, currency }) {
    return value.toLocaleString(undefined, { maximumFractionDigits: currency ? 2 : 0, minimumFractionDigits: currency ? 2 : 0 });
}

export function FormatDateChart(data, type, dateField, valueField) {
    const { t } = useTranslation();
    const [{ date }] = useAppState();
    let newData = [];

    let isRealtimeToday = false;
    const startRealtimeToday = Formatters.FormatDateTZ(moment(moment().startOf("day")));
    const endRealtimeToday = Formatters.FormatDateTZ(moment(moment().endOf("day")));

    if (date.typeDate === 'day' && startRealtimeToday && date.end === endRealtimeToday) {
        isRealtimeToday = true;
    }

    if (type === "day") {
        type = "HH";
    } else if (type === "week") {
        type = "ddd";
    } else if (type === "month") {
        type = "D";
    } else if (type === "year") {
        type = "MMM";
    }



    data.forEach((row, index) => {
        const newRow = {};

        if (index === data.length - 1 && isRealtimeToday) {
            return;
        }

        newRow[dateField] = t(`formatterX.${moment(row[dateField]).local().format(type)}`);
        newRow[valueField] = row[valueField];
        newData.push(newRow);
    })
    return newData;
}

export function FormatDateTZ(date) {
    return moment.utc(date).format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]");
}

export function FormatDateLocale(date) {
    return moment(date).format("DD/MM/YYYY");
}

export function FormatDate(date) {
    return moment(date).format("YYYY-MM-DD");
}

export function FormatDateYear(date) {
    return moment(date).format("YYYY");
}

export function FormatDateMonth(date) {
    return moment(date).format("M");
}



export function FormatCurrency(typeKpi) {
    typeKpi = typeKpi.typeKpi

    if (typeKpi === 'PHYSICALSALES' || typeKpi === 'PHYSICALPROFIT' || typeKpi === 'ONLINEPROFIT' ||
        typeKpi === 'ONLINESALES' || typeKpi === 'ONLINEAVERAGE_TRANSACTION' || typeKpi === 'PHYSICALAVERAGE_RECEIPT') {
        return ' €'
    }
    else if (typeKpi === 'PHYSICALCONVERSION' || typeKpi === 'ONLINECONVERSION'
    ) {
        return ' %'

    } else {
        return ""
    }
}