import React, { useEffect, useState } from 'react';
import { AngleCharts } from '../commons/AngleCharts';
import { CardTableOnline } from './CardTableOnline';



export function CardMySite({ data }) {
    const [dataCarts, setDataCarts] = useState([]);

    const [total, setTotal] = useState(0);

    // dataPLATFORMS
    useEffect(() => {

        let Carts = []
        let totalSales = 0;

        data.online.forEach(cart => {
            let value = []
            value = cart.kpi.filter(kpi =>
                kpi.subkind === "SALES"
            )

            Carts.push({ name: cart.name, value: value[0].value })


            totalSales = Number(totalSales) + Number(value[0].value)
        })

        setDataCarts(Carts)
        setTotal(totalSales)
    }, []);


    return (
        <div id="CardMySite" className="h-100" >
            <div className="bg-white shadowDropMediumBlur border-radius h-100 d-flex flex-column flex-wrap p-2 p-xs-4">
                <AngleCharts type="online" datacharts={dataCarts} total={total} />
                <div className="d-flex flex-column mt-3 w-100" >
                    <p className="c-tiffany font-weight-bold font-size-16 mb-0 ">
                        <CardTableOnline type="online" data={data} />
                    </p>
                </div>

            </div>

        </div>
    );
}