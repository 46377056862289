import React, { useRef, useState } from "react";
import { Offcanvas } from 'react-bootstrap'
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import Accordion from 'react-bootstrap/Accordion';
import "./mainsidebar.css";
import { useTranslation } from 'react-i18next';
import { useAppState } from "../../../store/AppState";
import { treeSelected, cleanHeadersSingleshop } from "../../../actions";
import { useHistory } from "react-router";
//IMAGE AND ICON
import IconGradClose from "../../../img/IconGradClose.png";
import IconGradFlag from "../../../img/IconGradFlag.png";
import IconGradLayers from "../../../img/IconGradLayers.png";
import IconGradHamburger from "../../../img/IconGradHamburger.png";
import IconBlackRightArrow from "../../../img/IconBlackRightArrow.png"
import IconGradAccount from "../../../img/IconGradAccount.png"
import IconGradPrivacy from "../../../img/IconGradPrivacy.png"
import IconGradBag from "../../../img/IconGradBag.png"
//COMPONENTS FROM REACT BOOTSTRAP
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
//Style css
import "./sidebars.css"


export const SidebarMain = () => {
    const gotoPage = useHistory();
    const [state, dispatch] = useAppState();
    const treeView = useRef(null);

    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const gotoDashboard = (data) => {
        dispatch(treeSelected({ dispatch, data, full: state.tree.full }));
        dispatch(cleanHeadersSingleshop());
        handleClose();
        gotoPage.push("/dashboard")
    }

    const expandTree = () => {
        treeView.current.expandAll();
    }

    const nodeTemplate = (data) => {
        return (
            <div id="treeviewSidebarTemplate" onClick={() => gotoDashboard(data)} >
                <div className="treeviewdiv">
                    <div className={`d-flex align-items-center ${data.identifier === state.tree.selected.identifier ? "active" : ""}`}>
                        {
                            data.kind === "STORE_PHYSICAL" ?
                                <span className="storeIcon e-badge e-badge-primary d-flex justify-content-center align-items-center me-2" /> :
                                <span className="circleName d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold m-0  font-size-13 me-2">
                                    {data.name.substring(0, 2)}
                                </span>
                        }
                        <p className="txtName c-black font-size-18 font-weight-regular m-0">{data.name}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <img alt="" src={IconGradHamburger} className="img-icon25 pointer" onClick={handleShow} />
            <Offcanvas id="mainSidebar" show={show} onHide={handleClose}>
                <Offcanvas.Body >
                    <div className="d-flex justify-content-end p-3 "><img alt="" className="img-icon25 pointer" src={IconGradClose} onClick={handleClose} /></div>
                    <div className="p-3">
                        <div className="d-flex border-bottom-grey pointer  align-self-center py-2" onClick={() => { gotoPage.push("/targetlist"); handleClose() }}>
                            <img alt="" className="img-icon20  me-2" src={IconGradFlag} />
                            <h3 className="c-black font-size-18 font-weight-bold flex-1 mb-0">{t("mainsidebar.target")}</h3>
                            <img alt="" className="img-icon15 " src={IconBlackRightArrow} /></div>
                    </div>
                    <Accordion>
                        <Accordion.Item eventKey="0" onClick={expandTree}>
                            <Accordion.Header><div className="d-flex pointer align-self-center py-2 w-100">
                                <img alt="" className="img-icon20 me-2" src={IconGradLayers} />
                                <h3 className="c-black font-size-18 font-weight-bold flex-1 mb-0">{t("mainsidebar.mylevel")}</h3></div>
                            </Accordion.Header>
                            <Accordion.Body className="scrollbarCustom">
                                <div className='control-pane'>
                                    <div className='control-section'>
                                        <div className='control_wrapper'>
                                            <TreeViewComponent id="treeview"
                                                nodeTemplate={nodeTemplate}
                                                ref={treeView}
                                                fields={{ dataSource: [state.tree.full], id: "identifier", text: 'name', child: 'haschild' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export function SidebarUser({ active, title }) {
    const gotoPage = useHistory();
    const { t } = useTranslation();

    return (
        <div id="sideBarUser" className="border-radius shadowDropMediumBlur bg-white stickySideBar">
            {/* DESKTOP */}
            <div className="px-3 py-5">
                <h2 className="c-black font-size-21 font-weight-bold px-1 pb-md-4">{t("useraresidebar.title")}</h2>
                <div className="desktopSidebarUser flex-column pb-5">

                    <div className="text-decoration-none borderBottomGrey d-flex
                    align-items-center pb-2 px-2 mb-3 pointer"
                        onClick={() => gotoPage.push("/myaccount")}>
                        <img alt="" className="img-icon25 me-2" src={IconGradAccount} />
                        <p className="c-dark-grey text-decoration-none font-weight-regular font-size-18 d-flex
                    align-items-center mb-0 flex-1"
                            style={active === "myAccount" ? { fontWeight: "bold", color: "#363636" } : null}>{t("useraresidebar.myaccount")}</p>
                        <img alt="" className="img-icon15 ms-3" src={IconBlackRightArrow} />
                    </div>
                    <div className="text-decoration-none borderBottomGrey d-flex
                    align-items-center pb-2 px-2 mb-3 pointer"
                        onClick={() => gotoPage.push("/conditionsofsale")}>
                        <img alt="" className="img-icon20 me-2" src={IconGradBag} />
                        <p className="c-dark-grey text-decoration-none font-weight-regular font-size-18 d-flex
                    align-items-center mb-0 flex-1"
                            style={active === "conditionsOfSale" ? { fontWeight: "bold", color: "#363636" } : null}>
                            {t("useraresidebar.conditionsofsale")}</p>
                        <img alt="" className="img-icon15 ms-3" src={IconBlackRightArrow} />
                    </div>
                    <div className="text-decoration-none borderBottomGrey d-flex
                    align-items-center pb-2 px-2 mb-3 pointer"
                        onClick={() => gotoPage.push("/privacy")}>
                        <img alt="" className="img-icon25 me-2" src={IconGradPrivacy} />
                        <p className="c-dark-grey text-decoration-none font-weight-regular font-size-18 d-flex
                    align-items-center mb-0 flex-1" style={active === "privacy" ? { fontWeight: "bold", color: "#363636" } : null}>
                            {t("useraresidebar.privacy")}</p>
                        <img alt="" className="img-icon15 ms-3" src={IconBlackRightArrow} />
                    </div>
                </div>
                {/* MOBILE */}
                <DropdownButton className="mobileSidebarUser" id="dropdown-mobile-user" title={title}>
                    <Dropdown.Item
                        active={active === "myAccount"}
                        onClick={() => gotoPage.push("/myaccount")}
                    ><p className="mb-0">{t("useraresidebar.myaccount")}</p> <img alt="" className="img-icon15" src={IconBlackRightArrow} /></Dropdown.Item>

                    <Dropdown.Item
                        active={active === "conditionsOfSale"}
                        onClick={() => gotoPage.push("/conditionsofsale")} >
                        <p className="mb-0">{t("useraresidebar.conditionsofsale")}</p></Dropdown.Item>
                    <Dropdown.Item
                        active={active === "privacy"}
                        onClick={() => gotoPage.push("/privacy")}>
                        <p className="mb-0">{t("useraresidebar.privacy")}</p></Dropdown.Item>
                </DropdownButton>
            </div>

        </div>
    );
}