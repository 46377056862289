import React, { useEffect, useState } from 'react';
import { Breadcrumb, CircleName, Navbars, Footers } from '../../commons';
import { useTranslation } from 'react-i18next';
import { useGetMultishopDashboard } from '../../../apis/fetch';
import { verifyResponse } from '../../../apis/response';
import { useAppState } from '../../../store/AppState';
import { MainShop } from './MainShop';
import { SubShops } from './SubShops';
import { Loading } from '../../commons/tables/loading/Loading';

export const Multishop = () => {
  const [{ tree, date }] = useAppState();
  const { t } = useTranslation();
  const getMultisshopDashboardResponse = useGetMultishopDashboard(date.typeDate, date.start, date.end);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
      setShow(false)
      let timer1 = setTimeout(() => setShow(true), 300 * 1000);
      return () => {
        clearTimeout(timer1);
      };
    },
  );

  useEffect(() => {
    if (getMultisshopDashboardResponse.content.isLoading === 'FETCHED') {
      if (verifyResponse(getMultisshopDashboardResponse, 200)) {
        setData(getMultisshopDashboardResponse.content.response.data);
        setIsLoading(false);
      } else console.log(getMultisshopDashboardResponse.content.response.data);
      getMultisshopDashboardResponse.sendRequest({ loading: 'NOT FETCHING' });
    }
  }, [getMultisshopDashboardResponse]);

  useEffect(() => {
    if (!tree.loading) {
      getMultisshopDashboardResponse.sendRequest({
        loading: 'FETCHING',
        body: JSON.stringify(tree.selected),
      });
      setIsLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree.selected, date.start, date.end]);

  return !tree.loading && !show &&
    <div id='dashboard'>
      <Navbars.NavbarDatePicker disabled={isLoading}/>
      <div className='container-fluid'>
        <div className='row '>
          <div className='p-2 col-12'>
            <Breadcrumb />
          </div>
          <div className='px-md-3 col-12 px-0 mb-1'>
            <div className='d-flex mb-3 ms-3 align-items-center'>
              <CircleName name={tree?.selected?.name?.substring(0, 2)} />
              <h3 className='c-black font-size-22 font-weight-bold mb-0 ms-3'>
                {tree.selected.name}
              </h3>
            </div>
            <h3 className=' c-black font-size-20 font-weight-semibold ms-3 mb-0'>
              {t('multishoptabcontainer.mydashboard')}
            </h3>
            <h3 className=' c-dark-grey font-size-15 font-weight-regular ms-3 mb-4'>
              {t('multishoptabcontainer.nbiva')}
            </h3>
          </div>
          <div className='px-md-2 col-12 ps-0 pe-0'>
            {!isLoading && <MainShop data={data} />}
          </div>
          <div className='px-3 col-12'>
            {!isLoading && data.haschild && <SubShops data={data.haschild} />}
          </div>
        </div>
        {isLoading && <Loading />}
        <div className='row '>
          <div className='px-4 px-md-2 col-12'>
            <Footers.FooterPoweredBy />
          </div>
        </div>
      </div>
    </div>
};
