import { getAccount } from '../middleware/apis';

export const accountRequest = (dispatch) => {
  return { type: 'ACCOUNT_REQUEST', payload: { loading: true, call: () => getAccount(dispatch) } };
};

export const accountSuccess = ({ data }) => {
  return { type: 'ACCOUNT_SUCCESS', payload: { loading: false, data: data } };
};

export const accountError = () => {
  return { type: 'ACCOUNT_ERROR', payload: { loading: false, data: [] } };
};
