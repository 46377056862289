import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Accordion from 'react-bootstrap/Accordion'
import { Inputs } from '../commons'
import { useAppState } from '../../store/AppState';
import { useTranslation } from 'react-i18next';
import "./filter.css"

export function FilterMobile({ filterArr, getFilterChosen, filterChosen }) {
  const { t } = useTranslation();
  const [state] = useAppState();
  const [show, setShow] = useState(false);
  const [filterChecked, setFilterChecked] = useState(filterChosen);

  useEffect(() => {
    setFilterChecked(filterChosen)
  }, [filterChosen]);

  useEffect(() => {
    getFilterChosen(filterChecked)
  }, [filterChecked]);

  const handleCheckbox = (e) => {
    const filterCheckedTemp = [...filterChecked]
    const value = JSON.parse(e.target.value)
    if (e.target.checked) {

      filterCheckedTemp.push(value)

    } else if (e.target.checked === false) {
      for (var i = filterCheckedTemp.length - 1; i >= 0; --i) {
        if (filterCheckedTemp[i].value === value.value) {
          filterCheckedTemp.splice(i, 1);
        }
      }
    }
    setFilterChecked(filterCheckedTemp)
  }

  return (
    <>
      <div id="filterTargetMobile" className=" col-12 h-100 d-block d-sm-none " >
        <ButtonComponent className="openModalFilter align-items-center d-flex mx-0 my-2 py-2 px-3"
          onClick={() => setShow(true)} >
          <span className=' e-icons e-Filter me-2' ></span>
          <span className=" font-size-16 mb-0 " >{t("targetfilter.buttonmodalfilters")}</span>
        </ButtonComponent>

      </div>
      <Modal
        Id="filterTargetModal"
        show={show}
        fullscreen
        onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="mt-3 text-center w-100 " >
            <h2 className="c-dark-grey font-size-18 text-center text-uppercase font-weight-bold">{t("targetfilter.title")}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end">
            <ButtonComponent
              disabled={filterChecked.length === 0}
              className="redlineButton  mb-3 font-size-16 px-3 " onClick={() => setFilterChecked([])}>
             {t("targetfilter.deletebutton")}
            </ButtonComponent>
          </div>
          <Accordion defaultActiveKey="0">
            {Object.entries(filterArr).map((filter, index) =>
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header ><h2 className="c-dark-grey font-size-18 mb-0 ms-1">{t(`targetfilter.${filter[1].title}`)}</h2></Accordion.Header>
                <Accordion.Body>
                  <div className="scrollbarCustom">
                    {index === 0 ?
                      <Inputs.CheckBoxTree
                        id={filter.title + index}
                        key={index}
                        title={filter[1].title}
                        tree={[state.tree.full]}
                        filterChecked={filterChecked}
                        onChange={handleCheckbox}
                      />
                      :
                      <Inputs.CheckBoxGroup
                        id={filter.title + index}
                        key={index}
                        title={filter[1].title}
                        filterChecked={filterChecked}
                        onChange={handleCheckbox}
                        listCheckbox={filter[1].filters}
                      />}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
          <div className="d-flex mt-4 justify-content-center">
          <ButtonComponent
              className="gradientButton  mb-3 font-size-16 px-3 "
               onClick={() => setShow(false)}>
             {t("targetfilter.buttonapplyfilter")}
            </ButtonComponent>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
}