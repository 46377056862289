import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useHistory } from "react-router";
import IconGradSuccess from "../../img/IconGradSuccess.png"
import { useTranslation } from 'react-i18next';


export function PasswordChangedContent() {
    const { t } = useTranslation();
    const gotoPage = useHistory();
    
    return (
        <div id="PasswordChangedContent" className="border-radius bg-white p-5 shadowDropMediumBlur min-height-520 d-flex flex-column justify-content-between">
            {/* <div className="d-flex  flex-column flex-wrap align-content-center justify-content-center my-2"> */}

                <div className="mt-3 mb-4 text-center">
                    <img alt="" className="img-icon90 mt-0 mb-3 " src={IconGradSuccess} />
                    <h4 className="c-dark-grey text-center font-size-26 font-weight-bold  mx-3 mt-1 mb-2">{t("passwordchanged.title")}</h4>
                </div>
                <ButtonComponent cssClass="gradientButton mb-1 mt-5 font-weight-semibold"
                   onClick={() => gotoPage.push("/login")}>{t("passwordchanged.backtologin")}</ButtonComponent>
                </div>
        // </div>
    );
}
