export function zEhide() {
  window.zE('webWidget', 'hide');
}
export function zEshow() {
  window.zE('webWidget', 'show');
}
export function zEprefill({ name, email }) {
  window.zE('webWidget', 'prefill', {
    name: {
      value: name,
      readOnly: true // optional
    },
    email: {
      value: email,
      readOnly: true // optional
    }
  });
}
export function zElocale(lang) {
  window.zE('webWidget', 'setLocale', lang)
}
export function zEcustomization() {
  window.zESettings = {
    webWidget: {
      color: {
        button: '#8F9DF8',
        launcherText: '#FFFFFF',
      }
    }
  };
}