import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardAffluenceChart } from '../commons/CardAffluenceChart';
import { CardRealTime } from '../commons/CardRealTime';
import { CardMyCashDesk } from './CardMyCashDesk';
import { PhysicalStoreTabContainer } from './PhysicalStoreTabContainer';

export function PhysicalShop({ data, parametersFetch }) {
    const { t } = useTranslation();
    const [realTimeStore, setRealTimeStore] = useState([]);

    const cardMyCashDeskShow = data.devices.cash.length > 1
    const cardRealTimeShow = data.store.filter(elem => elem.kind === "PHYSICAL" && elem.subkind === "REALTIME").length > 0

    useEffect(() => {
        const realTime = []
        if (cardRealTimeShow) {
            data.devices.zones.forEach(element =>
                realTime.push({ name: element.name, value: element.realtime })
            )
            setRealTimeStore(realTime)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div>
            <h3 className="c-dark-grey font-size-15 ms-1 mt-3">{t("physicalstoretabcontainer.nb")}</h3>
            <PhysicalStoreTabContainer
                data={data}
                parametersFetch={parametersFetch} />
            <div className="row g-0 mt-3">
                {cardMyCashDeskShow && (
                    <div className={`${realTimeStore.length > 0 ? "col-xl-7 pe-xl-3" : "col-12"}`}>
                        <div className="mb-4">
                            <h3 className="c-black font-weight-semibold font-size-20 ms-1 mt-2 mb-1">
                                {t(`physicalstoretabcontainer.mycashdesk`)}
                            </h3>
                            <CardMyCashDesk data={data} />
                        </div>
                    </div>)
                }
                <div className={`${cardMyCashDeskShow ? "col-xl-5 flex-column" : "col-xl-12"} d-flex flex-wrap`}>
                    {realTimeStore.length > 0 &&
                        <>
                            <div className={`mb-4 ${cardMyCashDeskShow ? "" : "w-50-100 pe-md-2 px-md-0 px-2"}`}>
                                <h3 className="c-black font-weight-semibold font-size-20 ms-1 mt-2 mb-1">
                                    {t(`physicalstoretabcontainer.myrealtime`)}
                                </h3>
                                <CardRealTime
                                    type="physical"
                                    data={data}
                                    dataRealTime={realTimeStore}
                                    kpiRealtime={data.store.filter(elem => elem.kind === "PHYSICAL" && elem.subkind === "REALTIME")} />
                            </div>
                            <div className={`mb-4 ${cardMyCashDeskShow ? "" : "w-50-100 ps-md-2 px-md-0 px-2"}`}>
                                <h3 className="c-black font-weight-semibold font-size-20 ms-1 mt-2 mb-1">
                                    {t(`physicalstoretabcontainer.affluence`)}
                                </h3>
                                <CardAffluenceChart data={data} />
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}