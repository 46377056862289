import { Category, ChartComponent, Tooltip, DataLabel, Inject, LineSeries, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
// import "./comparisonlinechart.css"
import { useTranslation } from 'react-i18next';
import { Formatters } from '../../../commons';
import './tablinechartsingleshop.css'
import moment from "moment";
import { setCurrencyCode } from '@syncfusion/ej2-base';

setCurrencyCode('EUR');

export function TabLineChartSingleshop({ mainData, subData, typeDate, type, name, id }) {
  const { t } = useTranslation();
  
  // FUNCTION SET PALETTE COLOR
  // const [colorPaletteCash, setColorPaletteCash] = useState([])
  // const [colorPaletteOnline, setColorPaletteOnline] = useState([])

  // useEffect(() => {
  //   const cashPaletteR = 138
  //   const cashPaletteG = 229
  //   const cashPaletteB = 237

  //   const newColorsCash = [] 
  //   for(let i = 0; i<subData.length; i++) {
  //     newColorsCash.push(`rgb(${cashPaletteR-(25*i)},${cashPaletteG-(25*i)},${cashPaletteB-(25*i)})`)
  //   }
  //   setColorPaletteCash(newColorsCash)

  //   const onlinePaletteR = 138
  //   const onlinePaletteG = 229
  //   const onlinePaletteB = 237

  //   const newColorsOnline = [] 
  //   for(let i = 0; i<subData.length; i++) {
  //     newColorsOnline.push(`rgb(${onlinePaletteR-(25*i)},${onlinePaletteG-(25*i)},${onlinePaletteB-(25*i)})`)
  //   }
  //   setColorPaletteOnline(newColorsOnline)
  // })

  const formatY = () => {
    switch (name) {
      case 'PHYSICALSALES':
      case 'PHYSICALPROFIT':
      case 'ONLINEPROFIT':
      case 'ONLINESALES':
      case 'ONLINEAVERAGE_TRANSACTION':
      case 'PHYSICALAVERAGE_RECEIPT':
        return '### €'
      case 'PHYSICALCONVERSION':
      case 'ONLINECONVERSION':
        return 'p'

      default:
        return 'n'
    }
  }



  // TOOLTIP
  let dataLong = "";
  const kpiTrend = mainData.percentage

  const tootltipeDate = (argsX) => {

    if (typeDate === "day") {
      mainData.history.forEach(element => {
        let time = argsX + ":00"
        dataLong = time
      })

    } else if (typeDate === "week") {

      mainData.history.forEach(element => {

        let dataWeek = moment(element.date).format("dddd-DD-MMM-YYYY")
        if (t(`formatterX.${moment(element.date).format("ddd")}`) === argsX) {
          let dataWeekTranslate = t(`multishoptablinechart.${dataWeek.split("-")[0].toLowerCase()}`) +
            " " + dataWeek.split("-")[1] + " " + t(`multishoptablinechart.${dataWeek.split("-")[2].toLowerCase()}`)
            + " " + dataWeek.split("-")[3]

          dataLong = dataWeekTranslate
        }
      })

    } else if (typeDate === "month") {
      mainData.history.forEach(element => {
        let dataMonth = moment(element.date).format("dddd-DD-MMM-YYYY")
        if (moment(element.date).format("DD") === ('0' + argsX).slice(-2)) {
          let dataMonthTranslate = t(`multishoptablinechart.${dataMonth.split("-")[0].toLowerCase()}`) +
            " " + dataMonth.split("-")[1] + " " + t(`multishoptablinechart.${dataMonth.split("-")[2].toLowerCase()}`)
            + " " + dataMonth.split("-")[3]
          dataLong = dataMonthTranslate
        }
      })
    } else if (typeDate === "year") {
      mainData.history.forEach(element => {
        let dataYear = moment(element.date).format("MMMM")
        if (t(`formatterX.${moment(element.date).format("MMM")}`) === argsX) {
          let dataMonthTranslate = t(`multishoptablinechart.long${dataYear.toLowerCase()}`)
          dataLong = dataMonthTranslate
        }
      })
    }
  }

  const linechartTooltipTemplate = (args) => {


    tootltipeDate(args.x)


    const tooltipTemplate = ReactDOMServer.renderToStaticMarkup(
      <div id={"linechartTooltip" + id}
        //style={{ backgroundColor: getColorForLine(subData, args.index) }}
        className={`linechartTooltipTemplateSingleShop d-flex flex-column p-2 border-radius ${type === "PHYSICAL" ? "bg-tiffany" : "bg-purple"}`}>

        <p className=" font-weight-semibold font-size-12 ms-2 mb-0 text-end"><span className="c-white">
          {dataLong}
        </span></p>

        <div>
          <p className="c-white font-weight-bold  font-size-13 ms-2 mb-0 text-end" >
            {args.y}
            {/* <Formatters.NumberFormatter value={args.y} currency={name === 'PHYSICALSALES' || name === 'PHYSICALPROFIT' || name === 'ONLINEPROFIT' || name === 'ONLINESALES' || name === 'PHYSICALPROFIT'} /> */}
            {/* <Formatters.FormatCurrency typeKpi={name} /> */}
          </p>
        </div>
        <div className="d-flex align-self-end ">
          <h4 className="c-white-op80 font-size-13 font-weight-semibold mb-0 text-end">
            {kpiTrend > 0 ? "+" : null}<Formatters.NumberFormatter value={parseInt(kpiTrend)} /> %
          </h4>
          <span className={`ms-1 ${kpiTrend > 0 ? "icon-trendUp" : kpiTrend < 0 ? "icon-trendDown" : "icon-trendDraw"}`} />
        </div>
      </div>)



    // const testdoc= document.querySelector("#TabLineChartSingleShop_tooltip div#TabLineChartSingleShop_tooltipparent_template div")
    document.querySelector("#TabLineChartSingleShop_tooltip div#TabLineChartSingleShop_tooltipparent_template div").innerHTML = tooltipTemplate
    return (
      <></>
      // <div id={"linechartTooltip" + id} className={`linechartTooltipTemplateSingleShop d-flex flex-column p-2 border-radius ${type === "PHYSICAL" ? "bg-tiffany" : "bg-purple"}`}>

      //   <p className=" font-weight-semibold font-size-12 ms-2 mb-0 text-end"><span className="c-white">
      //     {dataLong}
      //   </span></p>

      //   <div>
      //     <p className="c-white font-weight-bold  font-size-13 ms-2 mb-0 text-end" >
      //       <Formatters.NumberFormatter value={Number(args.y)} currency={name === 'PHYSICALSALES' || name === 'PHYSICALPROFIT' || name === 'ONLINEPROFIT' || name === 'ONLINESALES' || name === 'PHYSICALPROFIT'} />
      //       <Formatters.FormatCurrency typeKpi={name} />
      //     </p>
      //   </div>
      //   <div className="d-flex align-self-end ">
      //     <h4 className="c-white-op80 font-size-13 font-weight-semibold mb-0 text-end">
      //       {kpiTrend > 0 ? "+" : null}<Formatters.NumberFormatter value={parseInt(kpiTrend)} /> %
      //     </h4>
      //     <span className={`ms-1 ${kpiTrend > 0 ? "icon-trendUp" : kpiTrend < 0 ? "icon-trendDown" : "icon-trendDraw"}`} />
      //   </div>
      // </div>
    );
  }

  function getColorForLine(list, pos) {
    let colors = []
    if (type === 'ONLINE') {
      colors = [
        "#E1C4FF",
        "#C093F4",
        "#A05CFD",
        "#7936F7"
      ]
    } else {

      colors = [
        "#8AE5ED",
        "#4BB6C9",
        "#55A3B1",
        "#007996"
      ]

    }
    return colors[pos];
  }

  return (
    < div className='p-2 my-2 border-radius bg-white' >

      <div className='mx-md-4 py-3'>
        <ChartComponent
          tooltip={{
            enable: true,
             template: linechartTooltipTemplate
          }}
          id={"TabLineChartSingleShop"}
          className="overflowChart ComparisonLineChart"
          labelPlacement={"Auto"}
          primaryXAxis={{
            valueType: 'Category',
            interval: 1,
            majorGridLines: { width: 0 },
            labelIntersectAction: 'MultipleRows',
            labelPlacement: 'OnTicks'
          }}
          primaryYAxis={
            { labelFormat: formatY() }
          }
          chartArea={{ border: { width: 0 } }}
        >
          <Inject services={[LineSeries, Tooltip, DataLabel, Category]} />
          <SeriesCollectionDirective  >
            <SeriesDirective
              dataSource={Formatters.FormatDateChart(mainData.history, typeDate, 'date', 'value')}
              xName='date'
              yName='value'
              width={4}
              type='Line'
              fill={type === "PHYSICAL" ? "var(--tiffany)" : "var(--purple)"}
              marker={{ visible: false }}
            >
            </SeriesDirective>

            {subData.length > 1 && subData.map((desk, index) =>
              <SeriesDirective
                dataSource={Formatters.FormatDateChart(desk.history, typeDate, 'date', 'value')}
                xName='date'
                yName='value'
                width={3}
                type='Line'
                fill={getColorForLine(subData, index)}
                marker={{ visible: false }}
                dashArray={"10"}
              />
            )}


          </SeriesCollectionDirective>
        </ChartComponent>
        {subData.length > 1 && <div className="d-flex justify-content-start mt-3 ms-md-1 flex-wrap">
          <h3 className="c-dark-grey font-size-16 font-weight-regular d-flex align-items-center mx-4" >
            <span className={`legendComparison mx-2 ${type === "ONLINE" ? "bg-purple" : "bg-tiffany"}`} ></span>{t(`tablinechartsingleshop.total`)}</h3>

          {/* (name === "ONLINESALES" || name === "PHYSICALSALES") &&  */subData.map((desk, index) =>
            <h3 className="c-dark-grey font-size-16 font-weight-regular d-flex align-items-center mx-4" >

              <span className={`legendComparison mx-2`}
                style={{ backgroundColor: getColorForLine(subData, index) }} >
              </span>{desk.name}</h3>
          )}
        </div>}
      </div>
    </div >
  );
}





