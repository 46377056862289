import React, { useEffect, useState } from 'react';
import { Formatters, Inputs } from '../commons';
import IconVioletEuro from '../../img/IconVioletEuro.png';
import IconVioletPercentage from '../../img/IconVioletPercentage.png';
import IconVioletUser from '../../img/IconVioletUser.png';
import IconBlank from '../../img/IconBlank.png';
import { DatePicker } from './DatePicker';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  DropDownListComponent,
} from '@syncfusion/ej2-react-dropdowns';
import { Alerts } from '../commons';
import './form.css';
import { useNewTargetsUser } from '../../apis/fetch';
import { verifyResponse } from '../../apis/response';
import moment from 'moment';
import { useAppState } from '../../store/AppState';

import { useHistory } from "react-router";/* 
import { targetsRequest } from '../../actions'; */
import { useTranslation } from 'react-i18next';
import { DateDurationRadioTarget } from './DateDurationRadioTarget';
import { targetsRequest } from '../../actions';


export function Form() {
  const { t } = useTranslation();
  const gotoPage = useHistory();
  const newTargetsUserResponse = useNewTargetsUser();

  const [state, dispatch] = useAppState();
  const [level, setLevel] = useState({ id: 0, kind: '', kpi: [] });
  const [type, setType] = useState({ value: '', isDisabled: true });
  const [dateDisabled, setDateDisabled] = useState({ min: "day" });
  const [target, setTarget] = useState({ value: "", currency: 0, isDisabled: true, icon: IconBlank });
  const [date, setDate] = useState({ isDisabled: true });
  const [buttonSave, setButtonSave] = useState({ isDisabled: true });
  const [typeDate, setTypeDate] = useState('day');
  const [deleteDate, setDeleteDate] = useState(false);
  const [dateInfo, setDateInfo] = useState({ typeDate: 'day', start: "", end: "", min: moment().toDate() });
  const [dataKpi, setDataKpi] = useState([]);
  const [infoAlert, setInfoAlert] = useState({ type: 'targetDateDisabled', title: "formnewtarget.alertdatetitle", message: "formnewtarget.alertdatedescription", messageTwo: "formnewtarget.alertdatedescriptiontwo" })

  const [showAlert, setShowAlert] = useState("hidden");

  const jsonIconType = {
    physical: {
      sales: IconVioletEuro,
      conversion: IconVioletPercentage,
      profit: IconVioletEuro,
      affluence: IconVioletUser,
    },
    online: {
      sales: IconVioletEuro,
      conversion: IconVioletPercentage,
      profit: IconVioletEuro,
    },
  };

  const decimal = (kpiType) => {
    if (kpiType === "physicalsales" || kpiType === "physicalprofit" || kpiType === "onlineprofit" || kpiType === "onlinesales") {
      return 2
    } else {
      return 0
    }
  }

  /* useEffect(() => {
    dispatch(targetsRequest(dispatch, {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); */

  // FETCH SALVA OBIETTIVO
  useEffect(() => {
    if (newTargetsUserResponse.content.isLoading === 'FETCHED') {
      if (verifyResponse(newTargetsUserResponse, 201)) {
        gotoPage.push("/targetlist")
      } else console.log(newTargetsUserResponse.content.response.data);
      newTargetsUserResponse.sendRequest({ loading: 'NOT FETCHING' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTargetsUserResponse]);

  // SELEZIONA LIVELLO E SVUOTA/DISABILITA GLI ALTRI CAMPI 
  const selectLevel = (value) => {
    setTypeDate('day');
    setDate({ isDisabled: true });
    setType({ value: null, isDisabled: false });
    setTarget({ value: '', currency: 0, isDisabled: true, icon: target.icon });
    setLevel({ id: value.id, kind: value.kind, kpi: value.kpi });
    setDateInfo({ typeDate: 'day', start: "", end: "" });

    setButtonSave({ isDisabled: true });
    setDeleteDate(true)
  };

  // SELEZIONA KPI E SVUOTA/DISABILITA GLI ALTRI CAMPI
  const selectType = (value) => {
    setTypeDate('day');
    setDate({ isDisabled: true });
    setType({ value: value.value, isDisabled: false });

    const [kind, subkind] = value.value.split('-');
    setTarget({ value: '', currency: decimal(kind.toLowerCase() + subkind.toLowerCase()), isDisabled: false, icon: jsonIconType[kind.toLowerCase()][subkind.toLowerCase()] });

    setButtonSave({ isDisabled: true });
    setDeleteDate(true)
  };

  // SELEZIONA VALUE KPI E SVUOTA/DISABILITA GLI ALTRI CAMPI
  const selectTarget = (value) => {
    const disableDate = value >= 1
    setTypeDate('day');
    setDate({ isDisabled: !disableDate });
    setTarget({ value, currency: target.currency, isDisabled: target.isDisabled, icon: target.icon });
    setDateInfo({ typeDate: 'day', start: "", end: "" });

    setButtonSave({ isDisabled: true });
  };

  // SELEZIONA DATE E ABILITA BOTTONE SAVE
  useEffect(() => {
    setDateInfo({ typeDate: typeDate, start: dateInfo.start, end: dateInfo.end });
    if (dateInfo.start !== "") {
      setButtonSave({ isDisabled: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateInfo.typeDate, dateInfo.start, dateInfo.end]);

  useEffect(() => {
    dateInfo.start !== '' && dispatch(targetsRequest(dispatch, { getNew: "false", duration: dateInfo.typeDate.toUpperCase(), start: dateInfo.start, end: dateInfo.end, destinationId: level.id, destinationKind: level.kind }))
  }, [dateInfo.start, dateInfo.end])

  useEffect(() => {
    if (deleteDate) {
      setDeleteDate(false)
    }
  }, [deleteDate]);
  const buildTarget = () => {
    return {
      targetUser: {
        kind: dateInfo.typeDate.toUpperCase(),
        starts: dateInfo.start,
        ends: dateInfo.end,
        target_value: parseInt(target.value),
        config: {
          destination: {
            id: level.id,
            kind: level.kind,
          },
        },
      },
      kind: type.value.split('-')[0],
      subkind: type.value.split('-')[1],
    };
  };

  const sendTarget = () => {
    newTargetsUserResponse.sendRequest({
      loading: 'FETCHING',
      body: JSON.stringify(buildTarget()),
    });
  };

  useEffect(() => {
    setButtonSave({ isDisabled: true });
    setDateInfo({ typeDate: typeDate, start: "", end: "" });
    setDateDisabled({ min: moment().startOf(typeDate).toDate() });
  }, [typeDate]);

  useEffect(() => {
    let kpiDataArray = []
    const listKpi = level.kpi.filter(kpi => kpi.kind + kpi.subkind === 'PHYSICALSALES' ||
      kpi.kind + kpi.subkind === 'PHYSICALPROFIT' ||
      kpi.kind + kpi.subkind === 'PHYSICALCONVERSION' ||
      kpi.kind + kpi.subkind === 'PHYSICALAFFLUENCE' ||
      kpi.kind + kpi.subkind === 'ONLINESALES' ||
      kpi.kind + kpi.subkind === 'ONLINEPROFIT' ||
      kpi.kind + kpi.subkind === 'ONLINECONVERSION'
    )
    listKpi.forEach(kpi => {
      let valueKpi = `${kpi.kind}-${kpi.subkind}`
      let nameKpi = (`${kpi.kind}${kpi.subkind}`).toLowerCase()
      kpiDataArray.push({ name: t(`formnewtarget.${nameKpi}`), value: valueKpi })

    })

    setDataKpi(kpiDataArray)
  }, [level]);

  useEffect(() => {
    if (!state.targets.loading) {
      let alert = "hidden"
      state.targets.list?.filter(target => target.detail.status !== 'INTERRUPTED' &&
        target.type.kind + "-" + target.type.subkind === type.value &&
        target.detail.kind === typeDate.toUpperCase()
      ).forEach((target) => {

        if (Formatters.FormatDate(moment(dateInfo.start).startOf(typeDate.toLowerCase())) === Formatters.FormatDate(moment(target.detail.starts))) {
          alert = "visible"
        }

      })
      setShowAlert(alert);
    }
  }, [state.targets.loading])

  return !state.tree.loading && (
    <div
      id='targetWizardContent'
      className=' container-fluid d-flex flex-grow-1 mt-1'>
      <div className='row d-flex flex-fill fill d-flex h-100 mt-3 '>
        <div className='col-12'>
          <div className='bg-white-sm shadowDrop-md border-radius mt-2 px-2 px-sm-4 py-4 pb-5'>
            <h2 className='c-black font-size-20 font-weight-semibold'>
              {t(`formnewtarget.newtarget`)}
            </h2>
            <div className='mb-2 mt-4 py-0'>
              <h3 className='c-dark-grey font-size-14 font-weight-bold text-uppercase mb-2'>
                {t(`formnewtarget.selectlevel`)}
              </h3>

              <Inputs.FormDropDownTree
                id='dropdowntree'
                title="formnewtarget.selectlevelplaceholder"
                tree={[state.tree.full]}
                onChange={selectLevel}
              />
            </div>
            <div className='mb-2 mt-4 py-0'>
              <h3 className={`c-dark-grey font-size-14 font-weight-bold text-uppercase mb-2 ${type.isDisabled ? 'c-medium-grey' : 'c-dark-grey'}`}>
                {t(`formnewtarget.selectkpi`)}
              </h3>
              <div
                className='gradient-input-round gradient-dropdowntree mt-2 '
                style={{ opacity: type.isDisabled ? 0.5 : 1 }}
              >
                <DropDownListComponent
                  id='dropdownlist'
                  enabled={!type.isDisabled}
                  value={type.value}
                  dataSource={dataKpi}
                  placeholder={t(`formnewtarget.selectkpiplaceholder`)}
                  onChange={(e) => selectType(e)}
                  fields={{ text: "name", value: 'value' }}
                />
              </div>
            </div>
            <div className='mb-2 mt-4 py-0'>
              <h3
                className={`c-dark-grey font-size-14 font-weight-bold text-uppercase  mb-2 ${target.isDisabled ? 'c-medium-grey' : 'c-dark-grey'}`}>
                {t(`formnewtarget.targetvalue`)}
              </h3>
              <div className='mt-2'>
                <Inputs.InputTextIcon
                  decimal={target.currency}
                  Icon={target.icon}
                  disabled={target.isDisabled}
                  value={target.value}
                  onChange={(e) => selectTarget(e)}
                  placeholder={t(`formnewtarget.targetvalueplaceholder`)}
                />
              </div>
            </div>
            <div className='mb-2 mt-4 py-0'>
              <h3 style={{ opacity: date.isDisabled ? 0.5 : 1 }} className='c-dark-grey font-size-14 font-weight-bold text-uppercase mb-2'>
                {t(`formnewtarget.selectperiod`)}
              </h3>
              <DateDurationRadioTarget
                disabled={date.isDisabled}
                style={{ opacity: date.isDisabled ? 0.5 : 1 }}
                id='formNewTarget'
                setTypeDate={setTypeDate}
                typeDate={typeDate}
              />
              <h3 style={{ opacity: date.isDisabled ? 0.5 : 1 }} className='c-dark-grey font-size-14 font-weight-bold text-uppercase mt-4 mb-2'>
                {t(`formnewtarget.selectdate`)}
              </h3>
              <div className='d-flex flex-wrap'>
                <div className='gradient-calendar mb-3 me-3' style={{ opacity: date.isDisabled ? 0.5 : 1 }}>
                  <DatePicker
                    deleteDate={deleteDate}
                    setDateInfo={setDateInfo}
                    disabled={date.isDisabled}
                    id='formNewTarget'
                    typeDate={typeDate}
                    min={dateDisabled.min}
                    max={null}
                  />
                </div>
                <div id='disabledDatelert' className=' '>
                  <Alerts.Alert
                    info={infoAlert}
                    show={showAlert}
                  />
                </div>
              </div>
            </div>
            <ButtonComponent
              className='gradientButton mt-2 mb-5 font-weight-bold '
              disabled={buttonSave.isDisabled || showAlert !== "hidden"}
              onClick={() => sendTarget()}
            >
              {t(`formnewtarget.buttonsave`)}
            </ButtonComponent>
          </div>
        </div>
      </div>
    </div>
  );
}
