import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import IconWhiteBack from "../../../img/IconWhiteBack.png";
import { useAppState } from '../../../store/AppState';
import "./navbardatepicker.css"
import moment from 'moment';
import LogoSaga from '../../../img/LogoSaga.png';
import { useTranslation } from 'react-i18next';
import './navbars.css';
import { DateDurationRadio, DatePicker, LanguageSelector, MyAccountDropdown, Sidebars } from '..';

export function NavbarBackPage() {
    const { t } = useTranslation();
    const gotoPage = useHistory();
    const [state] = useAppState();

    const backPage = () => {
        gotoPage.push("/dashboard");
    }

    return (
        <div className="bg-grad py-1 px-4 d-flex justify-content-between flex-row align-items-center position-sticky" style={{ top: "54px", zIndex: "1030" }}>
            <div className="align-items-center d-flex text-decoration-none pointer" onClick={() => backPage()}>
                <img className="img-icon15 me-2" alt="Go back" src={IconWhiteBack} />
                <p className="c-white my-2 font-size-18 me-2">
                    {t("navbarbackto.backto")}
                    <span className="c-white  my-2 font-weight-bold font-size-16">
                        "{state.tree.selected.name}"
                    </span>
                </p>
            </div>
        </div>
    );
}

export function NavbarDatePicker({ disabled }) {
    const { t } = useTranslation();
    const [{ date }] = useAppState();
    const [dateDisabled, setDateDisabled] = useState({ max: "day" });

    useEffect(() => {
        setDateDisabled({ max: date.typeDate === "week" ? moment().endOf(date.typeDate).startOf("day").toDate() : moment().endOf(date.typeDate).toDate() });
    }, [date.typeDate]);

    return (
        <div className="bg-grad py-1 px-md-4 d-flex justify-content-between flex-row align-items-center position-sticky" style={{ top: "54px", zIndex: "99" }}>
            <div id="DatePickerNavbar" className='px-3'>
                <div className='row align-items-center'>
                    <h2 className="col c-white font-weight-bold font-size-16 me-3 ms-3 ms-md-0 ps-0 mb-0"> {t("navbardatepicker.select")}</h2>
                    <DateDurationRadio
                        id={"Navbar"}
                        disabled={disabled}
                    />
                    <div className='my-2 col'>
                        <div className='control-section'>
                            <DatePicker
                                max={dateDisabled.max}
                                id={"Navbar"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function NavbarLogo() {
    return (
        <div id="NavbarLogo">
            <div className="container-fluid  px-5 py-2">
                <div className="row justify-content-between">
                    <div className="col-auto m-md-0 m-auto">
                        <div className="logoSaga p-2 my-md-0 my-3">
                            <img alt="" className="img-icon80 img-sm-icon50 m-1" src={LogoSaga} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function NavbarMain() {
    return (
        <div id='navbarMain' className='w-100 shadowDropMediumBlur '>
            <div className='bg-white  py-1 px-4 d-flex justify-content-between flex-row align-items-center'>
                <div className='w-25 py-2'>
                    <Sidebars.SidebarMain />
                </div>
                <div className=''>
                    <img className='img-icon40' alt="logo Saga" src={LogoSaga} />
                </div>
                <div className='justify-content-end py-2 align-items-center d-flex w-25'>
                    <LanguageSelector />
                    <MyAccountDropdown />
                </div>
            </div>
        </div>
    );
};