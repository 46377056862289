import { Navbars, Footers } from '../commons/';
import { ForgotPasswordContent } from './ForgotPasswordContent'
import { ResetPasswordContent } from './ResetPasswordContent'
import { MailNewPasswordContent } from './MailNewPasswordContent'
import "./mailchanged.css"

export function ForgotPassword() {
    return (
        <div id="forgotPassword" className="autenticationBody d-flex flex-column align-items-stretch">
            <Navbars.NavbarLogo />
            <div className="container-fluid container-xl h-100 flex-grow-1 mb-4 mt-n5">
                <div className="row justify-content-center mt-n6 px-xl-5">
                    <div className="col-md-7 col-xl-5">
                        <ForgotPasswordContent />
                    </div>
                </div>
            </div>
            <Footers.FooterSocialAndFaq />
        </div>
    );
}

export function ResetPassword() {
    return (
        <div id="resetPassword" className="autenticationBody d-flex flex-column align-items-stretch">
            <Navbars.NavbarLogo />
            <div className="container-fluid container-xl h-100 flex-grow-1 mb-4 mt-n5">
                <div className="row justify-content-center mt-n6 px-xl-5">
                    <div className="col-md-7 col-xl-5">
                        <ResetPasswordContent />
                    </div>
                </div>
            </div>
            <Footers.FooterSocialAndFaq />
        </div>
    );
}

export function MailNewPassword() {
    return (
        <div id="mailNewPassword" className="autenticationBody d-flex flex-column align-items-stretch">
            <Navbars.NavbarLogo />
            <div className="container-fluid container-xl h-100 flex-grow-1 mb-4 mt-n5">
                <div className="row justify-content-center mt-n6 px-xl-5">
                    <div className="col-md-7 col-xl-5">
                        <MailNewPasswordContent />
                    </div>
                </div>
            </div>
            <Footers.FooterSocialAndFaq />
        </div>
    );
}