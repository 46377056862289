import {
    AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationTooltip, Inject
} from '@syncfusion/ej2-react-charts';
import React from 'react';
import './piechart.css'


import { useTranslation } from 'react-i18next';

export function PieChart({ Id, type, physical, online }) {
    const { t, i18n } = useTranslation();


    const dataPieChart = [
        { x: "shop", y: physical[0].value },
        { x: "online", y: online[0].value }
    ]

    // TOOLTIP
    const tooltipRender = (args) => {
        const total=physical[0].value + online[0].value
        args.headerText = t(`piechart.${args.point.x}`);
      args.text = ((args.point.y/total)*100).toLocaleString(undefined, { maximumFractionDigits: 0, minimumFractionDigits:0 }) +"%"
      args.textStyle.color = '#fff';
    }

    const tooltip = {
        enable: true,
        fill: 'var(--medium-grey)'
    }

    //GRADIENT COLOR FOR CHART
    function onPointRender(args) {
        if (args.point.x.indexOf('shop') > -1) {
            args.fill = ' url(#gradient-chart_2)';
        }
        else if (args.point.x.indexOf('online') > -1) {
            args.fill = ' url(#gradient-chart_1)';
        } else {
            args.fill = '#76C8F4';
        }
    }

    const SAMPLE_CSS = `
        #gradient-chart_1 stop {
            stop-color:#DB91FF;
        }
        #gradient-chart_1 stop[offset="0"] {
            stop-color:#DB91FF;stop-opacity:1
        }
        #gradient-chart_1 stop[offset="1"] {
            stop-color:#B558FD;stop-opacity:1
        }
        #gradient-chart_2 stop {
            stop-color: #98E2D9;
        }
        #gradient-chart_2 stop[offset="0"] {
            stop-color:#98E2D9;stop-opacity:1
        }
        #gradient-chart_2 stop[offset="1"] {
            stop-color:#5DC6D0;stop-opacity:1
        }  `;



    return (
        <>
            <style>
                {SAMPLE_CSS}
            </style>
            <div id="pieChart" className="d-flex pieChart" >
                <AccumulationChartComponent id={"pieChart" + Id}
                    enableBorderOnMouseMove={false}
                    tooltip={tooltip}
                    pointRender={onPointRender}
                    width='240px'
                    height='260px'
                    tooltipRender={tooltipRender}
                >
                    <Inject services={[AccumulationTooltip]} />
                    <AccumulationSeriesCollectionDirective >
                        <AccumulationSeriesDirective dataSource={dataPieChart}
                            xName='x'
                            yName='y'
                            innerRadius='45%'
                        // explode={true} explodeOffset='8%' explodeIndex={0} 
                        >
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>

                </AccumulationChartComponent>

                <svg style={{ height: '0' }}>
                    <defs>
                        <linearGradient id="gradient-chart_1" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0" />
                            <stop offset="1" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg style={{ height: '0' }}>
                    <defs>
                        <linearGradient id="gradient-chart_2" x1="0" x2="0" y1="0" y2="1">
                            <stop offset="0" />
                            <stop offset="1" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
        </>)
}






