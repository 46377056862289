import { useEffect, useState } from 'react';

export const urlServer = () => {
  return window['REACT_APP_URL_SERVER_' + process.env.REACT_APP_ENVIRONMENT];
};

export function verifyMessage(text) {
  if(text === "ErrorSubscriptionExpired" || text === "ErrorSessionDuplicate"){
    window.location.pathname = "/login";
  }
}
export const useFetch = (request) => {
  const [response, setResponse] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState('NOT FETCHING');
  const [body, setBody] = useState(null);

  const sendRequest = (request) => {
    setIsLoading(request.loading);
    setBody(request.body);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (body) {
          request.params['body'] = body;
        }

        const result = await fetch(urlServer() + request.url, request.params)
          .then((response) => response.json())
          .then((data) => {
            return data;
          })
          .catch((err) => {
            console.log(err);
            return {
              code: 503,
              message: 'ErrorServiceUnavailable',
              data: { success: false },
            };
          });

        verifyMessage(result.message)
        setResponse(result);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading('FETCHED');
        setBody(null);
      }
    };

    if (isLoading === 'FETCHING' && request.url !== '') {
      fetchData();
    }
  }, [isLoading]);

  return { content: { response, isError, isLoading }, sendRequest, urlServer };
};

export const headers = { 'Content-Type': 'application/json; charset=UTF-8' };
