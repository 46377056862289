import React from "react";
import './scroller.css';

export const Scroller = ({ children, id }) => {
    let isDown = false;
    let startX;
    let scrollLeft;

    const mouseDown = (e) => {
        let scroller = document.querySelector('.scroller' + id);
        isDown = true;
        startX = e.pageX - scroller.offsetLeft;
        scrollLeft = scroller.scrollLeft;
    }

    const mouseLeave = () => {
        isDown = false;
    }

    const mouseUp = () => {
        isDown = false;
    }

    const mouseMove = (e) => {
        if (!isDown) return;
        let scroller = document.querySelector('.scroller' + id);
        e.preventDefault();
        const x = e.pageX - scroller.offsetLeft;
        const walk = (x - startX);
        scroller.scrollLeft = scrollLeft - walk;
    }

    const touchStart = (e) => {
      
        let scroller = document.querySelector('.scroller' + id);
        isDown = true;
         startX = e.touches[0].pageX  - scroller.offsetLeft;
        scrollLeft = scroller.scrollLeft;
    }


    const scroller = document.getElementById("scroller" + id)
    scroller&&scroller.addEventListener('touchmove', function (e) {
        if (!isDown) return;
        let scroller = document.querySelector('.scroller' + id);
        e.preventDefault();
       const x =  e.touches[0].pageX - scroller.offsetLeft;
        const walk = (x - startX);
        scroller.scrollLeft = scrollLeft - walk;
    }, false);




    const touchEnd = () => {
        isDown = false;
    }


    return (
        <div
            id={"scroller" + id}
            className={"scroller scroller" + id}
            onMouseDown={mouseDown}
            onMouseUp={mouseUp}
            onMouseLeave={mouseLeave}
            onMouseMove={mouseMove}

            onTouchStart={touchStart}
            // onTouchMove={(e) => touchMove(e)}
            onTouchEnd={touchEnd}
        >
            {children}
        </div>
    )
}