import { getTargets } from '../middleware/apis';

export const targetsRequest = (dispatch, data) => {
  return { type: 'GET_TARGETS_REQUEST', payload: { loading: true, call: () => getTargets(dispatch, data) } };
};

export const targetsSuccess = ({ data }) => {
  return { type: 'GET_TARGETS_SUCCESS', payload: { loading: false, data: data } };
};

export const targetsError = () => {
  return { type: 'GET_TARGETS_ERROR', payload: { loading: false, data: [] } };
};