import React, { useEffect, useRef } from 'react';

import "./tabheaderbutton.css"
// i18next Translation
import { useTranslation } from 'react-i18next';




export function TabHeaderButton({ title, description, type, click, active }) {
    const { t } = useTranslation();

    return (
        <div id={"TabHeaderButtonSingleShop" + type} onClick={click}
            className={`tabHeaderButtonSingleShop p-4 bg-white pointer border-radius shadowDropMediumBlur d-flex transition ${active ? "active" : ""}
             ${type === "OVERVIEW" ? "overviewHeaderButton" : type === "ONLINE" ? "onlineHeaderButton" : "storeHeaderButton"}`}>
                 <div className="d-flex flex-lg-row flex-md-column flex-row " > 
                <span className="IconHeaderSingleShop me-3 "></span>
                <div className="txt-container d-flex flex-column ">
            <h2 className="c-black font-size-18 font-weight-bold ">{t(title)}</h2>
            <p className="c-dark-grey font-size-15 font-weight-regular mb-0 ">{t(description)}</p>
            </div>
             </div>
        </div>
    );
}





