import { Navbars, Footers } from '../commons/';
import { MailChangedContent } from './MailChangedContent'
import { PasswordChangedContent } from './PasswordChangedContent'
import "./mailchanged.css"

// fareTraduzioni
export function MailChanged() {
    return (
        <div id="mailChanged" className=" d-flex flex-column bg-white container-fluid">
            <MailChangedContent />
        </div>
    );
}

export function PasswordChanged() {
    return (
      <div id="changePassword" className="autenticationBody d-flex flex-column align-items-stretch">
        <Navbars.NavbarLogo />
        <div className="container-fluid container-xl h-100 flex-grow-1 mb-4 mt-n5">
          <div className="row justify-content-center mt-n6 px-xl-5">
            <div className="col-md-7 col-xl-5">
              <PasswordChangedContent />  
            </div>
          </div>
        </div>
        <Footers.FooterSocialAndFaq />
      </div>
    );
  }