export function targetsReducer(state, action) {
  const { type, payload } = action;

  state = { ...state };
  switch (type) {
    case 'GET_TARGETS_REQUEST':
      state.targets.loading = payload.loading;
      state.targets.new = [];
      state.targets.list = [];
      payload.call();
      break;
    case 'GET_TARGETS_SUCCESS':
      state.targets.loading = payload.loading;
      state.targets.new = payload.data.newTargets;
      state.targets.list = payload.data.targets;
      break;
    case 'GET_TARGETS_ERROR':
      state.targets.loading = payload.loading;
      state.targets.new = [];
      state.targets.list = [];
      break;
    default:
      return state;
  }

  return state;
}
