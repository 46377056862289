import React from 'react';
import { Form } from './Form';
import { Footers, Navbars } from '../commons';

export function Login() {
  return (
    <div id="loginPage" className="autenticationBody d-flex flex-column align-items-stretch">
      <Navbars.NavbarLogo />
      <div className="container-fluid container-xl h-100 flex-grow-1 mb-4 mt-n5">
        <div className="row justify-content-center mt-n6 px-xl-5">
          <div className="col-md-7 col-xl-5">
            <Form />
          </div>
        </div>
      </div>
      <Footers.FooterSocialAndFaq />
    </div>
  );
}