import React, { useEffect, useState } from 'react';
// i18next Translation
import { useTranslation } from 'react-i18next';


import { ResponsiveTablePhysical } from './ResponsiveTablePhysical';
// Style CSS
import './filtermobile.css'



export function CardTablePhysical({  data }) {
    const { t } = useTranslation();
    const filters = {
        fields: [
            'name',
            'physical__sales',
            'physical__receipts',
            'physical__average_receipt'
        ],
        orders: [
            'desc',
            'asc'
        ]
    }

    const valueToCompare = (elem, field) => {
        return field === 'name'
            ? elem[field]
            : elem.kpi.filter(elem => elem.kind === field.split('__')[0].toUpperCase() && elem.subkind === field.split('__')[1].toUpperCase())[0].value;
    }


    return (
        <>
            <ResponsiveTablePhysical
                id={'table-physical'}
                filters={filters}
                data={data.devices.cash}
                valueToCompare={valueToCompare}
            />
        </>
    );


}
