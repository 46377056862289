export function accountReducer(state, action) {
  const { type, payload } = action;

  state = { ...state };
  switch (type) {
    case 'ACCOUNT_REQUEST':
      state.account.loading = payload.loading;
      payload.call();
      break;
    case 'ACCOUNT_SUCCESS':
      state.account.loading = payload.loading;
      state.account.data = payload.data;
      break;
    case 'ACCOUNT_ERROR':
      state.account.loading = payload.loading;
      state.account.data = {};
      break;
    default:
      return state;
  }

  return state;
}
