import { pathToParent } from '../middleware/recursive';

export function treeReducer(state, action) {
  const { type, payload } = action;

  state = { ...state };
  switch (type) {
    case 'TREE_REQUEST':
      state.tree.loading = payload.loading;
      payload.call();
      break;
    case 'TREE_SUCCESS':
      state.tree.loading = payload.loading;
      state.tree.full = payload.data[0];
      break;
    case 'TREE_ERROR':
      state.tree.loading = payload.loading;
      state.tree.full = {};
      break;
    case 'TREE_SELECTED':
      state.tree.loading = payload.loading;
      state.tree.selected = payload.data;
      sessionStorage.setItem('multishopItemSelected', JSON.stringify(payload.data));
      payload.call();
      break;
    case 'TREE_BREADCRUMB':
      state.tree.loading = payload.loading;
      state.tree.breadcrumb = [];
      state.tree.breadcrumb.push(...pathToParent(payload.full, payload.selected));
      break;
    default:
      return state;
  }

  return state;
}
