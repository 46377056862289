export function dateReducer(state, action) {
  const { type, payload } = action;

  state = { ...state };
  switch (type) {
    case 'SET_DATE':
      state.date = payload.date;
      // state.date.loading = true;
      break;
    default:
      return state;
  }

  return state;
}