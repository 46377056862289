import React, { useEffect, useState } from 'react';
import { Card } from './Card'
import { EmptyTarget } from './EmptyTarget'
import { Filter } from "./Filter"
import { FilterMobile } from "./FilterMobile"
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useHistory } from "react-router";
import { useTranslation } from 'react-i18next';
import { useAppState } from '../../store/AppState';
import { targetsRequest } from '../../actions';
import { Formatters } from '../commons';
import moment from 'moment';
import { Loading } from '../commons/tables/loading/Loading';

export function List({ parametersFetch }) {
  const gotoPage = useHistory();
  const { t } = useTranslation();
  const [state, dispatch] = useAppState();
  const [filterChosen, setFilterChosen] = useState([]);
  const [cardsTargetFiltered, setCardsTargetFiltered] = useState([]);
  const [loadImage, setLoadImage] = useState(false);
  useEffect(() => {
    if (state.targets.loading) {
      setLoadImage(true)
      setFilterChosen([]);
    } else {
      setLoadImage(false)
    }
  }, [state.targets.loading])

  const getLevel = () => {
    let level = []
    if (state.tree.full) {
      level.push({ name: state.tree.full.name, value: state.tree.full.identifier, type: "LEVEL" })
      if (state.tree.full.haschild) {

        getLevelChild(state.tree.full.haschild, level)
      }
    }
    return level
  }


  const getLevelChild = (levelChild, level) => {
    levelChild.forEach((treeChild) => {
      level.push({ name: treeChild.name, value: treeChild.identifier, type: "LEVEL" })
      if (treeChild.haschild) {

        getLevelChild(treeChild.haschild, level)
      }
    })
  }

  const getYear = () => {
    let years = []
    if (!state.targets.loading) {
      state.targets.list.forEach(child => {
        years.push({ name: Formatters.FormatDateYear(moment(child.detail.starts)), value: Formatters.FormatDateYear(moment(child.detail.starts)), type: "YEAR" });

      })

      years = years.filter((year, index, self) =>
        index === self.findIndex((t) => (
          t.place === year.place && t.name === year.name && t.value === year.value && t.type === year.type
        ))
      )

      years = years.sort((a, b) => {
        return b.value - a.value;

      })

      return years
    }
  }

  const getCreators = () => {
    let creators = []
    if (!state.targets.loading) {
      state.targets.list.forEach(child => {
        creators.push({ name: child.creator.name + " " + child.creator.surname, value: "USER_" + child.creator.id, type: "CREATOR" });


      })
      creators = creators.filter((creator, index, self) =>
        index === self.findIndex((t) => (
          t.place === creator.place && t.name === creator.name && t.value === creator.value && t.type === creator.type
        ))
      )


      return creators
    }
  }

  const filterArr = {
    Level: {
      title: "level", filters: getLevel()
    },
    Kpi: {
      title: "kpi", filters: [{ name: t(`${"targetfilter.physicalsales"}`), value: "PHYSICALSALES", type: "KPI" },
      { name: t(`${"targetfilter.physicalconversion"}`), value: "PHYSICALCONVERSION", type: "KPI" }, { name: t(`${"targetfilter.physicalprofit"}`), value: "PHYSICALPROFIT", type: "KPI" },
      { name: t(`${"targetfilter.physicalaffluence"}`), value: "PHYSICALAFFLUENCE", type: "KPI" }, { name: t(`${"targetfilter.onlinesales"}`), value: "ONLINESALES", type: "KPI" },
      { name: t(`${"targetfilter.onlineconversion"}`), value: "ONLINECONVERSION", type: "KPI" }, { name: t(`${"targetfilter.onlineprofit"}`), value: "ONLINEPROFIT", type: "KPI" }]
    },
    Duration: {
      title: "durata", filters: [{ name: t(`${"targetfilter.dayly"}`), value: "DAY", type: "PERIOD" }, { name: t(`${"targetfilter.weekly"}`), value: "WEEK", type: "PERIOD" },
      { name: t(`${"targetfilter.monthly"}`), value: "MONTH", type: "PERIOD" }, { name: t(`${"targetfilter.yearly"}`), value: "YEAR", type: "PERIOD" }]
    },
    Year: {
      title: "year", filters: getYear()
    },
    Month: {
      title: "month", filters: [{ name: t(`${"targetfilter.january"}`), value: "MONTH_" + 1, type: "MONTH" }, { name: t(`${"targetfilter.february"}`), value: "MONTH_" + 2, type: "MONTH" },
      { name: t(`${"targetfilter.march"}`), value: "MONTH_" + 3, type: "MONTH" }, { name: t(`${"targetfilter.april"}`), value: "MONTH_" + 4, type: "MONTH" }, { name: t(`${"targetfilter.may"}`), value: "MONTH_" + 5, type: "MONTH" },
      { name: t(`${"targetfilter.june"}`), value: "MONTH_" + 6, type: "MONTH" }, { name: t(`${"targetfilter.july"}`), value: "MONTH_" + 7, type: "MONTH" }, { name: t(`${"targetfilter.august"}`), value: "MONTH_" + 8, type: "MONTH" },
      { name: t(`${"targetfilter.september"}`), value: "MONTH_" + 9, type: "MONTH" }, { name: t(`${"targetfilter.october"}`), value: "MONTH_" + 10, type: "MONTH" },
      { name: t(`${"targetfilter.november"}`), value: "MONTH_" + 11, type: "MONTH" }, { name: t(`${"targetfilter.december"}`), value: "MONTH_" + 12, type: "MONTH" }
      ]
    },
    Status: {
      title: "status", filters: [{ name: t(`${"targetfilter.past"}`), value: "PAST", type: "STATUS" },
      { name: t(`${"targetfilter.present"}`), value: "PRESENT", type: "STATUS" }, { name: t(`${"targetfilter.future"}`), value: "FUTURE", type: "STATUS" }]
    },
    Achievement: {
      title: "achievement", filters: [{ name: t(`${"targetfilter.completed"}`), value: "COMPLETED", type: "ARCHIEVEMENT" },
      { name: t(`${"targetfilter.notcompleted"}`), value: "NOTCOMPLETED", type: "ARCHIEVEMENT" },
      { name: t(`${"targetfilter.interupted"}`), value: "INTERRUPTED", type: "ARCHIEVEMENT" }]
    },
    Creator: {
      title: "creator", filters: getCreators()
    }
  }

  useEffect(() => {

    setCardsTargetFiltered(state.targets.list)

  }, [state.targets.list]);

  useEffect(() => {
    console.log(filterChosen)
    let arrayTargetCard = []
    let arrayLevel = [state.targets.list]
    let arrayKpi = [state.targets.list]
    let arrayDuration = [state.targets.list]
    let arrayYears = [state.targets.list]
    let arrayMonth = [state.targets.list]
    let arrayStatus = [state.targets.list]
    let arrayArchievement = [state.targets.list]
    let arrayCreator = [state.targets.list]

    if (filterChosen.length === 0 && state.targets.list) {
      setCardsTargetFiltered(state.targets.list)
    } else if (filterChosen.length > 0 && cardsTargetFiltered) {

      let arrayKpiTemp = []
      let arrayLevelTemp = []
      let arrayDurationTemp = []
      let arrayYearsTemp = []
      let arrayMonthTemp = []
      let arrayStatusTemp = []
      let arrayArchievementTemp = []
      let arrayCreatorTemp = []

      filterChosen.forEach(function (filterSingle) {

        state.targets.list.forEach(function (cardSingle) {
          // Level
          if (filterSingle.type === "LEVEL") {
            arrayLevel = [arrayLevelTemp]
            if (cardSingle.detail.config.destination.kind + "-" + cardSingle.detail.config.destination.id === filterSingle.value) {
              arrayLevelTemp.push(cardSingle)
            }
          }
          // KPI
          if (filterSingle.type === "KPI") {
            arrayKpi = [arrayKpiTemp]
            if ((cardSingle.type.kind + cardSingle.type.subkind) === filterSingle.value) {
              arrayKpiTemp.push(cardSingle)
            }
          }


          // Durata
          if (filterSingle.type === "PERIOD") {
            arrayDuration = [arrayDurationTemp]
            if (cardSingle.detail.kind === filterSingle.value) {
              arrayDurationTemp.push(cardSingle)
            }
          }


          // Year
          if (filterSingle.type === "YEAR") {
            arrayYears = [arrayYearsTemp]
            if (Formatters.FormatDateYear(moment(cardSingle.detail.starts)) === filterSingle.value) {

              arrayYearsTemp.push(cardSingle)
            }

          }
          // Month
          if (filterSingle.type === "MONTH") {
            arrayMonth = [arrayMonthTemp]
            if (typeof filterSingle.value === "string" && filterSingle.value.split('_') && filterSingle.value.split('_')[0] === "MONTH") {
              console.log(Formatters.FormatDateMonth(moment(cardSingle.detail.starts)))
              console.log(Formatters.FormatDateMonth(moment(cardSingle.detail.ends)))
              let thisMonth = Formatters.FormatDateMonth(moment(cardSingle.detail.starts)) <= (filterSingle.value.split('_')[1]) &&
                (filterSingle.value.split('_')[1]) <= Formatters.FormatDateMonth(moment(cardSingle.detail.ends.split('T')[0]))
              if (thisMonth) {

                arrayMonthTemp.push(cardSingle)
              }

            }
          }
          // Status
          if (filterSingle.type === "STATUS") {
            arrayStatus = [arrayStatusTemp]
            console.log(Formatters.FormatDate(moment()))
            console.log(Formatters.FormatDate(moment(cardSingle.detail.ends)))
            console.log(Formatters.FormatDate(moment(cardSingle.detail.starts)))
            let today = Formatters.FormatDate(moment()) >= Formatters.FormatDate(moment(cardSingle.detail.ends)) ? "PAST" :
              Formatters.FormatDate(moment(cardSingle.detail.starts)) > Formatters.FormatDate(moment()) ? "FUTURE" :
                "PRESENT"

            if (filterSingle.value === today) {

              arrayStatusTemp.push(cardSingle)
            }
          }
          // Archievement
          if (filterSingle.type === "ARCHIEVEMENT") {
            arrayArchievement = [arrayArchievementTemp]
            let archievementState = cardSingle.detail.status === "INTERRUPTED" ? "INTERRUPTED" :
              cardSingle.detail.current_value >= cardSingle.detail.target_value ? "COMPLETED" :
                "NOTCOMPLETED"
            if (filterSingle.value === archievementState) {

              arrayArchievementTemp.push(cardSingle)
            }
          }

          // Creator
          if (filterSingle.type === "CREATOR") {
            arrayCreator = [arrayCreatorTemp]
            if (filterSingle.value === "USER_" + cardSingle.creator.id) {

              arrayCreatorTemp.push(cardSingle)
            }
          }


        })

      })



      arrayTargetCard = arrayLevel.concat(arrayKpi).concat(arrayDuration).concat(arrayStatus).concat(arrayYears).concat(arrayMonth).concat(arrayArchievement).concat(arrayCreator)



      var result = arrayTargetCard.shift().filter(function (targetCard) {

        return arrayTargetCard.every(function (a) {
          return a.indexOf(targetCard) !== -1;
        });
      });

      setCardsTargetFiltered(result)
    }
  }, [filterChosen]);

  // SORT BEFORE THE NEW
  const sortByNewTarget = (a, b) => {
    if (state.targets.new.filter(newTarget =>
      a.detail.id === newTarget)[0]) {
      return -1
    } else if (state.targets.new.filter(newTarget =>
      b.detail.id === newTarget)[0]) {
      return 1
    } else {
      return 0
    }

  }

  return (
    <div id="targetListContainer" className="flex-grow-1 px-3 px-md-4 ">
      <div className="d-flex justify-content-between mt-4 flex-wrap" >
        <h4 className="c-black font-size-20 font-weight-semibold  ms-0">{t("targetlist.title")}</h4>
        <ButtonComponent className="gradientButton px-4" onClick={() => gotoPage.push("/createnewtarget")}>{t("targetlist.addtargetbutton")}</ButtonComponent>
      </div>
      <div className="">
        {/* Css Show FilterTarget or  FilterTargetMobile */}
        <Filter
          filterArr={filterArr}
          getFilterChosen={setFilterChosen}
          filterChosen={filterChosen}
        />
        <FilterMobile
          filterArr={filterArr}
          getFilterChosen={setFilterChosen}
          filterChosen={filterChosen}
        />


        {loadImage ? <Loading /> :
          !cardsTargetFiltered || cardsTargetFiltered.length === 0 ?
            <EmptyTarget />
            : <div className="row px-2 align-items-end">
              {cardsTargetFiltered.sort(sortByNewTarget).map((target, i) =>

                <Card target={target} parametersFetch={parametersFetch} />
              )}
            </div>
        }
      </div>
    </div>
  );
}