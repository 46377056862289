import React, { useState, useEffect } from 'react';
import { Navbars, Sidebars, Footers } from '../commons'
import { Prompt } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {Info} from './Info'
import {Password} from './Password'
import './account.css';

export function Account() {
    const { t } = useTranslation();
    const [isBlocking, setIsBlocking] = useState(false);
    const [isBlockingInfo, setIsBlockingInfo] = useState(false);
    const [isBlockingPassword, setIsBlockingPassword] = useState(false);

    useEffect(() => {
        if (isBlockingPassword || isBlockingInfo) {
            setIsBlocking(true)
        } else {
            setIsBlocking(false)
        }
    }, [isBlockingPassword, isBlockingInfo])

    return (
        <>
            <div id="myAccountArea">
                <Navbars.NavbarBackPage />
                <div className="container-fluid container-xl ">
                    <div className="row">
                        <div className="col-md-4 col-xl-3 pt-3 py-md-3">
                            <Sidebars.SidebarUser active="myAccount" title={t("useraresidebar.myaccount")} />
                        </div>
                        <div className="col-md-8 col-xl-9">
                            <div id="myAccount" className="border-radius shadowDropMediumBlur bg-white my-3">
                                <div className="row py-5">
                                    <div className="col-xl-6 borderRightGrey ">
                                        <Info setIsBlockingInfo={setIsBlockingInfo} />
                                    </div>
                                    <div className="col-xl-6 pt-4 pt-xl-0">
                                        <Password setIsBlockingPassword={setIsBlockingPassword} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <Footers.FooterPoweredBy />
            </div>
            <Prompt
                when={isBlocking}
                message={location => `${t("userarea.prompt")}`
                }
            />
        </>
    );
}