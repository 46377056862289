import React from 'react';
import { Formatters } from '../../../../commons'
import { useTranslation } from 'react-i18next';
import './cardyouronline.css'

export function CardYourOnline({ profitOnline, affluenceOnline, transactionsOnline }) {
    const { t, i18n } = useTranslation();
    return (
        <div id="CardYourOnline" className="h-100" >

            <div className="bgCardYourOnline bg-white shadowDropMediumBlur border-radius h-100 d-flex flex-column flex-wrap p-4">

                <h3 className="c-black font-weight-semibold mb-0 font-size-22 mt-1">
                    {t("cardyouronline.inyour")}<span className="c-purple font-weight-semibold font-size-22 ms-2">{t("cardyouronline.onlinestore")}</span>
                </h3>

                <div className="d-flex flex-column align-items-start mb-2 mt-3" >
                    <h4 className="c-purple font-weight-bold font-size-14 mb-0 text-uppercase">{t("cardyouronline.profitforclient")}</h4>
                    <p className="c-black font-weight-semibold font-size-25 mb-0">
                        <Formatters.NumberFormatter value={profitOnline[0].value} currency /> €
                    </p>
                </div>

                <div className="d-flex align-items-start mt-3" >
                    <div className="d-flex flex-column m-0" >
                        <h4 className="c-purple font-weight-bold font-size-14 mb-0 text-uppercase">{t("cardyouronline.transactions")}</h4>
                        <p className="c-black font-weight-semibold font-size-25 mb-0">
                            <Formatters.NumberFormatter value={transactionsOnline[0].value} />
                        </p>
                    </div>
                    <span className="borderGrey ms-4 me-4"></span>
                    <div className="d-flex flex-column m-0" >
                        <h4 className="c-purple font-weight-bold font-size-14 mb-0 text-uppercase">{t("cardyouronline.uniquevisitors")}</h4>
                        <p className="c-black font-weight-semibold font-size-25 mb-0">
                            <Formatters.NumberFormatter value={affluenceOnline[0].value} />
                        </p>
                    </div>
                </div>
                <div className="d-flex flex-column   mt-3" >

                    <p className="c-purple font-weight-bold font-size-16 mb-0 ">

                        {transactionsOnline[0].value !== 0 && affluenceOnline[0].value !== 0 ?
                            <> <Formatters.NumberFormatter value={transactionsOnline[0].value / affluenceOnline[0].value * 100} /> %</>
                            : "-"
                        }

                        <span className="c-black font-weight-regular ms-2 mb-0">{t("cardyouronline.peopledescription")}</span>
                    </p>
                </div>

            </div>

        </div>
    );
}