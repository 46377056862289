import React from 'react';
import { Navbars, Footers } from '../commons';
import { Form } from './Form';


export function TargetForm() {
  return (
    <div>
      <div id="targetWizard"
        className=" d-flex flex-column ">
        <Navbars.NavbarBackPage />
        <Form />

        
        <div className="me-3 me-md-2 ">
          <Footers.FooterPoweredBy />


        </div>
      </div>
    </div>
  );
}