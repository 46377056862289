import React, { useEffect, useState } from 'react';
import './card.css'
import IconTiffanyStore from "../../img/IconTiffanyBigStore.png"
import IconEcommerce from "../../img/IconEcommerce.png"
import IconGreyCalendar from "../../img/IconGreyCalendar.png"
import { LabelTarget, DottedMenu, InfoTooltip, Formatters } from "../commons"
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useAppState } from '../../store/AppState';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export function Card({ target, parametersFetch }) {
  const [state] = useAppState();
  const { t } = useTranslation();
  const [name, setName] = useState("");

  let isFind = false;

  const findLevel = (kind, id) => {
    let levelToFind = kind + "-" + id
    let nameTree = ""
    console.log(levelToFind)
    if (state.tree.full) {
      if (state.tree.full.identifier === levelToFind) {
        console.log(state.tree.full.name)
        nameTree = state.tree.full.name
      } else if (state.tree.full.haschild && state.tree.full.identifier !== levelToFind) {
        nameTree = findLevelChild(state.tree.full.haschild, levelToFind)
        console.log("levelToFind")
      }
      setName(nameTree);

    }
  }

  const findLevelChild = (levelChild, levelToFind) => {
    let nameTreeChild = ""
    levelChild.forEach((treeChild) => {
      if (!isFind) {
        if (treeChild.identifier === levelToFind) {
          nameTreeChild = treeChild.name
          isFind = true
        } else if (treeChild.identifier !== levelToFind && treeChild.haschild) {
          nameTreeChild = findLevelChild(treeChild.haschild, levelToFind)
        }
      } else {
        return
      }
    })
    return nameTreeChild;
  }

  useEffect(() => {
    findLevel(target.detail.config.destination.kind, target.detail.config.destination.id);
  }, [target.detail.config.destination.kind, target.detail.config.destination.id])

  return (
    <div className="targetCardList col-md-6 col-lg-4 col-xxl-3 py-2 " >
      <div className={!state.targets.loading && state.targets.new.find((id) => id === target.detail.id) ? "newTarget  " : "mb-1 "} >
        {!state.targets.loading && state.targets.new.find((id) => id === target.detail.id) &&
          <p className="c-white font-size-15 font-weight-bold text-uppercase mb-0 ms-1 text-center">{t(`cardtarget.new`)}</p>
        }
        <div className="targetCard bg-white shadowDropMediumBlur border-radius  h-100 p-3 d-flex flex-column justify-content-start" >
          <div className="d-flex align-items-center justify-content-between mb-1" >
            <LabelTarget target={target} />
            {target.detail.status !== 'INTERRUPTED' && target.creator.id === state.account.data.user.id &&
              moment(target.detail.ends).toDate() >= moment().toDate() ?
              <div className="" >
                <DottedMenu
                  idTarget={target.detail.id}
                  parametersFetch={parametersFetch}
                  nametarget={target.detail.kind + (target.type.kind + target.type.subkind).toLowerCase()}
                />
              </div>
              : target.detail.status !== 'INTERRUPTED' && target.creator.id !== state.account.data.user.id &&
              moment(target.detail.ends).toDate() >= moment().toDate() &&
              <InfoTooltip />
            }
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <p className="c-black font-size-18 font-weight-bold m-0">
              {name}</p>
            <span className="circleName d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
  m-0  font-size-13 ms-2">
              {name.substring(0, 2)}
            </span>
          </div>
          <div className="d-flex align-items-center mb-2">
            {target.type.kind === "PHYSICAL" ? <><img src={IconTiffanyStore} alt="" className="me-2 img-icon30" />
              <p className="c-tiffany font-size-18 font-weight-bold m-0 text-uppercase">{t(`cardtarget.${(target.type.kind + target.type.subkind).toLowerCase()}`)}</p></>
              : target.type.kind === "ONLINE" && <><img src={IconEcommerce} alt="" className="me-2 img-icon35" />
                <p className="c-purple font-size-18 font-weight-bold m-0 text-uppercase">{t(`cardtarget.${(target.type.kind + target.type.subkind).toLowerCase()}`)}</p></>}
          </div>
          <div className="d-flex align-items-end mb-2">
            <p className="c-black font-size-18 font-weight-bold mb-0">{target.detail.current_value}</p>
            <p className="c-medium-grey font-size-16 font-weight-regular mb-0 ms-1">/{target.detail.target_value}</p>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-start">
            <p className="c-medium-grey font-size-16 font-weight-regular text-uppercase m-0 mb-2">{t(`cardtarget.createdby`)}</p>
            {target.creator.id === state.account.data.user.id ?
              <div className="d-flex">
                <p className="c-black font-size-16 font-weight-semibold m-0">{t(`cardtarget.you`)}</p>
                <span className="circleName-grad-s d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
 m-0  font-size-11 ms-2">{target.creator.name.substring(0, 1) + target.creator.surname.substring(0, 1)}</span>
              </div> :
              <div className="d-flex">
                <p className="c-black font-size-16 font-weight-semibold m-0">{target.creator.name + " " + target.creator.surname}</p>
                <span className="circleName-s d-flex justify-content-center align-items-center text-uppercase c-white font-weight-semibold
 m-0  font-size-11 ms-2">
                  {target.creator.name.substring(0, 1) + target.creator.surname.substring(0, 1)}
                </span>
              </div>
            }
          </div>
          <div className="d-flex mt-3 mb-3">
            <img src={IconGreyCalendar} alt="" className="me-2 img-icon20" />
            <p className="c-black font-size-17 mb-0 font-weight-semibold  text-capitalize">{t(`cardtarget.${target.detail.kind.toLowerCase()}`)}</p>
          </div>
          <div className="d-flex flex-row ">
            <div className="d-flex flex-column col-4">
              <p className="c-medium-grey mt-0 mb-0 text-uppercase font-weight-bold font-size-14">{t(`cardtarget.start`)}</p>
              <p className="c-black mt-0  font-weight-regular font-size-14 mb-0">{Formatters.FormatDateLocale(moment(target.detail.starts))}</p>
            </div>
            <div className="d-flex flex-column align-items-center ps-3 col-4">
              <p className="c-medium-grey mt-0 mb-0 text-uppercase font-size-14 font-weight-bold">{t(`cardtarget.end`)}</p>
              <p className="c-black mt-0  font-weight-regular font-size-14 mb-0">{Formatters.FormatDateLocale(moment(target.detail.ends))}</p>
            </div>
            {target.detail.status === "INTERRUPTED" ?
              <div className="d-flex flex-column align-items-end ps-3 col-4">
                <p className="c-medium-grey mt-0 mb-0 text-uppercase font-size-14 font-weight-bold">{t(`cardtarget.interruption`)}</p>
                <p className="c-black mt-0  font-weight-regular font-size-14 mb-0">{Formatters.FormatDateLocale(moment(target.detail.updated_at))}</p>
              </div>
              :
              target.detail.status !== "INTERRUPTED" && 
              Math.ceil((moment(target.detail.ends).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24)) > 0 &&
              moment(target.detail.starts).toDate() <= moment().toDate()  ?
                <div className="d-flex flex-column align-items-end ps-3 col-4">


                  <p className="c-medium-grey mt-0 mb-0 text-uppercase font-size-14 font-weight-bold">{t(`cardtarget.remaining`)}</p>
                  <p className="c-black mt-0  font-weight-regular font-size-14 mb-0">{
                    Math.ceil((moment(target.detail.ends).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24))
                  }
                    {Math.ceil((moment(target.detail.ends).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24)) === 1 ?
                      <span className="ms-1">{t(`cardtarget.d`)}</span> :
                      <span className="ms-1">{t(`cardtarget.ds`)}</span>
                    }
                  </p>
                </div> :
                 null}
          </div>

          {Formatters.FormatDate(moment(target.detail.starts)) <= Formatters.FormatDate(moment()) &&
            <>
              <div className="d-flex align-items-end justify-content-between mt-3 mb-1">
                <p className="c-medium-grey font-size-11 text-uppercase font-weight-regular align-self-start mb-0">{t(`cardtarget.completion`)}</p>
                <p className="c-black font-size-16 font-weight-bold mb-0 ms-1">{Math.trunc((target.detail.current_value / (target.detail.target_value!==0?target.detail.target_value:1)) * 100)} %</p>
              
              </div>
              <ProgressBar now={(target.detail.current_value / (target.detail.target_value!==0?target.detail.target_value:1)) * 100} className={target.detail.status === "INTERRUPTED" ? "progressGrey" : "progressGrad"} />

            </>}

          {target.type.subkind === "CONVERSION" &&
            Formatters.FormatDate(moment(target.detail.starts)) <= Formatters.FormatDate(moment()) &&
            Formatters.FormatDate(moment(target.detail.ends)) >= Formatters.FormatDate(moment()) &&
            <div className=" mt-1 mb-2">
            </div>
          }


          {Formatters.FormatDate(moment(target.detail.starts)) > Formatters.FormatDate(moment())
            && target.detail.status !== "INTERRUPTED"
            // &&  target.type.subkind !== "CONVERSION" 
            ? <div className="d-flex flex-column  mt-3 mb-2">
              <p className="c-medium-grey font-size-11 text-uppercase font-weight-regular align-self-start mb-0">{t(`cardtarget.completion`)}</p>
              <p className="c-black font-size-15 font-weight-regular mt-1 mb-0 ms-0">{t(`cardtarget.targetstart`)}<span className={`ms-1 font-weight-semibold ${target.type.kind === "PHYSICAL" ? "c-tiffany" : "c-purple"}`}>
                {Math.ceil((moment(target.detail.starts).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24))}{" "}{t(`cardtarget.days`)}</span></p>
            </div> :
            Formatters.FormatDate(moment(target.detail.starts)) < Formatters.FormatDate(moment()) &&
            <div className="d-flex flex-column  mt-0 mb-2">
            </div>
          }


        </div>
      </div>
    </div>
  );
}