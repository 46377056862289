import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useSignIn } from "../../apis/fetch";
import { useTranslation } from 'react-i18next';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

import { Alerts, Inputs } from "../commons";
import { verifyResponse } from '../../apis/response';

export function Form() {
    const { t, i18n } = useTranslation();
    const gotoPage = useHistory();
    const signInResponse = useSignIn();

    const [enableButton, setEnableButton] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [infoAlert, setInfoAlert] = useState({ type: "", title: "alert.titlewrongcredentials", message: "alert.descriptionwrongcredentials" });
    const [showAlert, setShowAlert] = useState("hidden");

    useEffect(() => {
        if (signInResponse.content.isLoading === "FETCHED") {
            if (verifyResponse(signInResponse, 200)) {
                setShowAlert("hidden");
                if (signInResponse.content.response.data.isFirstLogin) {
                    gotoPage.push(`/resetpassword?hashcode=${signInResponse.content.response.data.token}`);
                } else {
                    sessionStorage.setItem("sessionToken", signInResponse.content.response.data.token);
                    sessionStorage.removeItem('multishopItemSelected');
                    gotoPage.push("/dashboard");
                }
            } else {
                setInfoAlert({ type: "error", title: "alert.titlewrongcredentials", message: "alert.descriptionwrongcredentials" });
                setShowAlert("visible");
            }
            signInResponse.sendRequest({ loading: "NOT FETCHING" });
        }
    }, [signInResponse, t])

    useEffect(() => {
        if (email.length >= 1 && password.length >= 1) {
            setEnableButton(false)
        } else setEnableButton(true)
    }, [email, password])

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            signInResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email: email, password: password, language: i18n.language }) })
        }
    }

    return (
        <div id="LoginContent" className="border-radius bg-white p-5 pb-4 min-height-520 shadowDropMediumBlur">
            <div className="px-md-5 px-2">
                <h3 className="c-gradient font-size-24 font-weight-bold m-auto mb-2 align-content-center">
                    {t("login.signin")}
                </h3>
                <div className="d-flex flex-column flex-wrap align-content-center justify-content-center my-1">
                    <div className="mt-4 mb-2 align-self-center w-100">
                        <h4 className="c-dark-grey font-size-24 font-weight-bold  mt-4 mb-2">{t("login.welcome")}</h4>
                        <Inputs.InputText
                            handleKeyPress={handleKeyPress}
                            placeholder={t("login.placeholderName")} onChange={setEmail} />
                        <Inputs.InputPassword
                            handleKeyPress={handleKeyPress}
                            value={password} setPassword={setPassword} />
                    </div>
                    <div >
                        <Alerts.Alert info={infoAlert} show={showAlert} />
                    </div>
                    <ButtonComponent
                        className="gradientButton mb-1 mt-2 font-weight-bold "
                        disabled={enableButton}
                        onClick={() => signInResponse.sendRequest({ loading: "FETCHING", body: JSON.stringify({ email: email, password: password, language: i18n.language }) })}
                    >
                        {t("login.buttonsignin")}
                    </ButtonComponent>
                    <h4 className="c-dark-grey text-center font-size-14 font-weight-regular mb-0 mt-5">
                        {t("login.forgotpassword")}
                        <div className="c-purple font-size-14 font-weight-semibold pointer ms-2 text-decoration-none" onClick={() => gotoPage.push("/forgotpassword")} >
                            {t("login.clickhere")}
                        </div>
                    </h4>
                </div>
            </div>
        </div>
    );
}
