import React from 'react';
import { cleanHeadersSingleshop, treeSelected } from '../../../actions';
import { useAppState } from '../../../store/AppState';
import "./subshops.css"
import { ResponsiveTable } from '../../commons/tables/ResponsiveTable';
import { useHistory } from 'react-router';

export function SubShops({ data }) {
    const [state, dispatch] = useAppState();
    const gotoPage = useHistory()

    const filters = {
        fields: [
            'name',
            'physical_sales',
            'physical_conversion',
            'physical_profit',
            'physical_affluence',
            'online_sales',
            'online_conversion',
            'online_profit'
        ],
        orders: [
            'desc',
            'asc'
        ]
    }

    const valueToCompare = (elem, field) => {
        return field === 'name'
            ? elem[field]
            : elem.kpi.filter(elem => elem.kind === field.split('_')[0].toUpperCase() && elem.subkind === field.split('_')[1].toUpperCase())[0].value;
    }

    const gotoDashboard = (subGroup) => {
        dispatch(treeSelected({ dispatch, data: state.tree.selected.haschild.filter(elem => elem.id === subGroup.id && elem.kind === subGroup.kind)[0], full: state.tree.full }))
        dispatch(cleanHeadersSingleshop());
    }

    return (
        <ResponsiveTable
            id={'table-sub-group'}
            filters={filters}
            data={data}
            valueToCompare={valueToCompare}
            click={(subGroup) => gotoDashboard(subGroup)}
        />
    );

}