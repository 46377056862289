import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';

export const PasswordCheck = ({ password, setIsPasswordValid }) => {
    const { t } = useTranslation();
    const [eightCharacters, setEightCharacters] = useState(false);
    const [uppercase, setUppercase] = useState(false);
    const [lowercase, setLowercase] = useState(false);
    const [number, setNumber] = useState(false);

    useEffect(() => {
        if (eightCharacters && uppercase && lowercase && number) {
            setIsPasswordValid(true);
        } else setIsPasswordValid(false);
    }, [eightCharacters, uppercase, lowercase, number, setIsPasswordValid])

    useEffect(() => {
        if (password.length >= 8) {
            setEightCharacters(true);
        } else setEightCharacters(false);

        if (/.*[a-z]/.test(password)) {
            setLowercase(true);
        } else setLowercase(false);

        if (/.*[A-Z]/.test(password)) {
            setUppercase(true);
        } else setUppercase(false);

        if (/.*[0-9]/.test(password)) {
            setNumber(true);
        } else setNumber(false);
    }, [password])

    return (
        <>
            <ul id="passwordCheck" className="text-start ps-0 ">
                <li className=" font-size-13 font-weight-regular list-style-none " style={eightCharacters ? { textDecoration: "line-through" } : null}>- {t("resetpassword.eightcharacters")}</li>
                <li className="font-size-13 font-weight-regular list-style-none" style={number ? { textDecoration: "line-through" } : null}>- {t("resetpassword.anumber")}</li>
                <li className=" font-size-13 font-weight-regular list-style-none" style={uppercase ? { textDecoration: "line-through" } : null}>- {t("resetpassword.anuppercase")}</li>
                <li className=" font-size-13 font-weight-regular list-style-none" style={lowercase ? { textDecoration: "line-through" } : null}>- {t("resetpassword.alowercase")}</li>
            </ul>
        </>
    );
}