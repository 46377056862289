import React, { useEffect, useState } from 'react';
import { Formatters } from '../../../commons'
import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    Inject,
    AccumulationTooltip
} from '@syncfusion/ej2-react-charts';
import "./anglecharts.css"
import { useTranslation } from 'react-i18next';


export function AngleCharts({ type, datacharts, total }) {
    const { t, i18n } = useTranslation();



    // TOOLTIP
    const tooltipRender = (args) => {

        args.headerText = args.point.x;
        args.text = args.point.y.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + "€";
        args.textStyle.color = '#fff';
    }


    const tooltip = {
        enable: true,
        fill: 'var(--medium-grey)'
    }


    // COLOR FOR CHART
    function onPointRender(args) {

        if (type === "physical") {
            if (args.point.index === 0) {
                args.fill = '#97EAEA';
            }
            else if (args.point.index === 1) {
                args.fill = '#01B5BC';
            } else if (args.point.index === 2) {
                args.fill = '#4BB6C9';
            }
            else if (args.point.index === 3) {
                args.fill = '#007996';
            }
        } else if (type === "online") {
            if (args.point.index === 0) {
                args.fill = '#E1C4FF';
            }
            else if (args.point.index === 1) {
                args.fill = '#C093F4';
            } else if (args.point.index === 2) {
                args.fill = '#A05CFD';
            }
            else if (args.point.index === 3) {
                args.fill = '#7936F7';

            }
        }
    }


    



    return (
        <div id={'AngleCharts' + type} className="AngleCharts align-self-center">
       
            <div className="chartDimension position-relative ">
                <AccumulationChartComponent
                    id={'AngleCharts' + type}
                     width='420'
                    pointRender={onPointRender}
                    enableBorderOnMouseMove={false}
                    tooltip={tooltip}
                    tooltipRender={tooltipRender}
                >
                    <Inject services={[AccumulationTooltip]} />
                    <AccumulationSeriesCollectionDirective>
                        <AccumulationSeriesDirective
                            dataSource={datacharts}
                            xName='name'
                            yName='value'
                            startAngle='270'
                            endAngle='90'
                            // dataLabel={{ visible: false, name: 'text', position: 'Outside' }}
                            innerRadius='60%'
                        >
                        </AccumulationSeriesDirective>
                    </AccumulationSeriesCollectionDirective>
                </AccumulationChartComponent>
            </div>
            <div className="labelTotalMyCashDeskCharts d-flex flex-column align-items-center mt-0" >
                <span className="titleAngleLabel c-dark-grey font-weight-bold font-size-16 text-uppercase mb-0">
                {t(`anglecharts.total`)}</span>
                <p className="c-black  font-weight-regular font-size-16 mb-0 ">
                    <Formatters.NumberFormatter value={total} currency/> €

                </p>
            </div>

        </div>


    );
}