import React, { useState, useEffect } from 'react';
import { Navbars, Sidebars, Footers } from '../commons'
import { Prompt } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// import './account.css';

export function Privacy() {
    const { t } = useTranslation();
    const [isBlocking, setIsBlocking] = useState(false);
    const [isBlockingInfo, setIsBlockingInfo] = useState(false);
    const [isBlockingPassword, setIsBlockingPassword] = useState(false);

    useEffect(() => {
        if (isBlockingPassword || isBlockingInfo) {
            setIsBlocking(true)
        } else {
            setIsBlocking(false)
        }
    }, [isBlockingPassword, isBlockingInfo])

    return (
        <>
            <div id="privacyArea">
                <Navbars.NavbarBackPage />
                <div className="container-fluid container-xl ">
                    <div className="row">
                        <div className="col-md-4 col-xl-3 pt-3 py-md-3">
                            <Sidebars.SidebarUser active="privacy" title={t("useraresidebar.privacy")} />
                        </div>
                        <div className="col-md-8 col-xl-9  pt-3 py-md-3">
                            <div id="myAccount" className="border-radius shadowDropMediumBlur bg-white">
                                <iframe title="Privacy" src={t("privacy.link")} className="py-5 px-4 w-100" height={600}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <Footers.FooterPoweredBy />
                        </div>
                    </div>
                </div>

            </div>

            <Prompt
                when={isBlocking}
                message={location => `${t("userarea.prompt")}`
                }
            />
        </>
    );
}