import * as Pages from '../components/pages';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import { Private } from './layouts/Private';
import { zEcustomization, zElocale, zEprefill } from '../middleware/zE';
import { useEffect } from 'react';
import { useAppState } from '../store/AppState';
import { useTranslation } from 'react-i18next';
import { Public } from './layouts/Public';

export function AppContainer() {
    const [state] = useAppState();
    const { i18n } = useTranslation();

    useEffect(() => {
        zElocale(i18n.language);
        zEprefill({
            name: `${state.account.data.user?.name} ${state.account.data.user?.surname}`,
            email: state.account.data.user?.email
        });
        zEcustomization();
    }, [state.account.data, i18n.language])

    /* useEffect(() => zEhide(), []); */

    return (
        <Router>
            <Switch>
                <Route path='/dashboard'>
                    <Private>
                        <Pages.Dashboard />
                    </Private>
                </Route>
                <Route path='/myaccount'>
                    <Private>
                        <Pages.Account />
                    </Private>
                </Route>
                <Route path='/privacy'>
                    <Private>
                        <Pages.Privacy />
                    </Private>
                </Route>
                <Route path='/conditionsofsale'>
                    <Private>
                        <Pages.ConditionsOfSale />
                    </Private>
                </Route>
                <Route path='/billing'>
                    <Private>
                        <Pages.Billing />
                    </Private>
                </Route>
                <Route path='/mailchanged'>
                    <Public>
                        <Pages.MailChanged />
                    </Public>
                </Route>
                <Route path='/newpassword'>
                    <Public>
                        <Pages.MailNewPassword />
                    </Public>
                </Route>
                <Route path='/forgotpassword'>
                    <Public>
                        <Pages.ForgotPassword />
                    </Public>
                </Route>
                <Route path='/passwordchanged'>
                    <Public>
                        <Pages.PasswordChanged />
                    </Public>
                </Route>
                <Route path='/resetpassword'>
                    <Public>
                        <Pages.ResetPassword />
                    </Public>
                </Route>
                <Route path='/login'>
                    <Public>
                        <Pages.Login />
                    </Public>
                </Route>
                <Route path='/createnewtarget'>
                    <Private>
                        <Pages.TargetForm />
                    </Private>
                </Route>
                <Route path='/targetlist'>
                    <Private>
                        <Pages.TargetList />
                    </Private>
                </Route>
                <Route path='*'>
                    <Public>
                        <Pages.Login />
                    </Public>
                </Route>
            </Switch>
        </Router>
    )
}