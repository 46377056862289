import React from "react";
import { useTranslation } from "react-i18next";
import "./datedurationradiotarget.css";

export const DateDurationRadioTarget = ({ id, disabled,typeDate,setTypeDate }) => {
    const { t } = useTranslation();


    // const setTypeDate = (typeDate) => {
    //     dispatch(setDateAction({
    //         typeDate: typeDate,
    //         start: date.start,
    //         end: date.end,
    //     }))
    // }

    return (
        <div id="dateInputGroup" className="my-2 col">
            <div
                style={{ opacity: disabled ? 0.5 : 1 }}
                className="stv-radio-buttons-wrapper border-radius d-flex"
            >
                <div className="buttonRadio">
                    <input
                        disabled={disabled}
                        type="radio"
                        className="stv-radio-button"
                        name={id + "radioDateSelect"}
                        checked={typeDate === "day" ? true : false}
                        onChange={(e) => setTypeDate(e.target.value)}
                        value="day"
                        id={id + "ButtonDay"} />
                    <label htmlFor={id + "ButtonDay"}>{t("datepicker.day")}</label>
                </div>
                <div className="buttonRadio">
                    <input
                        disabled={disabled}
                        type="radio"
                        className="stv-radio-button"
                        name={id + "radioDateSelect"}
                        checked={typeDate === "week" ? true : false}
                        onChange={(e) => setTypeDate(e.target.value)}
                        value="week"
                        id={id + "ButtonWeek"} />
                    <label htmlFor={id + "ButtonWeek"}>{t("datepicker.week")}</label>
                </div>
                <div className="buttonRadio">
                    <input
                        disabled={disabled}
                        type="radio"
                        className="stv-radio-button"
                        name={id + "radioDateSelect"}
                        checked={typeDate === "month" ? true : false}
                        onChange={(e) => setTypeDate(e.target.value)}
                        value="month"
                        id={id + "ButtonMonth"} />
                    <label htmlFor={id + "ButtonMonth"}>{t("datepicker.month")}</label>
                </div>
                <div className="buttonRadio">
                    <input
                        disabled={disabled}
                        type="radio"
                        className="stv-radio-button"
                        name={id + "radioDateSelect"}
                        checked={typeDate === "year" ? true : false}
                        onChange={(e) => setTypeDate(e.target.value)}
                        value="year"
                        id={id + "ButtonYear"} />
                    <label htmlFor={id + "ButtonYear"}>{t("datepicker.year")}</label>
                </div>
            </div>
        </div>
    );
}