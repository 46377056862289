import React, { useEffect, useState } from 'react';
import IconGradTarget from "../../../../img/IconGradTarget.png"
import { TargetCardOverview } from "./cards/TargetCardOverview"
import { useTranslation } from 'react-i18next';
// COMPONENTS
import { useAppState } from '../../../../store/AppState';
import { Formatters } from '../../../commons';
import moment from 'moment';




export function MyTargetOverview({ parametersFetch }) {
    const { t, i18n } = useTranslation();
    const [{ tree, targets, date }] = useAppState();

    const [targetFiltered, setTargetFilteredT] = useState([]);




    useEffect(() => {

        let filtered = targets.list && targets.list.filter((target, index) =>

            target.detail.kind === date.typeDate.toUpperCase()
            && date.start <= target.detail.ends &&
            date.end >= target.detail.starts
            && target.detail.config.destination.id === tree.selected.id
            && target.detail.config.destination.id === tree.selected.id
            && target.detail.status !== 'INTERRUPTED'
        )
        setTargetFilteredT(filtered)

    }, [targets.list]);




    return (
        <div className="bg-grad-op25 border-radius py-2">
            <div className="d-flex flex-row align-items-start">
                <img src={IconGradTarget} alt="" className="img-icon90 img-sm-icon40" />
                <div className="d-flex flex-column mb-1">
                    <h2 className="c-black font-size-20 font-weight-semibold mb-0 align-self-start mb-2">{t(`mytargetoverview.title${date.typeDate}`)}</h2>
                    <div className="d-flex flex-row flex-wrap ">
                        <div className="d-flex flex-column me-3 mb-2">
                            <h2 className="c-green font-size-16 font-size-sm-14 font-weight-bold mb-0 text-uppercase align-self-start">{t("mytargetoverview.start")}</h2>
                            <h2 className="c-black font-size-16 font-size-sm-14 font-weight-regular mb-0 align-self-start">{date.start && Formatters.FormatDateLocale(moment(date.start))}</h2>
                        </div>
                        <div className="d-flex flex-column me-3 mb-2">
                            <h2 className="c-tiffany font-size-16 font-weight-bold font-size-sm-14 mb-0 text-uppercase align-self-center align-self-md-start">{t("mytargetoverview.end")}</h2>
                            <h2 className="c-black font-size-16 font-weight-regular font-size-sm-14 mb-0 align-self-center align-self-md-start">{date.end && Formatters.FormatDateLocale(moment(date.end))}</h2>
                        </div>
                        {Math.ceil((moment(date.end).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24)) >= 1 &&
                            <div className="d-flex flex-column  mb-2">
                                <h2 className="c-purple font-size-16 font-size-sm-14 font-weight-bold mb-0 text-uppercase  align-self-end align-self-md-start">{t("mytargetoverview.remaining")}</h2>
                                <h2 className="c-black font-size-16 font-size-sm-14 font-weight-regular mb-0 align-self-end align-self-md-start">{Math.ceil((moment(date.end).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24))
                                }
                                    {Math.ceil((moment(date.end).toDate() - moment().toDate()) / (1000 * 60 * 60 * 24)) === 1 ?
                                        <span className="ms-1">{t("mytargetoverview.day")}</span>
                                        : <span className="ms-1">{t("mytargetoverview.days")}</span>}
                                </h2>
                            </div>}
                        
                    </div>
                </div>
            </div>


            <div className="d-flex flex-row flex-wrap p-md-3 ">
                {targetFiltered.length > 0 ? targetFiltered.map(function (target, i) {



                    let dimension = targetFiltered.length === 1 ? "col-lg-12" :
                        targetFiltered.length === 2 || targetFiltered.length === 4 ? "col-lg-6" :
                            "col-lg-4"

                    return <TargetCardOverview
                        dimension={dimension}
                        target={target}
                        parametersFetch={parametersFetch}
                    />
                }) :
                    <div className="d-flex justify-content-center p-3 w-100 "><p className="c-black font-size-21 font-weight-bold text-center">{t("mytargetoverview.notarget")}</p></div>
                }


            </div>

        </div>
    );
}
