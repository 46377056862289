import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import flagEng from "../../../img/eng.png";
import flagFr from "../../../img/fr.png";
import flagIta from "../../../img/ita.png";
import "./languageselectorfooter.css";

export const LanguageSelectorFooter = () => {
  const [imageActive, setImageActive] = useState("");
  const [langActive, setLangActive] = useState("");

  const { t, i18n } = useTranslation();

  const languages = [
    { name: "fr", flag: flagFr },
    { name: "it", flag: flagIta },
    { name: "en", flag: flagEng }
  ];

  useEffect(() => {
    if (i18next.language) {
      changeLang(i18n.language.split("-")[0])
    }
  }, [])

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setImageActive(selectActiveFlag(lang));
    if (lang === "fr" || lang === "it"||lang === "en") {
      setLangActive(lang)
    }
    else { setLangActive("fr") }
  }

  const handleClick = (lang) => {
    changeLang(lang);

    // const data = new FormData();
    // data.append("language", lang);
    // setLanguage(data);
  }

  const selectActiveFlag = (langActive) => {
    switch (langActive) {
      case "it":
        return flagIta;
      case "fr":
        return flagFr;
      case "en":
        return flagEng;
      default:
        return flagFr;
    }
  }

  return (
    <div id="languageSelectorFooter" className="position-relative ">
      <div className="lang-list">
        <div className=" bg-white lang-list-menu shadowDropMediumBlur">
          {languages.map((language) => {
            return (

              <button
                id={language.name}
                key={language.name}
                className="lang-item w-100"
                onClick={() => handleClick(language.name)}
              >
                <img alt="" className="img-icon30 mt-2 " src={language.flag} />
              </button>

            );
          })}
        </div>
        {languages.map((language) => {
          return (
            langActive === language.name ?
              <button
                id={language.name}
                key={language.name}
                className="lang-item active  m-0"
              >
                <img alt="" className="img-icon30 mt-2" src={language.flag} />
              </button>
              : null
          );
        })}
      </div>
    </div>
  );
}
