import React from 'react';
import './breadcrumb.css';
import { useAppState } from '../../../store/AppState'
import { treeSelected } from '../../../actions';
import { useHistory } from 'react-router';

export const Breadcrumb = () => {
  const [state, dispatch] = useAppState();
  const gotoPage = useHistory();

  const gotoItemTree = (e) => {
    const itemSelected = state.tree.breadcrumb.find(
      (element) => element.id === e.target.id
    );
    dispatch(treeSelected({ dispatch, data: itemSelected.tree, full: state.tree.full }));
  };

  return (
    <div id='breadcrumb' className='px-4 d-none d-sm-flex'>
      {state.tree.breadcrumb.map((child, index) => {
        return index === 0 ? (
          <span
            id={child.id}
            key={index}
            className=' font-size-16 pointer hoverbreadcrumb mb-0'
            onClick={(e) => gotoItemTree(e)}
          >
            {child.name}
          </span>
        ) : (
          <p id={child.id} key={index} className='d-flex align-items-end mb-0'>
            <span className='e-chevron-right-03 e-icons font-size-20 mx-1'></span>
            <span
              id={child.id}
              className=' font-size-16 hoverbreadcrumb pointer transition mb-0'
              onClick={(e) => gotoItemTree(e)}
            >
              {child.name}
            </span>
          </p>
        );
      })}
    </div>
  );
};
