import React from 'react';
import "./circlename.css"

export const CircleName = ({ name }) => {
  return (
    <div id="circleName" className="circleName-m">
      <span className=" text-uppercase c-white font-weight-regular m-0  font-size-17 ">
        {name}
      </span>
    </div>
  );
}
