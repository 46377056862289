import React, { useState, useEffect } from 'react';
import { useChangePassword } from '../../apis/fetch';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { useTranslation } from 'react-i18next';
import { Alerts, Inputs, Checks } from "../commons";
import "./info.css"
import { verifyResponse } from '../../apis/response';

export function Password({setIsBlockingPassword}) {
  const { t } = useTranslation();
  const changePasswordResponse = useChangePassword();

  const [infoAlert, setInfoAlert] = useState({type: "", title: "", message: ""});
  const [showAlert, setShowAlert] = useState("hidden");
  const [enableButton, setEnableButton] = useState(true);
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    if(changePasswordResponse.content.isLoading === "FETCHED") {
      if(verifyResponse(changePasswordResponse, 200)) {
        setInfoAlert({type: "success", title: "alert.savesuccesstitle", message: "alert.savesuccessdescription"});
        setShowAlert("visible");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        setInfoAlert({type: "error", title:"alert.titlewrongcredentials", message: "alert.descriptionwrongcurrentpassword"});
        setShowAlert("visible");
      }
      changePasswordResponse.sendRequest({loading: "NOT FETCHING"});
    }
  }, [changePasswordResponse, t])

  useEffect(() => {
    if(isPasswordValid && oldPassword.length>0 && newPassword.length>0 && confirmPassword.length>0) {
        setEnableButton(false);
    } else setEnableButton(true);

    if(newPassword.length>0 || oldPassword.length>0 || confirmPassword.length>0){
      setIsBlockingPassword(true)
    } else {
      setIsBlockingPassword(false)
    }
  }, [isPasswordValid, oldPassword, newPassword, confirmPassword, setIsBlockingPassword])

  const click = () => {
    if(newPassword === confirmPassword) {
      changePasswordResponse.sendRequest({loading: "FETCHING", body: JSON.stringify({password: oldPassword, new_password: newPassword })})
    } else {
        setInfoAlert({type: "error", title: "alert.titlewrongcredentials", message: "alert.descriptionpasswordsdifferents"});
        setShowAlert("visible");
    }
  }

  return (
    <div id="menagePassword" className="h-100">
      <div className="containerPersonalInfo h-100 d-flex flex-column justify-content-between h-100 px-4 px-md-5">
        <h2 className="c-black font-size-21 font-weight-bold mb-4">{t("userarea.passwordmanagement")}</h2>
        <div className=" align-self-center w-100 h-100 d-flex flex-column justify-content-between">
          <div className="mt-1 mb-3">
            <h4 className="c-dark-grey font-size-14 font-weight-bold mb-0 text-uppercase">{t("userarea.currentpassword")}</h4>
            <Inputs.InputPassword value={oldPassword} setPassword={setOldPassword} />
            <h4 className="c-dark-grey font-size-14 font-weight-bold mt-1 mb-0 text-uppercase">{t("userarea.newpassword")}</h4>
            <Inputs.InputPassword value={newPassword} setPassword={setNewPassword} />
            <p className="c-black font-size-18 font-weight-semibold mt-1 mb-2"> {t("userarea.newpasswordmustbe")}</p>
            <div className="c-black ">
            <Checks.PasswordCheck password={newPassword} setIsPasswordValid={setIsPasswordValid} />
            </div>
            <h4 className="c-dark-grey font-size-14 font-weight-bold mt-1 mb-0 text-uppercase">{t("userarea.confirmpassword")}</h4>
            <Inputs.InputPassword value={confirmPassword} setPassword={setConfirmPassword} />
          </div>
        </div>
        <Alerts.Alert info={infoAlert} show={showAlert} />
        <ButtonComponent 
          cssClass="gradientButton mb-1 mt-5 w-100"
          onClick={() => click()}
          disabled={enableButton}
        >
         {t("userarea.button")}
        </ButtonComponent>
      </div>
    </div>
  );
}