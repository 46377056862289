import React from 'react';
import BgNoTarget from '../../img/BgNoTarget.png'
import { useTranslation } from 'react-i18next';
export function EmptyTarget() {
  const { t } = useTranslation();
  return (
    <div id="emptyTarget">
      <div className="row justify-content-center">
        <div className="col-8 text-center" >
          <h1 className="c-dark-violet font-size-36 font-weight-bold mt-3 ">{t("emptytarget.title")}</h1>
          <p className="c-black font-size-14 font-weight-regular">{t("emptytarget.description")}</p>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8 col-xl-6  px-4 mt-5" >
          <img alt="" src={BgNoTarget} className="w-100" />
        </div>
      </div>
    </div>
  );
}