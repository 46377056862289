import React, { useEffect, useState } from 'react';
// i18next Translation
import { useTranslation } from 'react-i18next';


import { ResponsiveTableOnline } from './ResponsiveTableOnline';
// Style CSS
import './filtermobile.css'



export function CardTableOnline({ type, data }) {
    const { t } = useTranslation();
    const filters = {
        fields: [
            'name',
            'online__sales',
            'online__transactions',
            'online__average_transaction'
        ],
        orders: [
            'desc',
            'asc'
        ]
    }

    const valueToCompare = (elem, field) => {
        return field === 'name'
            ? elem[field]
            :
           elem.kpi.filter(elem => elem.kind === field.split('__')[0].toUpperCase() && elem.subkind === field.split('__')[1].toUpperCase())[0].value
    }


    return (
        <ResponsiveTableOnline
            id={'table-online'}
            filters={filters}
            data={data.online}
            valueToCompare={valueToCompare}
        />
    );


}
