import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AngleCharts } from '../commons/AngleCharts';
import { CardTablePhysical } from './CardTablePhysical';




export function CardMyCashDesk({ data }) {
    const { t, i18n } = useTranslation();
    const [dataCashDesk, setDataCashDesk] = useState([]);

    const [total, setTotal] = useState(0);

    // dataCASHDESK
    useEffect(() => {

        let dataCashDesk = []
        let totalSales = 0;

        data.devices.cash.forEach(singlecashdesk => {
            let value = []
            value = singlecashdesk.kpi.filter(kpi =>
                kpi.subkind === "SALES"
            )

            dataCashDesk.push({ name: singlecashdesk.name, value: value[0].value })


            totalSales = Number(totalSales) + Number(value[0].value)
        })

        setDataCashDesk(dataCashDesk)
        setTotal(totalSales)
    }, []);


    return (
        <div id="CardMyCashDesk" className="h-100" >
            <div className="bg-white shadowDropMediumBlur border-radius h-100 d-flex flex-column flex-wrap p-2 p-xs-4">
                <AngleCharts type="physical" datacharts={dataCashDesk} total={total} />
                <div className="d-flex flex-column mt-3 w-100" >
                    <p className="c-tiffany font-weight-bold font-size-16 mb-0  ">
                         <CardTablePhysical  data={data} />
                    </p>
                </div>

            </div>

        </div>
    );
}