import React, { useState, useRef } from "react";
import IconGreyInfo from "../../../../img/IconGreyInfo.png"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import "./infotooltip.css"
// i18next Translation
import { useTranslation } from 'react-i18next';

export function InfoTooltip() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <>
            <OverlayTrigger
                // delay={{ show: 250, hide: 100 }}
                placement='bottom-end'
                overlay={<div
                    className="infoTooltip bg-medium-grey shadowDropMediumBlur">
                    <p className="c-white text-size-15">{t("infotooltip.description")}</p>
                </div>
                } >

                <div className="" ref={target} onClick={() => setShow(!show)}>
                    <img src={IconGreyInfo} alt="info icon" className="img-icon25 mb-2" />
                </div>

            </OverlayTrigger>



        </>
    );
}