import React, { useState, useEffect } from 'react';
import { Navbars, Sidebars, Footers } from '../commons'
import { Prompt } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// import './account.css';

export function ConditionsOfSale() {
    const { t } = useTranslation();
    const [isBlocking, setIsBlocking] = useState(false);
    const [isBlockingInfo, setIsBlockingInfo] = useState(false);
    const [isBlockingPassword, setIsBlockingPassword] = useState(false);

    useEffect(() => {
        if (isBlockingPassword || isBlockingInfo) {
            setIsBlocking(true)
        } else {
            setIsBlocking(false)
        }
    }, [isBlockingPassword, isBlockingInfo])

    return (
        <>
            <div id="conditionsOfSale">
                <Navbars.NavbarBackPage />
                <div className="container-fluid container-xl ">
                    <div className="row">
                        <div className="col-md-4 col-xl-3 pt-3 py-md-3">
                            <Sidebars.SidebarUser active="conditionsOfSale" title={t("useraresidebar.conditionsofsale")} />
                        </div>
                        <div className="col-md-8 col-xl-9">
                            <div id="myAccount" className="border-radius shadowDropMediumBlur bg-white my-3">
                                <div className="py-5  px-4">
                                        <h1 className="c-black font-weight-semibold font-size-20">  Condizioni di Vendita (H1)</h1>
                                        <p className="c-black font-weight-regular font-size-15">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                                            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
                                            adipisci velit, sed quia non numquam eius modi tempora
                                            incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam</p>

                                        <h2 className="c-black font-weight-regular font-size-18">Titolo secondario (H2) medium</h2>
                                        <p className="c-black font-weight-regular font-size-15">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium,
                                            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                            Nemo enim ipsam voluptatem.</p>

                                        <h3 className="c-black font-weight-regular font-size-16">Titolo terziario (H3) medium</h3>

                                        <p className="c-black font-weight-regular font-size-15">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                                            eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas
                                            sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. </p>

                                        <h3 className="c-black font-weight-regular font-size-16">Titolo terziario (H3) medium</h3>
                                        <p className="c-black font-weight-regular font-size-15"> Questo è un link (body violet2 #A05cfd medium ) te natus error sit voluptatem accusantium doloremque laudantium,
                                            totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                                            Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem
                                            sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
                                            consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

              
                <Footers.FooterPoweredBy />
               
                
            </div>
            <Prompt
                when={isBlocking}
                message={location => `${t("userarea.prompt")}`
                }
            />
        </>
    );
}