import { useState } from "react";
import { useTranslation } from "react-i18next";
import IconTableUpArrow from '../../../../img/IconTableUpArrow.png'
import IconTableDownArrow from '../../../../img/IconTableDownArrow.png'
import { Formatters } from "../../../commons";
import './responsivetable.css';


export const ResponsiveTableOnline = ({ id, filters, data, valueToCompare, click }) => {
    const { i18n } = useTranslation();
    const [sortedData, setSortedData] = useState(data);
    const [filterActive, setFilterActive] = useState('');

    function saveSorting(newSortedData, value) {
        setFilterActive(value);
        setSortedData(newSortedData);
    }

    return (
        <div id={id} className={`responsive-tableOnline  mx-md-2 scrollbarCustom ${i18n.language}`}>
            <TableHeaderSort
                filters={filters}
                data={data}
                filterActive={filterActive}
                saveSorting={saveSorting}
                valueToCompare={valueToCompare}
                mobile={true}
            />

            <div className="p-0 border-radius overflow-auto scrollbarCustom">
                <Table
                    filters={filters}
                    data={sortedData}
                    filterActive={filterActive}
                    saveSorting={saveSorting}
                    valueToCompare={valueToCompare}
                    click={click}
                />
            </div>
        </div>
    );
}

export const Table = ({ filters, data, filterActive, saveSorting, valueToCompare, click }) => {
    return (
        <table className="table bg-grey">
            <TableHeaderSort
                filters={filters}
                data={data}
                filterActive={filterActive}
                saveSorting={saveSorting}
                valueToCompare={valueToCompare}
                mobile={false}
            />
            <TableBody
                data={data}
                click={click}
            />
        </table>
    );
}

export const TableHeaderSort = ({ filters, data, filterActive, saveSorting, valueToCompare, mobile }) => {
    const { t } = useTranslation();

    function sortColumn(value) {
        const [field, order] = value.split('-');
        let sortedData = data.sort(function (a, b) {
            const aField = valueToCompare(a, field);
            const bField = valueToCompare(b, field);
            if (order === 'asc') {
                if (aField < bField) return -1;
                if (aField > bField) return 1;
            }
            else if (order === 'desc') {
                if (aField > bField) return -1;
                if (aField < bField) return 1;
            }
            return 0;
        });

        saveSorting(sortedData, value);
    }

    return mobile ?
        <div className="d-flex flex-md-row flex-column justify-content-end">
          
            <div className="responsive-sort">
                <h3 className="c-dark-grey font-size-14 text-md-end font-weight-semibold m-2 text-uppercase">{t("responsivetableonline.sort")}</h3>
                <div className="gradient-input-round mb-3 mt-2 ">
                    <select
                        className="e-select py-1 px-2 w-100"
                        style={{ minHeight: "35px" }}
                        onChange={(e) => sortColumn(e.target.value)}
                        value={filterActive}
                    >
                        {filters.fields.map((field) => {
                            
                            return filters.orders.map((order, index) => {
                                const value = `${field}-${order}`;
                                return <option key={index} value={value}>{t(`responsivetableonline.${value}`)}</option>
                            })
                        })}
                    </select>
                </div>
            </div>
        </div>
        :
        <thead>
            <tr className="table bg-grad-purple border-radius shadowDropLessBlur ">
                {filters.fields.map((field, index) => {
                    return <th key={index} scope="col" className="text-start ps-2 ">
                        <div className="d-flex flex-row align-items-center">
                            <h5 className="c-white font-size-16 font-weight-semibold mb-0">{t(`responsivetableonline.${field}`)}</h5>
                            <div className="d-flex flex-column">
                                {filters.orders.map((order, index) => {
                                    const value = `${field}-${order}`;
                                    return <img key={index} alt="" className="img-icon10 pointer mx-1"
                                        style={{ visibility: filterActive === value ? "hidden" : "visible" }}
                                        src={order === 'asc' ? IconTableDownArrow : IconTableUpArrow}
                                        onClick={() => sortColumn(value)}
                                    />
                                })}
                            </div>
                        </div>
                    </th>
                })}
                <th scope="col" className="text-start ps-1"></th>
            </tr>
        </thead>
}

const TableBody = ({ data, click }) => {
    return (
        <tbody>
            {data.map((tr, index) =>
                <tr className="shadowDropLessBlur" key={index}>
                    <td className="text-start text-break bg-white ps-3 py-3">
                        <div className="c-black font-weight-semibold .font-weight-sm-regular text-decoration-underline">
                            {tr.name}
                        </div>
                    </td>
                    
                    {tr.kpi.filter(kpi => kpi.kind + kpi.subkind === 'ONLINESALES' || 
                    kpi.kind + kpi.subkind === 'ONLINETRANSACTIONS' || 
                    kpi.kind + kpi.subkind === 'ONLINEAVERAGE_TRANSACTION').map((td, index) => {
                        return <td key={index} className="bg-white ps-3 py-3">
                            <p className="text-start text-break mb-0 font-weight-regular">
                                <Formatters.NumberFormatter value={td.value} 
                                currency={td.kind + td.subkind === 'ONLINESALES'|| td.kind + td.subkind === 'ONLINEAVERAGE_TRANSACTION'} />

                            {td.kind + td.subkind === 'ONLINESALES' ||td.kind + td.subkind === 'ONLINEAVERAGE_TRANSACTION'  ? ' €' 
                             : null}</p></td>
                    })}

                </tr>
            )}
        </tbody>
    )
}