import React, { useEffect } from 'react';
import { targetsRequest } from '../../actions';
import { useAppState } from '../../store/AppState';
import { Navbars, Footers } from '../commons';
import { List } from './List'

export function TargetList() {
  const [state, dispatch] = useAppState();

  useEffect(() => {
    dispatch(targetsRequest(dispatch, {getNew: "true"}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="targetListPage" className="container-fluid min-vh-100 d-flex flex-column">
      
        <div className="row">
   <Navbars.NavbarBackPage />
    
   
      {/* {state.targets.list &&  <List />} */}
      <List parametersFetch={{getNew: "true"}} />
      <div className="col-12 ">
      <div className=" pe-3">
      <Footers.FooterPoweredBy />
      </div> 
      </div> 
      </div>
    </div>
  );
}